<template lang="pug">
.pane
  sidebar-title
    span {{ type === 'radio' ? $t('editRadio') : $t('editCheckbox') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          input-element-chooser
        sidebar-tab-predefined-style(property="selectedElement" component="input-picker")
          template(#before)
            custom-theme-style
            om-size(
              propsProperty="selectedElement"
              :inputType="type"
              typeOfComponent="input-picker"
            )
            sidebar-accordion(:title="$t('fontEdit')")
              template(v-if="hasFeedbackQuestion && isV1Element")
                .sidebar-input-wrapper.flex-column.align-items-start
                  .sidebar-input-wrapper-label.font-weight-bold.mb-2 {{ $t('question') }}
                  .border-bottom
                om-font(
                  propsProperty="selectedElement"
                  :type="type"
                  subPath="selectedElement.subElements.question.desktop"
                  typeOfComponent="input-picker"
                )
                  template(#fontSize)
                    om-range-input(
                      property="selectedElement.subElements.question.$device.fontSize"
                      :editMobile="true"
                      label="fontSize"
                      typeOfComponent="input-picker"
                      :min="8"
                      :max="90"
                      :step="1"
                    )
              .sidebar-input-wrapper.flex-column.align-items-start(
                v-if="hasFeedbackQuestion && isV1Element"
              )
                .sidebar-input-wrapper-label.font-weight-bold.mb-2 {{ $t('options') }}
                .border-bottom
              om-font(propsProperty="selectedElement" typeOfComponent="input-picker")
                template(#fontSize)
                  om-range-input(
                    :property="getPath('fontSize')"
                    :editMobile="true"
                    label="fontSize"
                    typeOfComponent="input-picker"
                    :min="8"
                    :max="90"
                    :step="1"
                  )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import { get as _get } from 'lodash-es';
  import inputsPaneMixin from '@/editor/mixins/inputsPane';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin, inputsPaneMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      hasFeedbackQuestion() {
        return _get(this.selectedElement, 'data.feedback.question.length');
      },
      isV1Element() {
        return parseInt(_get(this.selectedElement, 'data.version', 1), 10) === 1;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'input-picker',
        };
      },
    },
  };
</script>
