<template lang="pug">
div
  template(v-if="!isTabNull")
    .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="subPath === 'tab'") {{ $t('message') }}
    om-simple-input(v-if="subPath === 'tab'" label="" property="data.tabText")
  font-dropdown(
    label="fontFamily"
    :property="getPath('fontFamily')"
    :i18n="false"
    :typeOfComponent="typeOfComponent"
  )
  om-color-input(label="color" :property="getPath('color')" :typeOfComponent="typeOfComponent")
  .mt-3
    slot(name="fontSize")
      om-range-input(
        :property="getPath('fontSize')"
        label="fontSize"
        :typeOfComponent="typeOfComponent"
        :min="8"
        :max="90"
        :step="1"
      )
  om-range-input(
    v-if="isFeedback"
    :property="getPath('verticalSpacing')"
    label="spacing"
    :typeOfComponent="typeOfComponent"
    :step="1"
  )
  //- om-range-input(v-if="isFeedback" :property="propsProperty + `.${subPath}.lineHeight`" label="lineHeight" :typeOfComponent="typeOfComponent" :min="8", :step="1")
  om-dropdown-input(
    v-if="isFeedback || isPickAPresent"
    label="lineHeight"
    :property="getPath('lineHeight')"
    :items="lineHeights"
    :typeOfComponent="typeOfComponent"
    :i18n="false"
  )
  om-dropdown-input(
    v-if="isFeedback || isPickAPresent"
    label="weight"
    :property="getPath('fontWeight')"
    :items="fontWeights"
    :typeOfComponent="typeOfComponent"
    :i18n="false"
  )
  om-font-awesome-button(
    label="style"
    :property="propsProperty"
    :subPath="subPath"
    :typeOfComponent="typeOfComponent"
  )
  template(
    v-if="typeOfComponent === 'inputs' || subPath === 'inputs' || subPath === 'input-picker' || typeOfComponent === 'input-picker' || typeOfComponent === 'feedback' || typeOfComponent === 'survey' || isGlobalText"
  )
    align-select(
      label="textAlign"
      :options="direction.map(({ key }) => key)"
      :property="getPath('textAlign')"
      type="text"
      :small="true"
      :editMobile="editMobile"
    )
</template>

<script>
  import { mapGetters } from 'vuex';
  import { inputTypes, dropdownTypes } from '@om/editor-ssr-shared/src/utils';
  import { fontWeightOptions, lineHeightOptions } from '@/editor/quill_options';
  import itemMixin from '@/editor/mixins/item';
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';

  export default {
    components: { AlignSelect },
    mixins: [itemMixin],
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: 'desktop' },
      typeOfComponent: { type: String, default: 'global' },
      type: { type: String },
    },
    data() {
      return {
        show: false,
        canEditMobile: true,
        direction: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
      };
    },
    computed: {
      ...mapGetters(['nestedAccess']),
      isGlobalText() {
        return this.propsProperty === 'globalStyle' && this.subPath === 'text';
      },
      isTabNull() {
        return this.nestedAccess('globalStyle.data.tabText') === null;
      },
      isCustomInputText() {
        const isGlobal = this.propsProperty === 'globalStyle';
        const isText = [...inputTypes, ...dropdownTypes].includes(
          this.nestedAccess(`${this.propsProperty}.data.form.customSettings.type`),
        );
        return !isText && !isGlobal;
      },
      isFeedback() {
        return ['radio', 'checkbox', 'feedback'].includes(this.type);
      },
      isPickAPresent() {
        return this.typeOfComponent === 'pickapresent';
      },
      fontWeights() {
        return fontWeightOptions.map((fw) => ({
          key: fw.label,
          value: parseInt(fw.value, 10),
        }));
      },
      lineHeights() {
        return lineHeightOptions.map((l) => ({
          key: l.label,
          value: l.value,
        }));
      },
    },
  };
</script>
