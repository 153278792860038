<template lang="pug">
.pane
  sidebar-title
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          om-dropdown-input.wide-select(
            label="type"
            property="selectedElement.data.follow"
            :items="followType"
          )
          template(v-if="type === 'socialShare'")
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('url') }}
            .pb-2
              om-simple-input(label="" property="data.socialShare.url")
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('text') }}
            .pb-2
              om-simple-input(label="" property="data.socialShare.text")
          SubSection(label="services")
            template(v-if="type === 'socialShare'")
              .social-setting-block(
                v-for="(item, index) in selectedElement.data.socialShare.types"
              )
                .social-setting-block-inner
                  .sidebar-input-wrapper.flex-row-baseline.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('service') }}
                    span.om-remove.social-remove(
                      v-if="shareLength > 1"
                      @click="removeAnotherShareService(index)"
                      style="text-align: right"
                    ) {{ $t('remove') }}
                  div
                    om-dropdown-input.flex-row-baseline-wide(
                      label=""
                      :property="'selectedElement.data.socialShare.types.' + index + '.type'"
                      :items="shareTypes"
                      :i18n="false"
                    )
            template(v-if="type === 'socialFollow'")
              .social-setting-block(v-for="(item, index) in selectedElement.data.social")
                .social-setting-block-inner
                  .sidebar-input-wrapper.flex-row-baseline.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('linkText') }}
                    span.om-remove.social-remove(
                      v-if="socialLength > 1"
                      @click="removeAnotherService(index)"
                      style="text-align: right"
                    ) {{ $t('remove') }}
                  .sidebar-input-wrapper
                    input.simpleInput(v-model="item.linkText" @input="update($event, index)")
                  .sidebar-input-wrapper.flex-row-baseline.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('serviceUrl') }}
                  .d-flex
                    om-dropdown-input.flex-row-baseline-wide.pr-1.flex-grow-0.social-follow-type(
                      label=""
                      :property="'selectedElement.data.social.' + index + '.type'"
                      @change.native="changeSocialFollowType($event, index)"
                      :items="followTypes"
                      :i18n="false"
                    )
                    .sidebar-input-wrapper.pl-0.flex-grow-1
                      input.simpleInput(
                        :class="{ invalid: validationFailedForFollow(index) }"
                        :value="socialFollowInputValue(index)"
                        @input="updateSocialFollow($event, index)"
                      )
            transition(name="fade")
              .sidebar-input-wrapper.flex-row-baseline.pt-3(:class="{ disabled: isAddDisabled }")
                om-button(primary large block :disabled="isAddDisabled" @click="onAddClick") + {{ $t('addAnotherService') }}
        sidebar-tab-predefined-style(property="selectedElement" component="social")
          template(#before)
            custom-theme-style
            sidebar-accordion(:title="$t('style')")
              .mt-0
                .sidebar-input-wrapper.flex-row-baseline.flex-row-baseline-wide.social-icon-styles
                  .icon-style {{ $t('style') }}
                  .icon-style-container.flex-row-baseline.align-items-center.mr-1
                    .icon-style-item(
                      :class="{ selected: socialType === 'square' }"
                      @click="changeSocialStyle('square')"
                    )
                      .simple-style-background.simple-style-background-square
                        UilFacebookF.simple-style
                    .icon-style-item(
                      :class="{ selected: socialType === 'filled' }"
                      @click="changeSocialStyle('filled')"
                    )
                      .simple-style-background.margin-x-05
                        UilFacebookF.simple-style
                    .icon-style-item(
                      :class="{ selected: socialType === 'simple' }"
                      @click="changeSocialStyle('simple')"
                    )
                      .simple-style-background.white-bg
                        UilFacebookF.filled-style
                .mt-2
                  om-range-input(
                    label="iconSize"
                    property="selectedElement.desktop.iconSize"
                    typeOfComponent="social"
                    :min="10"
                    :max="100"
                    :step="1"
                    unit="px"
                  )
                  align-select(
                    label="align"
                    property="data.socialAlignment"
                    :options="alignment.map(({ key }) => key)"
                  )
            sidebar-accordion(:title="$t('fontEdit')")
              om-font(propsProperty="selectedElement" subPath="link" typeOfComponent="social")
        advanced-tab(:spacingProps="spacingProps")
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import { UilFacebookF } from '@iconscout/vue-unicons';
  import itemMixin from '@/editor/mixins/item';

  const pane = 'Social';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
      AlignSelect: () => import('@/editor/components/sidebar/components/AlignSelect.vue'),
      SubSection: () => import('@/editor/components/sidebar/components/SubSection.vue'),
      UilFacebookF,
    },
    mixins: [itemMixin],
    data() {
      return {
        showType: true,
        show: false,
        showDisplay: false,
        followType: [
          { key: 'socialShare', value: 'socialShare' },
          { key: 'socialFollow', value: 'socialFollow' },
        ],
        alignment: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        shareTypes: [
          { key: 'Linkedin', value: 'linkedin' },
          { key: 'Facebook', value: 'facebook' },
          { key: 'X', value: 'x' },
        ],
        followTypes: [
          { key: 'instagram.com/', value: 'instagram' },
          { key: 'linkedin.com/', value: 'linkedin' },
          { key: 'facebook.com/', value: 'facebook' },
          { key: 'x.com/', value: 'x' },
          { key: 'pinterest.com/', value: 'pinterest' },
          { key: 'youtube.com/', value: 'youtube' },
          { key: 'tiktok.com/', value: 'tiktok' },
        ],
        socialUrls: {
          instagram: 'https://instagram.com/',
          linkedin: 'https://linkedin.com/',
          facebook: 'https://facebook.com/',
          x: 'https://x.com/',
          pinterest: 'https://pinterest.com/',
          'plus.google': 'https://plus.google.com/',
          youtube: 'https://youtube.com/',
          tiktok: 'https://tiktok.com/',
        },
      };
    },
    computed: {
      ...mapState(['selectedElement', 'validationError', 'userInfoMessage']),
      ...mapGetters(['isSubUser']),
      openSocialPlatform() {
        return this.show || this.validationError.property === 'socialFollow';
      },
      type() {
        return this.selectedElement.data.follow;
      },
      shareLength() {
        return this.selectedElement.data.socialShare.types.length;
      },
      isShareLimit() {
        return this.shareLength < 4;
      },
      socialLength() {
        return this.selectedElement.data.social.length;
      },
      isFollowLimit() {
        return this.socialLength < 3;
      },
      socialType() {
        return this.selectedElement.data.socialType;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: pane.toLowerCase(),
        };
      },
      isAddDisabled() {
        return this.type === 'socialFollow' ? !this.isFollowLimit : !this.isShareLimit;
      },
    },
    methods: {
      ...mapMutations(['updateStyle']),
      validationFailedForFollow(index) {
        return (
          this.validationError.property === 'socialFollow' &&
          this.validationError.failedIndexes.indexOf(index) > -1
        );
      },
      addAnotherService() {
        this.selectedElement.data.social.push({
          linkText: this.$t('follow'),
          type: 'linkedin',
          serviceUrl: 'https://linkedin.com/',
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.social',
          value: this.selectedElement.data.social,
        });
      },
      addAnotherShareService() {
        this.selectedElement.data.socialShare.types.push({ type: this.shareTypes[0].value });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialShare.types',
          value: this.selectedElement.data.socialShare.types,
        });
      },
      removeAnotherService(id) {
        this.selectedElement.data.social.splice(id, 1);
        this.$store.commit('updateData', {
          property: 'selectedElement.data.social',
          value: this.selectedElement.data.social,
        });
      },
      removeAnotherShareService(id) {
        this.selectedElement.data.socialShare.types.splice(id, 1);
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialShare.types',
          value: this.selectedElement.data.socialShare.types,
        });
      },
      update() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.social',
          value: this.selectedElement.data.social,
        });
      },
      updateShare() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialShare.types',
          value: this.selectedElement.data.socialShare.types,
        });
      },
      socialFollowInputValue(index) {
        const social = this.selectedElement.data.social[index];
        const inputValue = social.serviceUrl.replace(this.socialUrls[social.type], '');
        return inputValue;
      },
      updateSocialFollow(e, index) {
        const social = this.selectedElement.data.social[index];
        const serviceUrl = this.socialUrls[social.type];
        const inputValue =
          e.target.value.charAt(0) === '/' ? e.target.value.substr(1) : e.target.value;
        const fullServiceUrl = serviceUrl + inputValue;
        this.$store.commit('updateData', {
          property: `selectedElement.data.social.${index}.serviceUrl`,
          value: fullServiceUrl,
        });
      },
      changeSocialFollowType(e, index) {
        const serviceUrl = this.socialUrls[e.target.value];
        const inputValue = this.selectedElement.data.social[index].serviceUrl.replace(
          new RegExp('https://.*.com/'),
          '',
        );
        const fullServiceUrl = serviceUrl + inputValue;
        this.$store.commit('updateData', {
          property: `selectedElement.data.social.${index}.serviceUrl`,
          value: fullServiceUrl,
        });
      },
      changeSocialStyle(type) {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialType',
          value: type,
        });
      },
      startProductTour() {
        if (!window.Intercom) return;
        if (this.$i18n.locale === 'hu') window.Intercom('startTour', 34213);
        else window.Intercom('startTour', 34209);
      },
      onAddClick() {
        if (this.type === 'socialFollow') {
          this.addAnotherService();
        } else {
          this.addAnotherShareService();
        }
      },
    },
  };
</script>
<style lang="sass">
  .sidebar.editor
    .social-setting-block:nth-child(3)
      margin-top: 0
      border: 0
    .social-icon-styles
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
      .icon-style
        margin-bottom: 1rem
        &-container
          width: 100%
          justify-content: space-between
        &-item
          display: flex
          align-items: center
          justify-content: center
          padding: .625rem 1.25rem
          border: 1px solid rgb(213, 216, 221)
          border-radius: 3px
          cursor: pointer
          &.selected
            border-color: var(--brand-primary-color)
          .simple-style-background
            width: 2rem
            height: 2rem
            line-height: 1.675rem
            background: rgb(57, 117, 234)
            border-color: rgb(57, 117, 234)
            &.white-bg
              background: white
            .filled-style
              color: rgb(57, 117, 234)
            &.margin-x-05
              margin: 0
</style>
