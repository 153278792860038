import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['validationError']),
  },
  methods: {
    notNull(v) {
      return v !== null;
    },
    setPrefix(value, index = null) {
      this.updateData({
        property: this.notNull(index)
          ? this.optionProperty(index, 'prefix')
          : 'selectedElement.data.prefix',
        value,
      });
    },
    setPhoneNumberField(value, index = null) {
      this.updateData({
        property: this.notNull(index)
          ? this.optionProperty(index, 'phoneNumber')
          : 'selectedElement.data.phoneNumber',
        value,
      });
    },
    getPhoneNumberElement(index = null) {
      return this.notNull(index)
        ? this.$refs[`phoneNumberInput${index}`][0].$refs.input
        : this.$refs.phoneNumberInput.$refs.input;
    },
    getIti(index = null) {
      return this.notNull(index) ? this.iti[index] : this.iti;
    },
    setPhoneNumber(index = null) {
      const phoneNumber = this.notNull(index)
        ? this.nestedAccess(this.optionProperty(index, 'phoneNumber'))
        : this.selectedElement.data.phoneNumber;
      const rawPhoneNumber = this.notNull(index)
        ? this.nestedAccess(this.optionProperty(index, 'rawPhoneNumber'))
        : this.selectedElement.data.rawPhoneNumber;

      if (phoneNumber) {
        this.getIti(index).setNumber(phoneNumber);
      } else if (rawPhoneNumber) {
        this.getIti(index).setNumber(rawPhoneNumber);
      }
    },
    initPhoneNumberInput(index = null) {
      this.$nextTick(async () => {
        const phoneNumberInput = this.getPhoneNumberElement(index);

        this.destroyPhoneNumberInput(index);

        const [{ default: intlTelInput }] = await Promise.all([
          import('intl-tel-input'),
          import('intl-tel-input/build/js/utils'),
        ]);

        let iti;
        if (this.notNull(index)) {
          this.iti = {};
          iti = intlTelInput(phoneNumberInput);
          this.iti[index] = iti;
        } else {
          iti = intlTelInput(phoneNumberInput);
          this.iti = iti;
        }

        const selectedCountry = iti.getSelectedCountryData();
        this.setPrefix(`+${selectedCountry.dialCode}`, index);

        phoneNumberInput.addEventListener('countrychange', (e) => {
          const iti = window.intlTelInputGlobals.getInstance(e.target);
          if (iti) {
            const selectedCountry = iti.getSelectedCountryData();
            this.setPrefix(`+${selectedCountry.dialCode}`, index);
          }
        });
        this.setPhoneNumber(index);
      });
    },
    destroyPhoneNumberInput(index = null) {
      if (this.iti && this.getIti(index)) {
        const inputs = document.querySelectorAll('.intl-tel-input');
        inputs.forEach((input) => {
          input.querySelector('input').removeAttribute('placeholder');
          const wrapper = document.createElement('div');
          input.parentNode.insertBefore(wrapper, input);
          wrapper.appendChild(input);
        });
        this.getIti(index).destroy();
      }
    },
    rawPhoneNumberChanged(index = null) {
      const property = this.notNull(index)
        ? this.optionProperty(index, 'rawPhoneNumber')
        : 'selectedElement.data.rawPhoneNumber';
      if (this.getIti(index).isValidNumber()) {
        this.setPhoneNumberField(this.getIti(index).getNumber(), index);

        if (this.validationError && this.validationError.property === property) {
          this.$bus.$emit('setEditorStateAttr', { attr: 'validationError', value: {} });
        }
      } else {
        this.setPhoneNumberField(NaN, index);
        this.$bus.$emit('setEditorStateAttr', {
          attr: 'validationError',
          value: { property, msg: 'invalidPhoneNumber' },
        });
      }
    },
  },
};
