<template lang="pug">
div
  radius-elements(@select="selectRadiusType($event)" :value="selected")
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import roundingExceptionsMixin from '@/editor/mixins/roundingExceptions';
  import RadiusElements from '@/editor/components/sidebar/RadiusElements.vue';

  export default {
    components: { RadiusElements },
    mixins: [pageStructureMixin, roundingExceptionsMixin],
    data() {
      return {
        selected: null,
      };
    },
    computed: {
      ...mapState(['globalStyle']),
      ...mapGetters(['hasAccountFeature', 'isThemeKit', 'getCustomThemeKitValues']),
      isNewCampaign() {
        return this.$route.params.new === 'new';
      },
    },
    mounted() {
      this.$nextTick(() => {
        if (this.isThemeKit) {
          const { rounding } = this.getCustomThemeKitValues;
          this.selected = rounding.concat('-type');
        } else {
          const { themeRadius } = this.globalStyle.themeSettings || {};
          this.selected = themeRadius?.concat('-type');
        }
      });
    },
    methods: {
      selectRadiusType(element) {
        this.selected = element.type;
        const type = element.type.replace('-type', '');
        if (this.isThemeKit) {
          this.handleCustomThemeRounding(type);
        }
      },
      handleCustomThemeRounding(type) {
        this.$store.commit('updateCustomThemeRadius', { value: type });
      },
    },
  };
</script>
