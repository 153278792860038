<template lang="pug">
.copy-element-id
  .sidebar-input-wrapper.d-flex.align-items-center.py-1.px-4.justify-content-between
    .sidebar-input-wrapper-label.p-0.pr-1.font-weight-bold {{ $t('elementId') }}:
    .d-flex.flex-grow-0
      .sidebar-input-wrapper-label.p-1.px-3.copy-element-id-value {{ selectedElement.uid }}
      fa-icon.cursor-pointer.copy-element-id-icon.ml-2.align-items-center(
        v-clipboard:copy="selectedElement.uid"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        variant="fa-copy"
        display="flex"
      )
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState(['selectedElement']),
    },

    methods: {
      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.elementIdCopied'),
        });
      },

      onError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.clipboardCopyError'),
        });
      },
    },
  };
</script>
