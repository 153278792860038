<template lang="pug">
upload-area(:src="imageUrl" :editMobile="true" @click="openImageManager" @remove="resetImage")
</template>

<script>
  import { mapMutations } from 'vuex';
  import itemMixin from '../../../mixins/item';
  import UploadArea from '../../UploadArea.vue';

  const defaultImage = require('@/assets/editor/img/om_picture.svg');

  export default {
    components: { UploadArea },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      subPath: { type: String, default: '' },
    },
    computed: {
      imageId() {
        return this.getValueOf(`${this.property}.imageId`);
      },
      imageUrl() {
        return this.getValueOf(`${this.property}.imageUrl`, defaultImage);
      },
    },
    methods: {
      ...mapMutations(['showImageManager', 'setImageManagerDestination']),
      openImageManager() {
        this.setImageManagerDestination({ targetAttr: this.property, simpleImage: true });
        this.showImageManager();
      },
      resetImage() {
        this.setValueOf(this.getPath('imageId'), null);
        this.setValueOf(this.getPath('imageUrl'), null);
      },
    },
  };
</script>

<style lang="sass"></style>
