<template lang="pug">
.sidebar-input-wrapper
  label(:style="{ 'margin-top': '-10px' }" v-if="label") {{ i18n ? $t(label) : label }}
  .d-flex.flex-wrap.chooser-container
    .chooser-option(
      v-for="option in options"
      :class="{ 'chooser-option-selected': selected === option.value, [`column-${options.length}`]: true }"
      @click="selected = option.value"
    )
      img(:src="option.image")
      .chooser-option-name.font-size-0--75.mt-2 {{ i18n ? $t(option.name) : option.name }}
</template>
<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String },
      options: { type: Array, required: true },
      defaultValue: { type: String },
      i18n: { type: Boolean, default: true },
    },
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters(['nestedAccess']),
      selected: {
        get() {
          return this.getValueOf(this.property, this.defaultValue) || this.defaultValue;
        },
        set(value) {
          this.setValueOf(this.property, value, true);
          window.om.bus.$emit('userInputChange', { property: this.property, value });
        },
      },
    },

    methods: {
      ...mapMutations(['updateData']),
    },
  };
</script>

<style lang="sass">
  .chooser-container
    justify-content: space-between

    .chooser-option
      cursor: pointer
      // flex-grow: 1
      flex-shrink: 1
      margin-top: 0.5rem
      margin-bottom: 0.5rem
      text-align: center
      margin-right: 0.3rem

      @for $i from 1 through 4
        &.column-#{$i}
          width: calc(100% / #{$i} - #{$i - 1} * 0.3rem)

      &:last-of-type
        margin-right: 0

      img
        margin: auto
        border: 1px solid #C8CDD7
        border-radius: 0.1875rem
        padding: 0.375rem
        width: 100%

      .chooser-option-name
        text-align: center

      &-selected
        img
          background: #F4F8FB
          border-color: var(--brand-primary-color)
</style>
