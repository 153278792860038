const moment = require('moment');
const { isNonPayingPlan, isNotFreemiumPackage } = require('./planDetails');
const dateCalculator = require('./dateCalculator');

// We cannot calculate a correct upgrade price if the expiration does not correspond to the period of the package
// e.g. semi annual deal with monthly package
const canCalculateCorrectUpgradePrice = ({
  currentPlan,
  period,
  dateExpires,
  isShopify = false,
}) => {
  if (isNonPayingPlan(currentPlan)) {
    return true;
  }

  if (isShopify && isNotFreemiumPackage(currentPlan, true)) {
    return false;
  }

  const tresholdInDay = 10; // mainly because of bank-transfer
  const today = moment.utc().startOf('day');
  // const paidUntil = dateCalculator.calculatePaidUntil(dateExpires);
  const paidFrom = dateCalculator.calculatePaidFrom(dateExpires, period, isShopify);
  return moment.utc(paidFrom).subtract(tresholdInDay, 'days').isSameOrBefore(today);
};

// this calculation is based on actual billing period
const calculatePackagePricePerDay = ({ packagePrice, period, dateExpires, isShopify = false }) => {
  const paidUntil = dateCalculator.calculatePaidUntil(dateExpires);
  const paidFrom = dateCalculator.calculatePaidFrom(dateExpires, period, isShopify);
  const daysBetweenPaidFromAndPaidUntil = moment.duration(paidUntil.diff(paidFrom)).asDays();

  // console.log({
  //   packagePrice,
  //   period,
  //   paidFrom: paidFrom.toDate(),
  //   paidUntil: paidUntil.toDate(),
  //   diffDays: daysBetweenPaidFromAndPaidUntil,
  //   pricePerDay: packagePrice / daysBetweenPaidFromAndPaidUntil,
  // });

  return packagePrice / daysBetweenPaidFromAndPaidUntil;
};

// Price diff for remaining days
const calculateUpgradePrice = ({
  eventDate = moment.utc(),
  dateExpires,
  period, // current package period
  currentPackagePrice,
  newPackagePrice,
  newPackagePeriod = null,
  isShopify = false,
}) => {
  if (!newPackagePeriod) newPackagePeriod = period;

  const paidUntil = dateCalculator.calculatePaidUntil(dateExpires);

  const currentPackagePricePerDay = calculatePackagePricePerDay({
    packagePrice: currentPackagePrice,
    period,
    dateExpires,
    isShopify,
  });

  const newPackagePricePerDay = calculatePackagePricePerDay({
    packagePrice: newPackagePrice,
    period: newPackagePeriod,
    dateExpires,
    isShopify,
  });

  const priceDiffPerDay = newPackagePricePerDay - currentPackagePricePerDay;

  const remainingPeriodInDays = moment.duration(paidUntil.diff(eventDate.startOf('day'))).asDays();

  // console.log({
  //   currentPackagePrice,
  //   currentPackagePricePerDay,
  //   newPackagePrice,
  //   newPackagePricePerDay,
  //   priceDiffPerDay,
  //   remainingPeriodInDays,
  //   result: Math.ceil(remainingPeriodInDays * priceDiffPerDay),
  // });

  return Math.ceil(remainingPeriodInDays * priceDiffPerDay);
};

// Refund amount for remaining days
const calculateRefund = ({
  eventDate = moment.utc(),
  dateExpires,
  packagePrice,
  period,
  isShopify = false,
}) => {
  const paidUntil = dateCalculator.calculatePaidUntil(dateExpires);
  const remainingPeriodInDays = moment.duration(paidUntil.diff(eventDate.startOf('day'))).asDays();
  const packagePricePerDay = calculatePackagePricePerDay({
    packagePrice,
    period,
    dateExpires,
    isShopify,
  });

  const result = remainingPeriodInDays * packagePricePerDay;

  // console.log({
  //   packagePrice,
  //   paidUntil: paidUntil.toDate(),
  //   remainingPeriodInDays,
  //   packagePricePerDay,
  //   result: Math.round(result),
  // });

  return Math.round(result);
};

module.exports = {
  canCalculateCorrectUpgradePrice,
  calculatePackagePricePerDay,
  calculateUpgradePrice,
  calculateRefund,
};
