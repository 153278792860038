<template lang="pug">
.sidebar-input-wrapper.flex-row-baseline
  span.sidebar-title-tooltip-container.pl-0
    label.m-0.text-break(:style="{position: setMaxWidth ? 'absolute' : 'static', 'max-width': setMaxWidth ? '5rem' : ''}" v-html="$t(label)")
    slot(name="tooltip")
    template(v-if="label === 'buttonEvent'")
      i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-tooltip="{content: $t('buttonEventsTooltip')}")
    template(v-if="label === 'action'")
      i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-tooltip="{content: $t('buttonActionTooltip')}")
    DeviceSelector(v-if="editMobile" :hasViewText="false")
  span.epic-simple-input
    .select-arrow
      i(class="fa fa-angle-down" aria-hidden="true")
    select(v-model="internalValue" :disabled="disabled" @change="$emit('change', $event)" :class="{'invalid': validationFailed}")
      option(v-if="isMobileProperty && defaultOption" :value="null") {{ $t('default') }}
      option(v-for="item in items" :value="item.value") {{i18n ? $t(item.key) : item.key}}
    .validation-failed-msg(v-if="validationFailed") {{ $t(validationError.msg) }}
  slot(name="help")
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '../../../mixins/item';
  import DeviceSelector from '../components/DeviceSelector.vue';

  export default {
    components: { DeviceSelector },
    mixins: [itemMixin],
    props: {
      property: { type: String, default: null },
      value: { type: [String, Number, Array, Object, null], default: null },
      label: { type: String, required: true },
      items: { type: Array, required: true },
      i18n: { type: Boolean, default: true },
      defaultValue: { type: String, default: null },
      mobileDefaultValue: { type: [String, Number, Array, Object, null], default: null },
      typeOfComponent: { type: String, default: 'global' },
      setMaxWidth: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      defaultOption: { type: Boolean, default: true },
    },
    computed: {
      ...mapState(['validationError']),
      validationFailed() {
        return this.validationError.property === this.property;
      },
      internalValue: {
        get() {
          if (this.property) {
            const _def = this.isMobileProperty ? this.mobileDefaultValue : this.defaultValue;
            return this.getValueOf(this.property, _def);
          }
          return this.value;
        },
        set(value) {
          if (this.property) {
            this.setValueOf(this.property, value);
            window.om.bus.$emit('userInputChange', { property: this.property, value });
          } else {
            this.$emit('input', value);
          }
        },
      },
      isMobileProperty() {
        return this.property && this.property.includes('mobile.');
      },
    },
  };
</script>
