<template lang="pug">
div
  .pane.pane-no-copy-element-id
    sidebar-title
      span {{ $t('editPage') }}
    .sidebar-inner-box.pt-0
      sidebar-tab-wrapper(defaultTab="style")
        template(#tabs)
          sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
          sidebar-tab-item#advanced(:name="$t('sidebarSettings.tabs.name.advanced')")
        template(#content)
          sidebar-tab-content#style
            custom-theme-style
            om-background-box(
              propsProperty="selectedPage"
              subPath="selectedPage"
              :editMobile="true"
              :isOpen="true"
            )
            template(v-if="showSize && !isEmbedded && !isFullscreen")
              sidebar-accordion(:title="$t('size')")
                om-range-input(
                  v-if="mobilePreview"
                  label="width"
                  property="selectedPage.mobile.width"
                  :editMobile="true"
                  :min="15"
                  :max="100"
                  :step="1"
                  typeOfComponent="canvas"
                  unit="%"
                )
                om-range-input(
                  v-else
                  label="width"
                  property="selectedPage.desktop.width"
                  :editMobile="true"
                  :min="200"
                  :max="pageMaxWidth"
                  :step="1"
                  typeOfComponent="canvas"
                )
                om-range-input(
                  v-if="!isFitToScreen"
                  label="minHeight"
                  property="selectedPage.desktop.minHeight"
                  :min="50"
                  :max="650"
                  :step="1"
                  typeOfComponent="canvas"
                )
            sidebar-accordion(
              :title="$t('contentSize')"
              :addDeviceSelector="false"
              v-if="isEmbedded || isFullscreen"
            )
              om-dropdown-input(
                v-if="!mobilePreview"
                label="width"
                property="selectedPage.subElements.content.desktop.size"
                :items="widthModes"
              )
              template(v-if="contentWidthMode === 'manual' || (mobilePreview && isFullscreen)")
                om-range-input(
                  v-if="isFullscreen && mobilePreview"
                  label="width"
                  property="selectedPage.subElements.content.mobile.maxWidth"
                  :editMobile="true"
                  :min="15"
                  :max="100"
                  :step="1"
                  typeOfComponent="canvas"
                  unit="%"
                )
                om-range-input(
                  v-else
                  label=""
                  property="selectedPage.subElements.content.desktop.maxWidth"
                  :editMobile="isFullscreen"
                  :step="1"
                  :min="150"
                  :max="1920"
                  typeOfComponent="canvas"
                )
                om-dropdown-input(
                  label="horizontalAlignment"
                  property="selectedPage.desktop.horizontalAlign"
                  :items="horizontalAlignment"
                )
              om-dropdown-input(
                label="verticalAlignment"
                property="selectedPage.desktop.verticalAlign"
                :items="verticalAlignment"
              )
              om-range-input(
                v-if="isEmbedded"
                label="minHeight"
                property="selectedPage.desktop.minHeight"
                :min="50"
                :max="650"
                :step="1"
                typeOfComponent="canvas"
              )
            om-border-box(propsProperty="page" subPath="selectedPage")
          sidebar-tab-content#advanced
            om-padding(propsProperty="page" subPath="selectedPage.padding" :showAccordion="false")
            om-margin(
              v-if="!isInCenter || isEmbedded"
              propsProperty="page"
              subPath="selectedPage.margin"
              typeOfComponent="page"
              :showAccordion="false"
            )
            .sidebar-inner-box.bt-1.bb-1.py-0
              custom-class-accordion
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
      CustomClassAccordion: () =>
        import('@/editor/components/sidebar/components/CustomClassAccordion.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        verticalAlignment: [
          { key: 'top', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'flex-end' },
        ],
        horizontalAlignment: [
          { key: 'left', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'flex-end' },
        ],
        widthModes: [
          { key: 'w100', value: '100w' },
          { key: 'manual', value: 'manual' },
        ],
      };
    },
    computed: {
      ...mapState(['hasWheel', 'page', 'selectedPage', 'mobilePreview', 'globalStyle']),
      ...mapGetters(['isNano', 'isSidebar', 'isEmbedded', 'isFullscreen', 'isTemplateEditorMode']),
      isInCenter() {
        return this.globalStyle.overlay.position === 5;
      },
      showSize() {
        const canShow = !(this.isNano || (this.hasWheel && !this.isTemplateEditorMode));
        return (canShow && this.mobilePreview) || (canShow && !this.mobilePreview);
      },
      pageMaxWidth() {
        if (this.hasWheel) return 1000;
        if (this.isEmbedded) return 1300;
        return 980;
      },
      isFitToScreen() {
        return this.getValueOf('globalStyle.overlay.fitToScreen');
      },
      contentWidthMode() {
        return this.selectedPage.subElements.content.desktop.size;
      },
    },
    watch: {
      contentWidthMode(n) {
        if (n === 'manual') {
          this.selectedPage.subElements.content.desktop.maxWidth = 850;
          this.setValueOf('selectedPage.subElements.content.desktop.maxWidth', 850);
        } else {
          this.selectedPage.subElements.content.desktop.maxWidth = null;
          this.setValueOf('selectedPage.subElements.content.desktop.maxWidth', null);
        }
        this.updateElementStyle(this.selectedPage.uid);
      },
    },
    created() {
      this.$bus.$emit('selectElement', this.selectedPage.uid);
    },
  };
</script>
