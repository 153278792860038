<template lang="pug">
div
  sidebar-title
    .sidebar-title-tooltip-container
      span {{ $t('fontManager.title') }}
  .sidebar-inner-box.pt-0.bb-1
    .border-top-h3.pt-3
    .sidebar-input-wrapper.flex-row-baseline.pr-5
      span.font-size-0--8125 {{ $t('fontManager.customTitle') }}:
      span.font-size-0--8125.font-weight-bold.justify-content-center {{ customCount }}
        span.unit.ml-1.align-items-end(v-if="$i18n.locale === 'hu'") db
    .sidebar-input-wrapper.flex-row-baseline.pr-5.mt-3
      span.font-size-0--8125 {{ $t('fontManager.preInstalledTitle') }}:
      span.font-size-0--8125.font-weight-bold.justify-content-center {{ systemCount }}
        span.unit.ml-1.align-items-end(v-if="$i18n.locale === 'hu'") db
    .sidebar-input-wrapper.flex-row-baseline.pr-5.mt-3
      span.font-size-0--8125 {{ $t('fontManager.installedTitle') }}:
      span.font-size-0--8125.font-weight-bold.justify-content-center {{ installedCount }}
        span.unit.ml-1.align-items-end(v-if="$i18n.locale === 'hu'") db
    .sidebar-input-wrapper.flex-row-baseline.pr-5.my-3
      span.font-size-0--8125 {{ $t('fontManager.availableTitle') }}:
      span.font-size-0--8125.font-weight-bold.justify-content-center {{ availableCount }}
        span.unit.ml-1.align-items-end(v-if="$i18n.locale === 'hu'") db
    .sidebar-input-wrapper.flex-row-baseline
      .button.button-primary.button-primary-large(@click="$modal.show('font-management')") {{ buttonText }}
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import fontCounter from '@/mixins/fontCounter';

  export default {
    mixins: [fontCounter],
    computed: {
      ...mapState(['fonts']),
      ...mapGetters(['installedFonts']),
      buttonText() {
        const openIt = this.$t('openIt');
        const title = this.$t('fontManager.title');
        return window.om.i18n.locale === 'hu' ? `${title} ${openIt}` : `${openIt} ${title}`;
      },
    },
  };
</script>
