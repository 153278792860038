<template lang="pug">
  .sidebar-inner-box.pt-0.pb-0
    info-box(name="stepInfo" :heading="$t('sidebarSettings.infoBox.stepInfo.heading')")
      template(#image)
        lottie.brand-lottie(filename="drag_and_drop")
      template(#content)
        span {{ $t('sidebarSettings.infoBox.stepInfo.content') }}
</template>

<script>
  import InfoBox from '@/editor/components/sidebar/components/infoBox/BaseInfoBox.vue';

  export default {
    components: {
      InfoBox,
    },
  };
</script>
