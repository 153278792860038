<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.21 14.29L8.06 14.17C8.00426 14.1322 7.94372 14.1019 7.88 14.08L7.7 14C7.53782 13.9661 7.36976 13.973 7.21092 14.0201C7.05208 14.0673 6.90744 14.1531 6.79 14.27C6.70167 14.3672 6.63064 14.4788 6.58 14.6C6.50432 14.7822 6.48423 14.9827 6.52226 15.1763C6.56028 15.3699 6.65472 15.5479 6.7937 15.688C6.93267 15.828 7.10996 15.9238 7.30326 15.9633C7.49656 16.0028 7.69722 15.9843 7.88 15.91C7.9995 15.852 8.11052 15.778 8.21 15.69C8.34876 15.5494 8.44275 15.3708 8.48013 15.1768C8.5175 14.9828 8.49658 14.7821 8.42 14.6C8.37014 14.4844 8.29889 14.3792 8.21 14.29ZM11.5 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H11.5C12.2956 20 13.0587 19.6839 13.6213 19.1213C14.1839 18.5587 14.5 17.7956 14.5 17V3C14.5 2.20435 14.1839 1.44129 13.6213 0.87868C13.0587 0.316071 12.2956 0 11.5 0ZM12.5 17C12.5 17.2652 12.3946 17.5196 12.2071 17.7071C12.0196 17.8946 11.7652 18 11.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H11.5C11.7652 2 12.0196 2.10536 12.2071 2.29289C12.3946 2.48043 12.5 2.73478 12.5 3V17Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      color: { type: String, default: '#505763' },
      size: { type: Number, default: 20 },
    },
  };
</script>
