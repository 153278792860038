<template lang="pug">
om-modal(
  name="upgrade-plan"
  :title="$t('upgradePlan')"
  :width="1200"
  :scrollable="true"
  @beforeOpen="beforeOpen"
  @closed="closed"
  :clickToClose="false"
)
  template(slot="modal-header")
    template(v-if="progress !== progressStatuses.PROGRESS_THANK_YOU")
      .container-fluid.plan-settings
        .row
          .col-10
            h1.mt-1.mb-3 {{ $t('changePlan') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('upgrade-plan')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12" color="#c2c2c2")
  template(slot="modal-body")
    loading-logo(v-if="loadingPlan")
    upgrade-plan-table(
      v-if="!loadingPlan && progress === progressStatuses.PROGRESS_PLAN"
      :plans="plans"
      :triggers="triggers"
      :selectedPeriod="selectedPeriod"
      :activeColumn="activeColumn"
      :showPeriodSelect="false"
      :canCalculateCorrectUpgradePrice="_canCalculateCorrectUpgradePrice"
      @setPeriod="setPeriod"
      @setPlan="planSelected"
      @sendShopifyOrder="sendShopifyOrder"
    )

    template(v-if="progress === progressStatuses.PROGRESS_BILLING")
      .header-text.billing-address.justify-content-between
        .d-flex.flex-wrap.align-items-center
          billing-address-svg.header-text-icon
          span {{ $t('billingInfo') }}
      .d-flex.customer-data-content.flex-column
        checkout-billing(
          ref="billingComp"
          :showSaveButton="false"
          :billing.sync="billing"
          @saveBilling="storeBillingAndNext"
        )

    template(v-if="progress === progressStatuses.PROGRESS_PAYMENT")
      payment-choose(
        ref="paymentComp"
        v-if="!loadingBilling"
        :region="getRegion"
        :billing="billing"
        :locale="$i18n.locale"
        :currentPaymentMethod="paymentMethod"
        :selectedPayment.sync="selectedPayment"
        :price="getTotalAmount"
        :showButton="false"
        deletablePayments
        @save="savePaymentMethod"
        @payload="braintreePayload = $event.payload"
        @noPaymentMethodRequestable="noPaymentRequestable"
        @loaded="loadingPayment = false"
      )

    template(v-if="progress === progressStatuses.PROGRESS_SUMMARY")
      .header-text.order-summary.justify-content-between
        .d-flex.flex-wrap.align-items-center
          order-summary-svg.header-text-icon
          span {{ $t('orderSummary') }}
      .w-100
        loading-logo(v-if="loadingSummary")
        div(v-show="!loadingSummary")
          .d-flex.customer-data-content
            .row.col-8.p-0
              .col-12
                .row.order-summary-box.mb-3
                  .col-5
                    .order-summary-box-label {{ $t('selectedPlan') }}
                  .col-7
                    .order-summary-box-data-title {{ getPlanNameToShow }}
                    .order-summary-box-data-desc {{ getPlanPrice(getPlan) }} / {{ $t(selectedPeriodLanguageKey) }}
                .row.order-summary-box.mb-3(v-if="shouldDisplayVat")
                  .col-5
                    .order-summary-box-label {{ $t('vatAmount') }}
                  .col-7
                    .order-summary-box-data-title {{ getVatString }}
                .row.order-summary-box.mb-3
                  .col-5
                    .order-summary-box-label {{ $t('billingInfo') }}
                  .col-7
                    .order-summary-box-data-title.mb-2 {{ getFullName }}
                    .order-summary-box-data-benefit(v-if="billing.billingName") {{ billing.billingName }}
                    .order-summary-box-data-benefit {{ billing.address }}
                    .order-summary-box-data-benefit {{ billing.city }}, {{ getCountryByCode(billing.countryCode).text }} {{ billing.postalCode }}
                    .order-summary-box-data-benefit(v-if="billing.euVatNumber") {{ billing.euVatNumber }}
                .row.order-summary-box
                  .col-5
                    .order-summary-box-label {{ $t('paymentMethod') }}
                  .col-7
                    .order-summary-box-data-payment {{ getPaymentSummary }}
            .col-4
              .order-summary-box.order-summary-box-info.alert.alert-primary(
                v-if="(!hasPayingPlan || isInactive) && invalidTerms"
              )
                .col-2.col-md-3.col-lg-2.p-0.mr-1.d-flex.justify-content-center
                  .fa.fa-exclamation-triangle
                .col-10.col-md-9.col-lg-10.p-0
                  div Please accept the terms to proceed

          .d-flex.customer-data-content.flex-column
            .d-flex.align-items-center(v-if="!$store.getters.isPaying || isInactive")
              input#checkout-terms(type="checkbox" v-model="terms" name="checkbox" value="")
              label.mb-0.ml-2(
                for="checkout-terms"
                v-html="$t('termsAndConditions', { link: $t('termsAndConditionsLink') })"
              )
                a.ml-1.font-weight-bold.customer-data-policy(href="#") Terms
    template(v-if="progress === progressStatuses.PROGRESS_THANK_YOU")
      .w-100.d-flex.justify-content-center.pt-4.pb-5
        img(:src="require('@/assets/hi-5.svg')")
  template(slot="modal-footer")
    template
      .w-50 {{ $t('upgradePlanPage.needSomeHelp') }}
        a.ml-1(href="https://calendly.com/optimonk-session" target="_blank") {{ $t('upgradePlanPage.scheduleACall') }}
    template(v-if="progress !== progressStatuses.PROGRESS_PLAN")
      .d-flex.justify-content-end
        om-button#save-payment.ml-auto(primary @click="goToNextStep()") {{ progress !== progressStatuses.PROGRESS_THANK_YOU ? $t('next') : $t('closePopup') }}
</template>

<script>
  import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex';
  import { getCountryByLocale } from '@/config/countries';
  import GET_SALES_AUTH from '@/graphql/GetSalesAuth.gql';
  import { PaymentAdapter } from '@/services/paymentAdapter';
  import { capitalizeFirstLetter, separatePrice } from '@/util';
  import UpgradePlanTable from '@/components/Tables/UpgradePlan.vue';
  import CheckoutBilling from '@/components/Payment/CheckoutBilling.vue';
  import PaymentChoose from '@/components/Payment/PaymentChoose';

  import { canCalculateCorrectUpgradePrice } from '@om/payment/src/helpers/upgradeCalculator';

  const {
    mapGetters: paymentGetters,
    mapActions: paymentActions,
    mapState: paymentState,
    mapMutations: paymentMutations,
  } = createNamespacedHelpers('payment');
  const CREDIT_CARD = 'CreditCard';

  export default {
    components: { UpgradePlanTable, CheckoutBilling, PaymentChoose },
    data() {
      return {
        loadingPlan: true,
        loadingPayment: true,
        loadingSummary: true,
        PaymentAdapter: null,
        progressStatuses: {
          PROGRESS_PLAN: 'plan',
          PROGRESS_BILLING: 'billing',
          PROGRESS_PAYMENT: 'payment',
          PROGRESS_SUMMARY: 'summary',
          PROGRESS_THANK_YOU: 'thankYou',
        },
        progress: 'plan',
        terms: false,
        invalidTerms: false,
        selectedPayment: '',
        braintreePayload: null,
        paymentError: null,
        countries: [
          { text: 'Hungary', value: 97, code: 'HU' },
          { text: 'United States', value: 223, code: 'US' },
        ],
        activeColumn: 'GROWTH',
        lowestPlan: 'GROWTH',
        triggers: [],
      };
    },

    computed: {
      ...mapGetters(['isPaying', 'currentPlan', 'currentPeriod', 'getRegion', 'accountFeatures']),
      ...paymentState([
        'plans',
        'selectedPlan',
        'selectedPeriod',
        'billing',
        'paymentMethod',
        'dateExpires',
        'couponService',
      ]),
      ...paymentGetters([
        'getPlans',
        'isShopifyPayment',
        'isInactive',
        'getPlanDetail',
        'getPlainPlanName',
        'hasFuturePackage',
      ]),
      titles() {
        if (this.triggers.includes('customVariable')) return this.$t('upgradePlanTitles.pro');
        if (this.triggers.length === 1) return this.$t(`upgradePlanTitles.${this.triggers[0]}`);
        if (this.triggers.length > 1) return this.$t('upgradePlanTitles.default');

        return { title: 'Upgrade plan', subtitle: '' };
      },

      title() {
        return this.titles.title;
      },

      subtitle() {
        return this.titles.subtitle;
      },
      getPeriod() {
        return this.selectedPeriod || this.currentPeriod;
      },
      getTotalAmount() {
        const priceString = this.getPlanPrice(this.getPlan);
        const { priceNumber } = separatePrice(priceString);
        return priceNumber + this.calculateVat();
      },
      shouldDisplayVat() {
        const country = this.countries.find(({ code }) => code === this.billing.countryCode);
        let isEuVat = false;
        if (country) {
          isEuVat = country.euVatNumber;
        }
        return (
          this.billing.countryCode === 'HU' || (isEuVat && this.billing.euVatNumber.length === 0)
        );
      },
      getVatString() {
        const priceString = this.getPlanPrice(this.getPlan);
        const { prefix, priceNumber, suffix } = separatePrice(priceString);
        return !priceNumber ? '' : `${prefix} ${this.calculateVat()} ${suffix}`;
      },
      hasPaymentMethod() {
        return !!this.paymentMethod;
      },
      hasPayingPlan() {
        return this.isPaying;
      },
      getFullName() {
        return `${this.billing.firstName} ${this.billing.lastName}`;
      },
      getPaymentSummary() {
        const isBraintree = this.selectedPayment
          ? this.selectedPayment.indexOf('braintree') === 0
          : false;

        if (isBraintree) {
          const payload = this.braintreePayload || {
            type: '',
            details: { cardType: '', email: '' },
          };
          if (payload.type === CREDIT_CARD) {
            return `${payload.details.cardType} (${payload.details.lastFour})`;
          }
          return `${this.$t('payment.braintreepaypal')} (${payload.details.email})`;
        }

        return this.$t('payment.bank_transfer');
      },
      getPlan() {
        return this.getPlans.find(({ name }) => name === this.selectedPlan.toUpperCase());
      },
      _canCalculateCorrectUpgradePrice() {
        return canCalculateCorrectUpgradePrice({
          currentPlan: this.currentPlan,
          dateExpires: this.dateExpires,
          period: this.currentPeriod,
          isShopify: this.isShopifyPayment,
        });
      },

      selectedPeriodKey() {
        let period = 'monthly';
        if (this.getPeriod === 12) {
          period = 'annual';
        } else if (this.getPeriod === 3) {
          period = 'quarterly';
        }
        return period;
      },

      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.getPeriod === 12) {
          period = 'annual';
        } else if (this.getPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },

      getPlanNameToShow() {
        return this.getPlainPlanName(this.getPlan.name.toUpperCase());
      },

      getCouponCodeWithPlanValidation() {
        if (this.hasFuturePackage) return null;
        if (this.hasValidCouponDiscountForSelectedPlan === false) return null;
        return this.couponService.getCouponCode();
      },

      hasValidCouponDiscountForSelectedPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const plan = this.getPlanDetail(this.selectedPlan);
        const periodKey = this.selectedPeriodKey;
        return plan.hasDiscount && plan.hasDiscount[periodKey];
      },
    },

    methods: {
      ...mapActions(['fetchAccount']),
      ...paymentActions(['loadAccount', 'loadBilling', 'loadPlans']),
      ...paymentMutations(['setPlan', 'setPeriod', 'setBilling']),
      capitalizeFirstLetter,
      async beforeOpen(event) {
        this.triggers = event.params.trigger;
        if (this.triggers.includes('abTesting')) {
          this.activeColumn = 'ESSENTIAL';
          this.lowestPlan = 'ESSENTIAL';
        }

        if (this.triggers.includes('unbranded')) {
          this.activeColumn = 'PREMIUM';
          this.lowestPlan = 'PREMIUM';
        }

        if (
          this.triggers.includes('customVariable') ||
          // this.triggers.includes('cartRules') ||
          this.triggers.includes('jsEvent') ||
          this.triggers.includes('ipBlock') ||
          this.triggers.includes('cookie')
        ) {
          this.activeColumn = 'GROWTH';
          this.lowestPlan = 'GROWTH';
        }

        await this.loadBilling();
        this.loadingBilling = false;

        await this.loadPlans();
        this.loadingPlan = false;

        this.$set(this, 'countries', getCountryByLocale(this.$i18n.locale));

        if (this.triggers.includes('pageViews')) {
          const currentPlanIndex = this.getPlans.findIndex(
            (p) => p.name.toUpperCase() === this.currentPlan,
          );
          this.lowestPlan =
            currentPlanIndex + 1 < this.getPlans.length
              ? this.getPlans[currentPlanIndex + 1].name
              : this.getPlans[currentPlanIndex].name;
          this.activeColumn = this.lowestPlan;
        }

        const {
          data: { salesAuth },
        } = await this.$apollo.query({
          query: GET_SALES_AUTH,
        });

        this.$set(
          this,
          'PaymentAdapter',
          new PaymentAdapter(salesAuth, this.accountFeatures, this.$apollo),
        );
      },

      getCountryByCode(code) {
        for (const index in this.countries) {
          if (this.countries[index].code === code) {
            return this.countries[index];
          }
        }

        return false;
      },

      getPlanPrice(plan) {
        if (!plan?.originalPrice) {
          return plan.price[this.selectedPeriodKey];
        }
        return plan.originalPrice[this.selectedPeriodKey];
      },

      calculateVat() {
        if (!this.shouldDisplayVat) {
          return 0;
        }

        const priceString = this.getPlanPrice(this.getPlan);
        const { priceNumber } = separatePrice(priceString);

        const number = parseFloat(
          ((priceNumber + (this.selectedPayment === 'bank_transfer' ? 390 : 0)) * 0.27).toFixed(2),
        );
        return this.getRegion === 'Hungary' ? Math.round(number) : number;
      },

      saveBilling() {
        this.$refs.billingComp.saveBilling();
      },

      storeBillingAndNext(billing) {
        this.setBilling(billing);
        this.progress = this.progressStatuses.PROGRESS_PAYMENT;
      },

      savePayment() {
        this.$refs.paymentComp.savePaymentMethod();
      },

      savePaymentMethod(data) {
        this.braintreePayload = data.payload;
        this.selectedPayment = data.method;
        this.progress = this.progressStatuses.PROGRESS_SUMMARY;
        this.loadingSummary = false;
      },

      noPaymentRequestable() {
        if (this.isBillingFilled && this.getProgress === this.progressStatuses.PROGRESS_SUMMARY) {
          this.goToStep(this.progressStatuses.PROGRESS_PAYMENT);
        }
      },

      setPaymentError(error) {
        this.paymentError = error;
      },

      async confirmOrder() {
        const self = this;

        const prevPackage = this.currentPlan;
        if (!this.terms && !this.$store.getters.isPaying) {
          this.invalidTerms = true;
          return;
        }

        const data = {
          paymentMethod: this.selectedPayment,
          plan: this.getPlan.name,
          period: this.selectedPeriod,
          ...this.billing,
        };
        const country = this.getCountryByCode(data.countryCode);

        if (!country) {
          return; // No such country
        }

        data.country = country.value;

        if (this.braintreePayload) {
          data.paymentNonce = this.braintreePayload.nonce;
        }

        this.loadingSummary = true;
        try {
          data.couponCode = this.getCouponCodeWithPlanValidation;
          const createOrderResponse = await this.PaymentAdapter.sendCreateOrder(data);

          if (createOrderResponse.data.success) {
            try {
              const confirmOrderResponse = await this.PaymentAdapter.sendConfirmOrder(
                data.paymentMethod,
                data,
              );

              if (confirmOrderResponse.data.success) {
                self.$notify({
                  type: 'success',
                  text: 'Order created successfully!',
                });

                this.couponService.afterOrderActions();

                await this.fetchAccount();
                await this.loadAccount();
                await this.loadBilling();
                await this.loadPlans();

                // self.$bus.$emit('pollAccount');

                window.dataLayer.push({
                  event: 'Successful order',
                  paymentMethod: self.selectedPayment,
                  newPackage: self.currentPlan,
                  period: 1,
                  previousPackage: prevPackage,
                });
              } else {
                self.$notify({
                  type: 'error',
                  text: data.error,
                });
              }
            } catch (e) {
              self.$notify({
                type: 'error',
                text: 'Something went wrong!!',
              });
              self.loadingSummary = false;

              console.error(e);
            }

            self.loadingSummary = false;
            self.progress = self.progressStatuses.PROGRESS_THANK_YOU;
          } else {
            self.$notify({
              type: 'error',
              text: 'Something went wrong!!!',
            });
            self.loadingSummary = false;
            console.error(createOrderResponse.data);
          }
        } catch (e) {
          self.$notify({
            type: 'error',
            text: 'Something went wrong!',
          });
          self.loadingSummary = false;
          console.error(e);
        }
      },

      sendShopifyOrder() {
        this.loadingPlan = true;
        const couponCode = this.getCouponCodeWithPlanValidation;

        this.PaymentAdapter.sendShopifyPay(this.selectedPlan, 1, couponCode).then(({ data }) => {
          if (data && data.success) {
            window.location = data.confirmUrl || data.confirmUri;
          } else {
            this.$notify({
              type: 'error',
              text: data.message,
            });

            this.loadingPlan = false;
          }
        });
      },

      planSelected(planName) {
        this.setPlan(planName);
        this.goToNextStep();
      },
      goToNextStep() {
        if (this.progress === this.progressStatuses.PROGRESS_SUMMARY) {
          this.confirmOrder();
          return;
        }

        if (this.progress === this.progressStatuses.PROGRESS_THANK_YOU) {
          this.$modal.hide('upgrade-plan');
        }

        const currentStepIndex = Object.values(this.progressStatuses).indexOf(this.progress);
        const saveMethodName = `save${this.progress.charAt(0).toUpperCase()}${this.progress.substr(
          1,
        )}`;
        const loadingPropertyName = `loading${this.progress
          .charAt(0)
          .toUpperCase()}${this.progress.substr(1)}`;

        if (typeof this[loadingPropertyName] !== 'undefined' && this[loadingPropertyName]) {
          return;
        }

        if (this.isShopifyPayment) {
          this.sendShopifyOrder();
          return;
        }

        if (!this[saveMethodName] || (this[saveMethodName] && this[saveMethodName]())) {
          this.progress = Object.values(this.progressStatuses)[currentStepIndex + 1];
        }
      },

      closed() {
        this.progress = this.progressStatuses.PROGRESS_PLAN;
        this.loadingPayment = true;
        this.loadingSummary = true;
      },
    },
  };
</script>
