<template lang="pug">
sidebar-accordion.embed-accordion(:title="$t('advancedSettings')")
  om-padding(
    v-if="showPadding"
    :propsProperty="propsProperty"
    :typeOfComponent="typeOfComponent"
    :opened="true"
  )
  om-margin(
    v-if="showMargin"
    :propsProperty="propsProperty"
    :typeOfComponent="typeOfComponent"
    :opened="true"
  )
  om-z-index(v-if="!hideZIndex" property="zIndex" :showAccordion="false")
</template>

<script>
  import zIndex from '@/editor/components/sidebar/inputs/zIndex.vue';
  import { mapState } from 'vuex';

  export default {
    components: { 'om-z-index': zIndex },
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
    },
    computed: {
      ...mapState(['selectedElement']),
      showPadding() {
        return ['row', 'column', 'text', 'coupon', 'feedback', 'product', 'customHTML'].includes(
          this.typeOfComponent,
        );
      },
      showMargin() {
        return !['OmFloatingImage'].includes(this.selectedElement.type);
      },
      hideZIndex() {
        return ['pickapresent'].includes(this.typeOfComponent);
      },
    },
  };
</script>
