<template lang="pug">
.sidebar-input-wrapper.d-flex.flex-column.align-items-start
  .d-flex.text-container
    .d-flex.text.pl-0.mb-4
      span {{ $t('borderRadius') }}
    .d-flex.text.pl-0.mb-4(v-if="isThemeKit")
      span.align-self-center {{ $t('borderThemeKitRounding') }}
      .switch.d-flex.align-items-center
        om-switch#customThemeRounding(
          label=""
          v-model="customThemeRounding"
          @switch="setRadius($event)"
        )
    .tooltip-icon.ml-1
      om-tooltip(v-if="isFallbackToDesktop" transition="fade" big :arrow="false")
        span {{ $t(`fallbackToDesktop`) }}
  transition(name="fade" mode="out-in")
    .d-flex.align-items-center.mt-2(:class="{ 'not-editable': isCustomThemeRounding }")
      .row
        .col.mb-2
          .corner-radius-input.d-flex.align-items-end(:style="getFallbackStyle")
            input.simpleInput.mr-2(
              type="number"
              :value="cornerValue(0) || 0"
              :min="0"
              step="1"
              @focus="activeCorner = 'tl'"
              @blur="onBlur"
              @input="onInput($event, 0)"
            )
            .corner-radius-input-unit.tl px
            <svg :class="{ 'active': activeCorner === 'tl', 'mb-1': true  }" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.5 2H14.5C7.87258 2 2.5 7.37258 2.5 14V18" stroke="#505763" stroke-width="2" stroke-linecap="round"/>
            </svg>
        .col
          .corner-radius-input.d-flex.align-items-start(:style="getFallbackStyle")
            input.simpleInput.mr-2(
              type="number"
              :value="cornerValue(3) || 0"
              :min="0"
              step="1"
              @focus="activeCorner = 'bl'"
              @blur="onBlur"
              @input="onInput($event, 3)"
            )
            .corner-radius-input-unit.bl px
            <svg :class="{ 'active': activeCorner === 'bl', 'mt-1': true  }" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 2L2.5 6C2.5 12.6274 7.87258 18 14.5 18L18.5 18" stroke="#505763" stroke-width="2" stroke-linecap="round"/>
            </svg>
      .row
        .col
          om-button.corner-radius-chain(
            ghost
            icon="link-alt"
            iconOnly
            :selected="isChained"
            @click.native="setChained"
          )
      .row
        .col.mb-2
          .corner-radius-input.corner-radius-input-right.d-flex.align-items-end(
            :style="getFallbackStyle"
          )
            <svg :class="{ 'active': activeCorner === 'tr', 'mb-1': true  }" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.5 18L18.5 14C18.5 7.37258 13.1274 2 6.5 2L2.5 2" stroke="#505763" stroke-width="2" stroke-linecap="round"/>
            </svg>
            input.simpleInput(
              type="number"
              :value="cornerValue(1) || 0"
              :min="0"
              step="1"
              @focus="activeCorner = 'tr'"
              @blur="onBlur"
              @input="onInput($event, 1)"
            )
            .corner-radius-input-unit.tr px
        .col
          .corner-radius-input.corner-radius-input-right.d-flex.align-items-start(
            :style="getFallbackStyle"
          )
            <svg :class="{ 'active': activeCorner === 'br', 'mt-1': true  }" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 18L6.5 18C13.1274 18 18.5 12.6274 18.5 6L18.5 2" stroke="#505763" stroke-width="2" stroke-linecap="round"/>
            </svg>
            input.simpleInput(
              type="number"
              :value="cornerValue(2) || 0"
              :min="0"
              step="1"
              @focus="activeCorner = 'br'"
              @input="onInput($event, 2)"
            )
            .corner-radius-input-unit.br px
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import { mapState, mapGetters } from 'vuex';
  import { get as _get } from 'lodash-es';
  import roundingExceptionsMixin from '@/editor/mixins/roundingExceptions';
  import { UilQuestionCircle } from '@iconscout/vue-unicons';

  export default {
    name: 'CornerRadius',
    components: {
      UilQuestionCircle,
    },
    mixins: [itemMixin, roundingExceptionsMixin],
    props: {
      subPath: { type: String, default: '' },
    },
    data: () => ({
      customThemeRounding: false,
      activeCorner: null,
      isFallbackToDesktop: false,
    }),
    computed: {
      ...mapState(['selectedElement', 'mobilePreview', 'themeKit']),
      ...mapGetters(['hasAccountFeature', 'isThemeKit']),

      isCustomThemeRounding() {
        return this.isThemeKit && this.getValueOf(this.getCustomThemeProperty);
      },
      getCustomThemeProperty() {
        if (this.subPath) {
          return `${this.subPath}.border.customThemeRounding`;
        }
        const splitted = this.getPath().split('.');
        return `${splitted.slice(0, splitted.length - 2).join('.')}.border.customThemeRounding`;
      },
      isChained() {
        const path = this.getPath(null);
        return this.smartGetValueOf(`${path}Chained`);
      },
      cornerValue() {
        return (index) => {
          const path = this.getPath(index);
          const cornerValue = this.smartGetValueOf(path);

          if (cornerValue instanceof Object) {
            if (!cornerValue.defaultToDesktop) return;

            this.isFallbackToDesktop = true;
            return cornerValue.desktopValue;
          }
          return cornerValue;
        };
      },
      getFallbackStyle() {
        return this.isFallbackToDesktop && this.mobilePreview ? 'opacity: 0.5' : '';
      },
    },
    watch: {
      mobilePreview(v) {
        if (!v) {
          this.isFallbackToDesktop = false;
        }
        this.customThemeRounding = this.isCustomThemeRounding;
      },
    },
    mounted() {
      if (!this.isThemeKit && this.getValueOf(this.getCustomThemeProperty) === true) {
        this.setRadius(false);
      }
      this.customThemeRounding = this.isCustomThemeRounding;
    },
    methods: {
      setRadius(value) {
        this.setValueOf(this.getCustomThemeProperty, this.customThemeRounding);
        const type = this.themeKit.rounding;

        if (!value) {
          this.updateElementStyle(this.selectedElement.uid);
          return;
        }

        this.$store.commit('updateCustomThemeRadius', { value: type });
      },
      getPath(index = null) {
        const path =
          this.subPath && _get(this.selectedElement, 'type') !== 'OmPage'
            ? `${this.subPath}.border.radius`
            : '$device.border.radius';
        return index !== null ? `${path}.${index}` : path;
      },
      onBlur() {
        this.activeCorner = null;
      },
      onInput(event, index) {
        const path = this.getPath(null);
        const value = event.target.value;
        const itemValue = this.smartGetValueOf(path);

        let newItemValue = JSON.parse(JSON.stringify(itemValue));
        newItemValue[index] = value;

        if (this.isChained) {
          newItemValue = (newItemValue || []).map(() => value);
        }

        newItemValue = newItemValue.map((value) => Number(value));

        this.smartSetValueOf(path, newItemValue);

        this.isFallbackToDesktop = false;
      },
      setChained() {
        const path = this.getPath(null);
        const pathChained = `${path}Chained`;
        const newValue = !this.isChained;
        this.smartSetValueOf(pathChained, newValue);
        if (newValue) {
          const itemValue = this.smartGetValueOf(path);
          const newItemValue = itemValue.map(() => itemValue[0]);
          this.smartSetValueOf(path, newItemValue);
        }
        this.$forceUpdate();
      },
    },
  };
</script>

<style lang="sass">
  .text-container
    width: 100%
    flex-direction: column
    .text
      span
        font-size: 0.75rem
        color: #313233
    .tooltip-icon
      margin-left: auto
      align-self: center
  .corner-radius
    &-chain
      padding: .25rem
      &.selected
        span
          svg
            color: var(--brand-primary-color)
    &-input
      height: 2rem
      &-unit
        font-size: 0.875rem
        position: absolute
        right: 4rem
        &.tl
          left: 2.75rem
          top: calc(50% - 1rem)
        &.bl
          left: 2.75rem
          top: calc(50% - .35rem)
        &.tr
          right: 2.75rem
          top: calc(50% - 1rem)
        &.br
          right: 2.75rem
          top: calc(50% - .35rem)
      input.simpleInput
        text-align: left
        position: relative
        padding-right: 2.5rem !important
        -moz-appearance: textfield
        &::-webkit-inner-spin-button
          opacity: 1
          position: absolute
          right: .25rem
          display: block
          width: 1rem
          height: 1rem
          -webkit-appearance: none
        &:hover::-webkit-inner-spin-button
          -webkit-appearance: inner-spin-button
        &:focus::-webkit-inner-spin-button
          -webkit-appearance: inner-spin-button
      &-right
        input.simpleInput
          paddnig-right: 2rem !important
      svg
        path
          transition: all .1s ease-in-out
        &.active
          path
            stroke: var(--brand-primary-color)
  .switch
    margin-left: auto
  .not-editable
    opacity: 0.5
    pointer-events: none
</style>
