<template>
  <div ref="container" :style="style" />
</template>

<script>
  import lottie from 'lottie-web';
  import { getRandomIntInclusive } from '@/util';

  // REQUIRED FOR LAZY LOADING
  const jsons = {
    bob_laptop: () => import('@/lottie/bob_laptop.json'),
    bob_popup: () => import('@/lottie/bob_popup.json'),
    btn_loading: () => import('@/lottie/btn_loading.json'),
    countdown: () => import('@/lottie/countdown.json'),
    countdown_mobile: () => import('@/lottie/countdown_mobile.json'),
    drag_and_drop: () => import('@/lottie/drag_and_drop.json'),
    floating_elements: () => import('@/lottie/floating_elements.json'),
    hello_teaser: () => import('@/lottie/hello_teaser.json'),
    nanobar_desktop: () => import('@/lottie/nanobar_desktop.json'),
    nanobar_mobile: () => import('@/lottie/nanobar_mobile.json'),
    recommend: () => import('@/lottie/recommend.json'),
    teaser_illu: () => import('@/lottie/teaser_illu.json'),
    thank_you: () => import('@/lottie/thank_you.json'),
    welcome_bob: () => import('@/lottie/welcome_bob.json'),
    follow_up: () => import('@/lottie/follow_up.json'),
    wizard_templates_loading_hu: () => import('@/lottie/wizard_templates_loading_hu.json'),
    wizard_templates_loading_en: () => import('@/lottie/wizard_templates_loading_en.json'),
    activate_campaign: () => import('@/lottie/activate_campaign.json'),
    toast_loading_grey: () => import('@/lottie/toast_loading_grey.json'),
    loginpage_tactic_animation: () => import('@/lottie/loginpage_tactic_animation.json'),
    sab_loader_hu: () => import('@/lottie/sab_loader_hu.json'),
    sab_loader_en: () => import('@/lottie/sab_loader_en.json'),
    wizard_monk: () => import('@/lottie/wizard_monk.json'),
  };

  export default {
    name: 'Lottie',
    props: {
      filename: {
        required: true,
      },
      speed: {
        type: Number,
        required: false,
        default: 1,
      },
      width: {
        type: Number,
        required: false,
        default: null,
      },
      height: {
        type: Number,
        required: false,
        default: null,
      },
      loop: {
        type: Boolean,
        required: false,
        default: true,
      },
      autoPlay: {
        type: Boolean,
        required: false,
        default: true,
      },
      loopDelayMin: {
        type: Number,
        required: false,
        default: 0,
      },
      loopDelayMax: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data() {
      return {
        rendererSettings: {
          scaleMode: 'centerCrop',
          clearCanvas: true,
          progressiveLoad: false,
          hideOnTransparent: true,
        },
        anim: null,
        style: null,
      };
    },

    watch: {
      filename() {
        this.init();
      },
    },

    mounted() {
      this.init();
    },

    methods: {
      async loadJsonData(filename) {
        return jsons[filename]();
      },
      emitLoopComplete() {
        this.$emit('loopComplete', true);
      },
      initStyle(jsonData) {
        const width = this.width ? `${this.width}px` : `${jsonData.w}px`;
        const height = this.height ? `${this.height}px` : `${jsonData.h}px`;

        this.style = {
          width,
          height,
          overflow: 'hidden',
          margin: '0 auto',
        };
      },
      async init() {
        let jsonData;

        try {
          jsonData = await this.loadJsonData(this.filename);
        } catch (e) {
          console.error(
            `lottie animation with filename ${this.filename} not found. Place it in @/lottie!`,
          );
          return;
        }

        this.initStyle(jsonData);

        if (this.anim) {
          this.anim.destroy(); // Releases resources. The DOM element will be emptied.
        }

        this.anim = lottie.loadAnimation({
          container: this.$refs.container,
          renderer: 'svg',
          loop: this.loop,
          autoplay: this.autoPlay,
          animationData: jsonData,
          rendererSettings: this.rendererSettings,
        });

        this.anim.addEventListener('loopComplete', this.emitLoopComplete);

        this.anim.setSpeed(this.speed);

        if (this.loopDelayMin > 0) {
          this.anim.loop = false;
          this.anim.autoplay = false;
          this.executeLoop();
        }
      },

      executeLoop() {
        this.anim.play();
        setTimeout(() => {
          this.anim.stop();
          this.executeLoop();
        }, getRandomIntInclusive(this.loopDelayMin, this.loopDelayMax === 0 ? this.loopDelayMin : this.loopDelayMax));
      },
    },
  };
</script>
