<template lang="pug">
portal(to="root")
  om-modal.lead-sync-modal(
    :name="name"
    :width="420"
    color="light"
    :scrollable="true"
    :clickToClose="true"
    @beforeOpen="beforeOpen"
    @beforeClose="beforeClose"
  )
    template(slot="modal-header")
      .brand-modal-action-icon
        close-icon.cursor-pointer(
          @click.native="$modal.hide(name)"
          :width="14"
          :height="14"
          color="#C2C2C2"
        )
    template(slot="modal-body")
      .container.d-flex.justify-content-center.flex-column.align-items-center
        om-heading.heading(h3) {{ $t('failedIntegration.leadSyncModal.title') }}
        span.sub-title.text-center {{ $t(`failedIntegration.leadSyncModal.${state}.subTitle`, { leadsCnt: totalLeads }) }}
        span.sub-title.text-center(
          v-html="$t(`failedIntegration.leadSyncModal.${state}.resyncLeads`)"
        )
    template(slot="modal-footer")
      .d-flex.justify-content-center
        om-button.mr-4(v-if="state === 'syncStarted'" secondary @click="$modal.hide(name)") {{ $t('close') }}
        om-button(primary @click="handleClick") {{ $t(`failedIntegration.leadSyncModal.${state}.ctaText`) }}
</template>
<script>
  import LogRocket from 'logrocket';
  import { track } from '@/services/xray';

  export default {
    data() {
      return {
        name: 'lead-sync-modal',
        state: 'readyToSync',
        isNew: false,
        failedIntegrationResyncService: null,
        campaignId: null,
        newIntegrationData: null,
        totalLeads: 0,
        resyncLogId: null,
      };
    },
    methods: {
      beforeClose() {
        this.campaignId = null;
        this.newIntegrationData = null;
        this.totalLeads = null;
        this.state = 'readyToSync';
      },
      async beforeOpen(event) {
        const { isNew, totalLeads, campaignId, serviceInstance, newIntegrationData } = event.params;

        this.isNew = isNew;
        this.totalLeads = totalLeads;
        this.campaignId = campaignId;
        this.failedIntegrationResyncService = serviceInstance;

        if (newIntegrationData) {
          this.newIntegrationData = newIntegrationData;
        }
      },
      async handleStartResyncClick() {
        track('resyncModal', { view: this.$route.name, component: 'resync-modal', type: 'start' })
        this.logRocketTrack('resyncModal-resync-start')
        this.resyncLogId = await this.failedIntegrationResyncService.integrationSetupDone(
          this.newIntegrationData,
        );
        this.state = 'syncStarted';

        // this prevents the "you have unsaved changes" modal when trying to leave campaign settings after lead sync modal
        this.$bus.$emit('saveCampaignSettings');
      },
      goToLeadsPage() {
        track('resyncModal', { view: this.$route.name, component: 'resync-modal', type: 'leads-redirect', id: this.resyncLogId })
        this.$router.push({
          name: 'subscribers',
          params: { openIntegrationResyncModal: true, resyncLogId: this.resyncLogId },
        });
      },
      handleClick() {
        if (this.state === 'readyToSync') {
          this.handleStartResyncClick();
        } else {
          this.goToLeadsPage();
        }
      },
      logRocketTrack(name) {
        const logRocketInited = window._logRocketInited;
        if (logRocketInited) {
          LogRocket.track(name)
        }
      }
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .lead-sync-modal
    ::v-deep .brand-modal-body
      padding-top: unset
      padding-bottom: 0.625rem
      .container
        gap: 12px
        .heading
          color: $om-gray-700
        .sub-title
          color: $om-gray-700
        .select-period-select-syncDateRange
          min-width: 21.25rem
        .skeleton-row
          padding: unset
</style>
