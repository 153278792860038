<template lang="pug">
.sidebar-input-wrapper
  label(v-if="label") {{ $t(label) }}
  span.epic-simple-input.epic-simple-input-wide
    input.simpleInput(
      :class="classObj"
      v-model="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :placeholder="placeholder"
      :type="type"
      ref="input"
      :disabled="disabled"
      :min="min"
      :max="max"
    )
    .validation-failed-msg(v-if="validationFailed") {{ $t(validationErrorMsg || validationError.msg) }}
    slot(name="help")
      .sidebar-help-wrapper.mt-2(v-if="helpContent")
        .sidebar-help-content {{ helpContent }}
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import debouncedHistoryUpdate from '@/mixins/debouncedHistoryUpdate';
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [debouncedHistoryUpdate, itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, default: null },
      extraClass: { type: String, required: false },
      styleProperty: { type: String, default: undefined },
      placeholder: { type: String, default: '' },
      validationErrorMsg: { type: String, default: '' },
      disabled: { type: Boolean, default: false },
      type: { type: String, default: 'text' },
      min: { type: String, default: '' },
      max: { type: String, default: '' },
    },
    computed: {
      ...mapState(['validationError']),
      ...mapGetters(['nestedAccess']),
      validationFailed() {
        return this.validationError.property === this.property || this.validationErrorMsg.length;
      },
      classObj() {
        const result = {};
        if (this.extraClass) result[this.extraClass] = true;
        if (this.validationFailed) result.invalid = true;
        return result;
      },
      value: {
        get() {
          if (
            this.property === 'data.socialShare.text' &&
            this.getValueOf(this.property) === 'share'
          ) {
            return this.$t('share');
          }
          return this.getValueOf(this.property);
        },
        set(value) {
          this.setValueOf(this.property, value);
          window.om.bus.$emit('userInputChange', { property: this.property, value });
        },
      },
      helpContent() {
        if (this.property.includes('error')) {
          return this.$t('editorBeta.help.errorMessageInput');
        }

        if (this.property.includes('onlyNewLeadCustomErrorMessage')) {
          return this.$t('editorBeta.help.onlyNewLead');
        }

        if (this.property.includes('customCopyText')) {
          return this.$t('couponCopy.helper');
        }

        return null;
      },
    },
    watch: {
      validationFailed: {
        handler(v) {
          if (v) {
            setTimeout(() => {
              this.$refs.input.focus();
            }, 1000);
          }
        },
        immediate: true,
      },
    },

    methods: {
      ...mapMutations(['updateData', 'updateStyle']),
      focus() {
        return this.$refs.input.focus();
      },
    },
  };
</script>
