<template lang="pug">
.sidebar-input-wrapper.d-flex.justify-center
  .sidebar-title-tooltip-container.pdl-0.w-100
    label.w-100.mb-0(style="position:static") {{$t(label)}}
      i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-if="tooltipContent.length" v-tooltip="tooltip")
    span.epic-simple-input
      .graphic-item(@click="imageManager")
        .graphic-select.image-bg(style="text-align:center;width:4rem;cursor:pointer")
          div(:style="backgroundStyle")
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';

  export default {
    props: {
      propsProperty: { type: String, required: true },
      label: { type: String, required: true },
      tooltipContent: { type: String, default: '' },
    },
    computed: {
      ...mapGetters(['nestedAccess']),
      ...mapState(['images']),
      tooltip() {
        return { content: this.$t(this.tooltipContent), delay: { show: 500, hide: 500 } };
      },
      imageId() {
        return this.nestedAccess(`${this.propsProperty}.imageId`);
      },
      imageUrl() {
        return this.nestedAccess(`${this.propsProperty}.imageUrl`);
      },
      backgroundStyle() {
        const style = {
          width: '100%',
          height: '100%',
          background: `url(${require('@/assets/editor/img/om_picture.svg')}) center center / contain no-repeat`,
        };
        if (this.imageId && this.imageId.length > 0) {
          const image = this.images.find((i) => i._id === this.imageId);
          if (image) style.background = `url(${image.url}) center center / contain no-repeat`;
          else if (this.imageUrl)
            style.background = `url(${this.imageUrl}) center center / contain no-repeat`;
        }

        return style;
      },
    },
    methods: {
      ...mapMutations(['showImageManager', 'setImageManagerDestination']),
      imageManager() {
        this.setImageManagerDestination({ targetAttr: this.propsProperty, simpleImage: true });
        this.showImageManager();
      },
    },
  };
</script>

<style lang="sass"></style>
