<template lang="pug">
#page-structure-wrapper.page-structure-wrapper(
  v-if="hasSelectedPage"
  data-track-property="location:layers|component:layer"
)
  .page-structure-title {{ $t('editor.layers') }}
  ul.om-structure-v2-list
    li.om-structure-v2-list-item.om-structure-v2-overlay(
      v-if="(isPopup || isEmbedded) && selectedPage && !selectedPage.isTeaser"
    )
      overlay(:element="{ uid: 'website-overlay' }")
    li.om-structure-v2-list-item.om-structure-v2-frame
      frame(:element="selectedPage" :interactions="interactions")
      ul
        li.om-structure-v2-list-item.om-structure-v2-close-button(
          v-if="selectedPage && (!selectedPage.isTeaser || isPermanentTeaser) && !isEmbedded"
        )
          close-button(:element="{ uid: 'close-button' }" :interactions="interactions")
        li.om-structure-v2-list-item.om-structure-v2-floating-image(
          v-for="floatingImage in floatingImages"
          :class="'om-structure--v2' + type(floatingImage)"
        )
          OmFloatingImage(:element="floatingImage" :interactions="interactions")
            page-structure-visibility(:element="floatingImage")
        li.om-structure-v2-list-item.om-structure-v2-ribbon(v-if="hasRibbon")
          .om-structure-v2-list-item-box(@click.stop="navigateTo('RibbonPane')")
            span {{ $t('ribbon') }}
        li.om-structure-v2-list-item.om-structure-v2-row(
          v-for="(row, rowIndex) in boxes"
          :class="{ 'om-structure-v2-list-item-hidden': isElementHidden(row) }"
        )
          block(:element="row" :interactions="interactions")
            page-structure-visibility(:element="row")
          ul
            li.om-structure-v2-list-item.om-structure-v2-column(
              v-for="(column, columnIndex) in getColumnsOfRow(row)"
            )
              OmColumn(
                :element="column"
                :row="row"
                :columns="getColumnsOfRow(row)"
                :columnIndex="columnIndex"
                :interactions="interactions"
              )
                page-structure-visibility(:element="column")
              ul
                Elements(:column="column" :interactions="interactions")
      MadeByOM
</template>
<script>
  import { mapState } from 'vuex';
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureVisibility from './components/PageStructureVisibility.vue';

  export default {
    components: {
      PageStructureVisibility,
      Overlay: () => import('./components/pageStructureElements/Overlay.vue'),
      Frame: () => import('./components/pageStructureElements/Frame.vue'),
      CloseButton: () => import('./components/pageStructureElements/CloseButton.vue'),
      Block: () => import('./components/pageStructureElements/Block.vue'),
      Elements: () => import('./components/pageStructureElements/Elements.vue'),
      OmColumn: () => import('./components/pageStructureElements/OmColumn.vue'),
      OmFloatingImage: () =>
        import('./components/pageStructureElements/elements/OmFloatingImage.vue'),
      MadeByOM: () => import('./components/pageStructureElements/MadeByOM.vue'),
    },
    mixins: [pageStructureMixin],
    data() {
      return {
        canvasInteraction: {
          hoveredElementArray: [],
          hoveredElements: {},
          selectedElement: {},
        },
      };
    },
    computed: {
      ...mapState(['selectedPage', 'selectedElement', 'paneL2', 'data']),

      interactions() {
        return {
          ...{ hoveredElementArray: this.canvasInteraction.hoveredElementArray },
          ...{ selectedElement: this.canvasInteraction.selectedElement },
        };
      },
      hasSelectedPage() {
        return !!this.selectedPage?.uid;
      },
      isPermanentTeaser() {
        return this.data.isPermanentTeaser;
      },
    },
    watch: {
      selectedElement(val) {
        if (!val?.uid) {
          return;
        }
        this.canvasInteraction.selectedElement = {
          uid: val.uid,
        };
      },
      paneL2() {
        this.setSelectedElementByPaneL2();
      },
    },
    created() {
      this.initCanvasElementSelectAndHoverListeners();
    },
    methods: {
      initCanvasElementSelectAndHoverListeners() {
        this.$bus?.$on?.('mouseEnterElementOnCanvas', ({ uid }) => {
          this.canvasInteraction.hoveredElements[uid] = {};
          this.reBuildHoveredElementArray();
        });
        this.$bus?.$on?.('mouseLeaveElementOnCanvas', ({ uid }) => {
          if (this.canvasInteraction.hoveredElements[uid]) {
            delete this.canvasInteraction.hoveredElements[uid];
            this.reBuildHoveredElementArray();
          }
        });
        this.$bus?.$on?.('deselectAll', () => {
          this.canvasInteraction.selectedElement = {};
        });
      },
      reBuildHoveredElementArray() {
        const elements = [];
        for (const uid in this.canvasInteraction.hoveredElements) {
          elements.push(uid);
        }
        this.canvasInteraction.hoveredElementArray = elements;
      },
      setSelectedElementByPaneL2() {
        let setByPane = null;
        if (this.paneL2 === 'CloseButtonPane') {
          setByPane = 'close-button';
        }
        if (this.paneL2 === 'PagePane') {
          setByPane = this.selectedPage?.uid;
        }
        if (setByPane) {
          this.canvasInteraction.selectedElement = {
            uid: setByPane,
          };
        }
      },
    },
  };
</script>
