<template lang="pug">
div(:lang="$i18n.locale")
  template(v-if="filterable")
    .brand-table-filter.d-flex.align-items-center
      slot(name="table-filter-row")
  slot(name="table-embedded-content")
  div(:class="{ 'table-responsive brand-campaigns-statistics-table-scroll': isSubscriberTable }")
    .table.brand-table
      .thead(v-if="showHeader" @scroll="equalHorizontalScroll" ref="thead")
        .tr
          .th.col-select(v-if="selectable" scope="col" :checked="allSelect")
            input.form-control(type="checkbox" v-model="allSelect")

          .th(
            v-if="isActiveColumn(column.key)"
            :class="getColumnClasses(column.key)"
            scope="col"
            v-for="column in columns"
          )
            slot(:name="`${column.key}-th`" :column="column")
              span.cursor-pointer(
                v-if="sortable && (column.sortable === undefined || column.sortable !== false)"
                @click="sortBy(column)"
              )
                span {{ column.header }}
                template(v-if="column.header !== ''")
                  i.fas.ml-1(:class="sortIconClass(column.key)")
              span(v-else) {{ column.header }}
      .tbody(
        @scroll="equalHorizontalScroll"
        ref="tbody"
        :style="{ 'overflow-x': !innerItems.length ? 'hidden' : null }"
      )
        slot(name="loading" v-if="loadingContent")
        template(v-else)
          template(v-for="(row, index) in innerItems")
            .tr.brand-table-tr(
              :class="{ 'brand-table-tr-no-b-mr': extendable && extendables[index], 'cursor-pointer': extendable }"
              @click="extendable && toggleExtendable(index)"
              :checked="isChecked(row._id)"
              @mouseleave="$emit('mouseOutFromTableRow', row)"
            )
              .td.brand-table-td.col-select(v-if="selectable")
                input.form-control(
                  type="checkbox"
                  :checked="isChecked(row._id)"
                  @change="handleSingleSelect(row._id)"
                )
              template(v-if="isActiveColumn(column.key)" v-for="column in columns")
                .td(
                  :class="getColumnClasses(column.key)"
                  :title="needColumnTitle && column.key !== 'date' ? getRowData(row, column.key) : null"
                )
                  slot(:name="column.key" :rowData="{ column, row, index }") {{ getRowData(row, column.key) }}
              .td.brand-table-td.flex-grow-0.pl-4.py-1.bl-1(v-if="extendable")
                i.fas.cursor-pointer.font-size-0--9375(
                  :class="{ 'fa-chevron-up': extendables[index], 'fa-chevron-down': !extendables[index] }"
                  @click.stop="toggleExtendable(index)"
                )
            slide-up-down(v-if="extendable" :active="extendables[index]" :duration="500")
              slot(name="extendable" :rowData="{ row, index }")
          .brand-box.brand-box-gray.text-center.flex-wrap.justify-content-center.px-0.flex.flex-column(
            v-if="innerItems.length === 0 && !$store.state.showAdminLoader"
          )
            template(v-if="isSearchResult")
              .pt-3.pb-4
                img(src="@/assets/empty-state/monk-magnifying.svg")
              .pt-6.font-size-1--5.font-weight-bold
                span.font-weight-bold.mt-3.gray-900(
                  v-html="$t('emptyState.campaignList.emptyFilter')"
                )
            template(v-if="!isSearchResult && !hadItems")
              div
                img(
                  v-if="$route.name === 'dashboard'"
                  src="@/assets/admin/svg/campaign-placeholder.svg"
                )
                img.pt-3(
                  v-else-if="!isSubUser"
                  src="@/assets/admin/img/monk.png"
                  style="width: 13.25rem; height: 11.875rem"
                )
              .w-100.pt-4.pb-4.font-size-1--5.font-weight-bold(
                :class="{ 'position-absolute': $route.name === 'dashboard' }"
              )
                .d-flex.flex-column.align-items-center(v-if="$route.name === 'subscribers'")
                  .pt-3.pb-4
                    img(src="@/assets/empty-state/monk-magnifying.svg")
                  .pt-6.font-size-1--5.font-weight-bold
                    span.font-weight-bold.mt-3.gray-900 {{ $t('emptyState.leads.emptyFilter') }}
                slot(name="no-result" v-else-if="$route.name === 'dashboard'")
                span(v-else-if="$route.name === 'campaigns'") {{ $t('createFirstCampaign') }}
  nav.d-flex-flex-wrap(v-if="paginate && allItemsCount")
    .row
      .col-sm-6.d-flex.justify-content-start.align-items-center.py-3
        om-pagination-list(
          :label="$t('viewPerPage')"
          v-model.number="pagination.limit"
          :pageFrom="itemsFrom"
          :pageTo="itemsTo"
          :allPageNum="allItemsCount"
          :limits="limits"
          @paginationLimitChange="limitChange($event)"
        )
      .col-sm-6.d-flex.justify-content-end.py-3(v-if="pageCount !== 1")
        om-pagination(
          :value="pagination.page"
          :maxPage="pageCount"
          @paginateTo="pageChange($event)"
        )
</template>

<script>
  import { get as _get } from 'lodash-es';
  import { mapGetters } from 'vuex';
  import tableMixin from '@/mixins/table';

  export default {
    mixins: [tableMixin],
    props: {
      name: { type: String, default: '' },
      needColumnTitle: { type: Boolean, default: false },
      items: { type: Array, required: true },
      columns: { type: Array, required: true },
      columnClasses: { type: Object, default: undefined },
      availableColumns: { type: Array, default: null },
      selectable: { type: Boolean, default: true },
      sortable: { type: Boolean, default: true },
      serverSort: { type: Boolean, default: true },
      paginate: { type: Boolean, default: true },
      serverPaginate: { type: Boolean, default: true },
      filterable: { type: Boolean, default: false },
      allItemsCount: { type: Number, default: null },
      showHeader: { type: Boolean, default: true },
      loadingContent: { type: Boolean, default: false },
      pagination: {
        type: Object,
        default: () => {
          return { page: 1, limit: 30 };
        },
      },
      sorting: { type: Object, default: null },
      isSearchResult: { type: Boolean, default: false },
      limits: {
        type: Array,
        default: () => {
          return [30, 50, 100];
        },
      },
      extendable: { type: Boolean, default: false },
      customSortFields: { type: Object, default: () => ({}) },
      addSubscriberClasses: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        selectedIds: [],
        allSelect: false,
        hadItems: false, // dont show greetings texts if table had items
        innerSorting: this.sorting
          ? this.sorting
          : this.columns.find((c) => c.key === 'createdAt')
          ? { field: 'createdAt', direction: -1 }
          : { field: 'name', direction: 1 },
        extendables: [],
      };
    },

    computed: {
      ...mapGetters(['isSubUser']),
      isSubscriberTable() {
        return (
          this.addSubscriberClasses &&
          (this.$route.name === 'subscribers' || this.$route.name === 'campaign_variants')
        );
      },
      itemsFrom() {
        return (this.pagination.page - 1) * this.pagination.limit + 1;
      },
      itemsTo() {
        const pageCapacity = this.pagination.page * this.pagination.limit;
        return pageCapacity > this.allItemsCount ? this.allItemsCount : pageCapacity;
      },
      pageCount() {
        return Math.ceil(this.allItemsCount / this.pagination.limit);
      },
      innerItems() {
        let innerItems = this.items;

        if (!this.serverSort && this.sortable) {
          innerItems = innerItems.slice().sort((i1, i2) => {
            const sorters = {
              string: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
              number: (a, b) => a - b,
            };

            const getField = (row) =>
              this.customSortFields[this.innerSorting.field]
                ? this.customSortFields[this.innerSorting.field](row)
                : this.innerSorting.field;
            const getValue = (row) => _get(row, getField(row));

            const sorter = typeof getValue(i1) || 'string';

            return this.innerSorting.direction === 1
              ? sorters[sorter](getValue(i1), getValue(i2))
              : sorters[sorter](getValue(i2), getValue(i1));
          });
        }

        if (!this.serverPaginate && this.paginate) {
          innerItems = innerItems.slice(
            (this.pagination.page - 1) * this.pagination.limit,
            this.pagination.page * this.pagination.limit,
          );
        }

        return innerItems;
      },
    },

    watch: {
      allItemsCount(v) {
        if (v > 0) this.hadItems = true;
      },
      allSelect(v) {
        this.selectedIds = v ? this.items.map((i) => i._id) : [];
        this.$bus.$emit('allSelectTable', v);
      },
      selectedIds() {
        this.$emit('selectedIdsChange', this.selectedIds);
      },
      innerSorting: {
        handler() {
          this.$emit('sortingChange', this.innerSorting);
        },
        deep: true,
      },
      items: {
        handler() {
          if (this.items.length === 1) {
            if (!this.extendables[0]) this.toggleExtendable(0);
          }
        },
        deep: true,
      },
    },

    created() {
      this.resetExtendables();
    },

    mounted() {
      this.$bus.$on('allSelectTableBulk', (v) => {
        this.allSelect = v;
      });
      this.$bus.$on('deselectAllTable', () => {
        this.selectedIds = [];
        this.allSelect = false;
      });
      this.$bus.$on('resetTableHadItems', this.resetHadItems);
    },

    beforeDestroy() {
      this.$bus.$off('resetTableHadItems', this.resetHadItems);
    },

    methods: {
      resetHadItems() {
        this.hadItems = false;
      },
      equalHorizontalScroll(e) {
        if (e.target.classList.contains('thead')) {
          this.$refs.tbody.scrollLeft = e.target.scrollLeft;
        } else {
          this.$refs.thead.scrollLeft = e.target.scrollLeft;
        }
      },
      limitChange(newLimit) {
        const page = 1;
        this.resetExtendables();
        this.$emit('paginationChange', { page, limit: parseInt(newLimit, 10) });
      },
      pageChange(newPage) {
        const { page } = this.pagination;

        if (page !== newPage) {
          this.resetExtendables();
        }
        this.$emit('paginationChange', {
          page: parseInt(newPage, 10),
          limit: parseInt(this.pagination.limit, 10),
        });
      },
      isActiveColumn(value) {
        if (!this.availableColumns) return true;
        if (this.availableColumns.includes(value)) return true;
        return false;
      },
      isChecked(_id) {
        return this.selectedIds.indexOf(_id) > -1;
      },
      handleSingleSelect(_id) {
        const index = this.selectedIds.indexOf(_id);
        if (index === -1) {
          // add
          this.selectedIds.push(_id);
        } else {
          // remove
          this.selectedIds.splice(index, 1);
        }
      },
      sortBy(column) {
        // this.pagination.page = this.pagination.page !== 1 ? 1 : this.pagination.page // reset if not on first page

        if (this.innerSorting.field === column.key) {
          this.innerSorting.direction = this.innerSorting.direction === 1 ? -1 : 1;
        } else {
          this.innerSorting.field = column.key;
          this.innerSorting.direction = -1;
        }
      },
      genPagination() {
        const delta = 2;
        const left = this.pagination.page - delta;
        const right = this.pagination.page + delta + 1;
        let pages = [];

        pages = Array.from({ length: this.pageCount }, (v, k) => k + 1).filter(
          (i) => i && i >= left && i < right,
        );

        return pages;
      },
      getColumnClasses(key) {
        const defaultClasses = [];
        if (`${key}`.includes('custom')) {
          defaultClasses.push('brand-subscriber-table-customfields');
        }
        return this.columnClasses !== undefined && this.columnClasses[key] !== undefined
          ? this.columnClasses[key]
          : defaultClasses;
      },
      sortIconClass(header) {
        if (this.innerSorting.field === header) {
          return {
            'fa-sort-up': this.innerSorting.direction === 1,
            'fa-sort-down': this.innerSorting.direction === -1,
          };
        }
        return {
          'fa-sort': true,
        };
      },
      resetExtendables() {
        if (this.extendable) {
          this.extendables = Array(this.items.length).fill(false);
        }
      },
      toggleExtendable(index) {
        if (this.extendable) {
          const current = this.extendables[index];
          this.resetExtendables();
          this.$set(this.extendables, index, !current);
        }
      },
    },
  };
</script>
