<template lang="pug">
sidebar-accordion(:title="$t('fontEdit')" :opened="isOpen")
  om-font(:propsProperty="propsProperty"
          :subPath="subPath"
          :typeOfComponent="typeOfComponent")
</template>

<script>
  import OmFont from '@/editor/components/sidebar/inputs/OmFont.vue';

  export default {
    components: { OmFont },
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: 'desktop' },
      isOpen: { type: Boolean, default: false },
      typeOfComponent: { type: String, default: 'global' },
    },
  };
</script>
