<template lang="pug">
.vue-notification-group(:style="styles")
  component(
    :is="componentName"
    :name="animationName"
    @enter="enter"
    @leave="leave"
    @after-leave="clean"
  )
    .vue-notification-wrapper(
      v-for="item in active"
      :key="item.id"
      :style="notifyWrapperStyle(item)"
      :data-id="item.id"
    )
      slot(name="body" :class="[classes, item.type]" :item="item" :close="() => destroy(item)")
        OmToast(
          :color="item.color"
          :type="item.type"
          :onClick="item.onClick"
          :closable="item.closable"
        )
          span(v-html="notificationText(item)")
</template>
<script>
  import plugin from '../OmToast';
  import { events } from '../events';
  import { Id, listToDirection } from '../util';
  import defaults from '../defaults';
  import parseNumericValue from '../parser';

  const STATE = {
    IDLE: 0,
    DESTROYED: 2,
  };

  export default {
    components: {
      OmToast: () => import('@/components/Elements/Toast/Toast.vue'),
      VelocityGroup: () => import('./VelocityGroup.vue'),
      CssGroup: () => import('./CssGroup.vue'),
    },
    props: {
      group: {
        type: String,
        default: '',
      },

      width: {
        type: [Number, String],
        default: 300,
      },

      reverse: {
        type: Boolean,
        default: false,
      },

      position: {
        type: [String, Array],
        default: () => {
          return defaults.position;
        },
      },

      classes: {
        type: String,
        default: 'vue-notification',
      },

      animationType: {
        type: String,
        default: 'css',
        validator(value) {
          return value === 'css' || value === 'velocity';
        },
      },

      animation: {
        type: Object,
        default() {
          return defaults.velocityAnimation;
        },
      },

      animationName: {
        type: String,
        default: defaults.cssAnimation,
      },

      speed: {
        type: Number,
        default: 300,
      },

      duration: {
        type: Number,
        default: 6000,
      },

      closable: {
        type: Boolean,
        default: false,
      },

      delay: {
        type: Number,
        default: 0,
      },

      max: {
        type: Number,
        default: Infinity,
      },

      ignoreDuplicates: {
        type: Boolean,
        default: false,
      },

      closeOnClick: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        list: [],
        velocity: plugin.params.velocity,
      };
    },
    computed: {
      actualWidth() {
        return parseNumericValue(this.width);
      },

      isVelocityAnimation() {
        return this.animationType === 'velocity';
      },

      componentName() {
        return this.isVelocityAnimation ? 'VelocityGroup' : 'CssGroup';
      },

      styles() {
        const { x, y } = listToDirection(this.position);
        const width = this.actualWidth.value;
        const suffix = this.actualWidth.type;

        const styles = {
          width: width + suffix,
          position: 'fixed',
          padding: '10px',
          [y]: '0px',
        };

        if (x === 'center') {
          styles.left = `calc(50% - ${width / 2}${suffix})`;
        } else {
          styles[x] = '0px';
        }

        return styles;
      },

      active() {
        return this.list.filter((v) => v.state !== STATE.DESTROYED);
      },

      botToTop() {
        return this.styles.hasOwnProperty('bottom');
      },
    },
    mounted() {
      events.$on('add', this.addItem);
      events.$on('close', this.closeItem);
    },
    methods: {
      notificationText(item) {
        if (!item) return '';

        const { title, text } = item;

        if (title && text) return `<b>${title}</b><br>${text}`;

        if (title) return `<b>${title}</b>`;

        if (text) return text;

        return '';
      },
      addItem(event) {
        event.group = event.group || '';

        if (this.group !== event.group) {
          return;
        }

        if (event.clean || event.clear) {
          this.destroyAll();
          return;
        }

        const duration = typeof event.duration === 'number' ? event.duration : this.duration;

        const speed = typeof event.speed === 'number' ? event.speed : this.speed;

        const ignoreDuplicates =
          typeof event.ignoreDuplicates === 'boolean'
            ? event.ignoreDuplicates
            : this.ignoreDuplicates;

        const { title, color, text, type, data, id, onClick } = event;

        const item = {
          id: id || Id(),
          title,
          color,
          text,
          type,
          state: STATE.IDLE,
          speed,
          length: duration + 2 * speed,
          closable: this.closable,
          data,
          onClick,
        };

        if (duration > 0) {
          item.timer = setTimeout(() => {
            this.destroy(item);
          }, item.length);
        }

        const direction = this.reverse ? !this.botToTop : this.botToTop;

        let indexToDestroy = -1;

        const isDuplicate = this.active.some((item) => {
          return item.title === event.title && item.text === event.text;
        });

        const canAdd = ignoreDuplicates ? !isDuplicate : true;

        if (!canAdd) return;

        if (direction) {
          this.list.push(item);

          if (this.active.length > this.max) {
            indexToDestroy = 0;
          }
        } else {
          this.list.unshift(item);

          if (this.active.length > this.max) {
            indexToDestroy = this.active.length - 1;
          }
        }

        if (indexToDestroy !== -1) {
          this.destroy(this.active[indexToDestroy]);
        }
      },

      closeItem(id) {
        this.destroyById(id);
      },

      notifyWrapperStyle(item) {
        return this.isVelocityAnimation ? null : { transition: `all ${item.speed}ms` };
      },

      destroy(item) {
        clearTimeout(item.timer);
        item.state = STATE.DESTROYED;

        if (!this.isVelocityAnimation) {
          this.clean();
        }
      },

      destroyById(id) {
        const item = this.list.find((v) => v.id === id);

        if (item) {
          this.destroy(item);
        }
      },

      destroyAll() {
        this.active.forEach(this.destroy);
      },

      getAnimation(index, el) {
        const animation = this.animation[index];

        return typeof animation === 'function' ? animation.call(this, el) : animation;
      },

      enter({ el, complete }) {
        const animation = this.getAnimation('enter', el);

        this.velocity(el, animation, {
          duration: this.speed,
          complete,
        });
      },

      leave({ el, complete }) {
        const animation = this.getAnimation('leave', el);

        this.velocity(el, animation, {
          duration: this.speed,
          complete,
        });
      },

      clean() {
        this.list = this.list.filter((v) => v.state !== STATE.DESTROYED);
      },
    },
  };
</script>
