import Container from '@/editor/components/Container';
import CustomEditorBox from '@/editor/components/CustomEditorBox';
import CustomEditorBoxBeta from '@/editor/components/CustomEditorBoxBeta';
import GlobalDisplay from '@/editor/components/sidebar/inputs/GlobalDisplay.vue';
import GlobalDisplayBeta from '@/editor/components/sidebar/inputs/GlobalDisplayBeta.vue';
import Sidebar from '@/editor/components/sidebar/Sidebar';
import SidebarColorPalette from '@/editor/components/sidebar/SidebarColorPalette';
import ThemeFontManager from '@/editor/components/sidebar/ThemeFontManager';
import RoundingManager from '@/editor/components/sidebar/RoundingManager';
import OmBackgroundBox from '@/editor/components/sidebar/inputs/OmBackground.vue';
import OmBorderBox from '@/editor/components/sidebar/inputs/OmBorder.vue';
import OmTextShadow from '@/editor/components/sidebar/inputs/OmTextShadow.vue';
import OmCloseButton from '@/editor/components/sidebar/inputs/OmCloseButton.vue';
import OmColorInput from '@/editor/components/color/OmColorInputWrapper.vue';
import OmDropdownInput from '@/editor/components/sidebar/inputs/DropdownInput';
import OmFontAwesomeButton from '@/editor/components/sidebar/inputs/OmFontAwesomeButton.vue';
import OmMargin from '@/editor/components/sidebar/inputs/Margin.vue';
import OmPadding from '@/editor/components/sidebar/inputs/Padding.vue';
import OmRangeInput from '@/editor/components/sidebar/inputs/RangeInput';
import OmShadowBox from '@/editor/components/sidebar/inputs/OmShadow.vue';
import OmSimpleInput from '@/editor/components/sidebar/inputs/SimpleInput';
import OmSize from '@/editor/components/sidebar/inputs/OmSize.vue';
import OmColorPicker from '@/editor/components/color/OmColorPicker';
import OmStyle from '@/editor/components/sidebar/inputs/OmStyle.vue';
import OmSwitches from '@/editor/components/sidebar/inputs/Switches.vue';
import SimpleImage from '@/editor/components/sidebar/inputs/SimpleImage.vue';
import ImagePicker from '@/editor/components/sidebar/inputs/ImagePicker.vue';
import Quill from '@/editor/components/sidebar/inputs/Quill';
import SidebarOption from '@/editor/components/sidebar/SidebarOption.vue';
import SidebarTitle from '@/editor/components/sidebar/SidebarTitle';
import CopyElementId from '@/editor/components/CopyElementId';
import InputElementChooser from '@/editor/components/InputElementChooser';

import OmProgressiveImage from '@/components/OmProgressiveImage.vue';
import FontDropdown from '@/editor/components/sidebar/inputs/FontDropdown.vue';
import Expandable from '@/editor/components/Expandable.vue';

import OmFont from '@/editor/components/sidebar/inputs/OmFont.vue';
import SidebarAccordion from '@/editor/components/sidebar/SidebarAccordion.vue';
import OmChooser from '@/editor/components/sidebar/inputs/OmChooser.vue';
import MobileSettings from '@/editor/components/sidebar/inputs/MobileSettings.vue';
import OmDeviceChooser from '@/editor/components/sidebar/inputs/OmDeviceChooser.vue';

import AdvancedSettings from '@/editor/components/sidebar/inputs/AdvancedSettings.vue';
import InputGroup from '@/editor/components/sidebar/inputs/InputGroup.vue';
import ResponsiveSettings from '@/editor/components/sidebar/inputs/ResponsiveSettings.vue';
import DesktopSvg from '@/editor/components/svg/DesktopSvg.vue';
import MobileSvg from '@/editor/components/svg/MobileSvg.vue';
import SetElementDefaults from '@/editor/components/SetElementDefaults';
import ElementDefaultValues from '@/editor/components/ElementDefaultValues';
import TabWrapper from '@/editor/components/sidebar/tab/Wrapper.vue';
import TabItem from '@/editor/components/sidebar/tab/Item.vue';
import TabContent from '@/editor/components/sidebar/tab/Content.vue';
import CommonAdvancedTabContent from '@/editor/components/sidebar/tab/predefined/CommonAdvancedTabContent.vue';
import CommonStyleTabContent from '@/editor/components/sidebar/tab/predefined/CommonStyleTabContent.vue';
import StepInfo from '@/editor/components/sidebar/components/infoBox/StepInfoBox.vue';

const registerEditorComponents = (vueInstance) => {
  vueInstance.component('CustomEditorBox', CustomEditorBox);
  vueInstance.component('CustomEditorBoxBeta', CustomEditorBoxBeta);
  vueInstance.component('Container', Container);
  vueInstance.component('FeatureForFeedback', () =>
    import('@/editor/components/modals/FeatureForFeedback.vue'),
  );
  vueInstance.component('CouponSettings', () =>
    import('@/editor/components/modals/CouponSettings.vue'),
  );
  vueInstance.component('MoneyFormat', () => import('@/editor/components/modals/MoneyFormat.vue'));
  vueInstance.component('SmartTagModal', () =>
    import('@/editor/components/modals/SmartTag/SmartTag.vue'),
  );
  vueInstance.component('AddThemeColor', () =>
    import('@/editor/components/modals/themeColor/Add.vue'),
  );
  vueInstance.component('FontManager', () => import('@/editor/components/modals/FontManager.vue'));
  vueInstance.component('LuckyWheelSettings', () =>
    import('@/editor/components/modals/LuckyWheelSettings.vue'),
  );
  vueInstance.component('ScratchCardSettings', () =>
    import('@/editor/components/modals/ScratchCardSettings.vue'),
  );
  vueInstance.component('ScratchCardTest', () =>
    import('@/editor/components/modals/ScratchCardTest.vue'),
  );
  vueInstance.component('FormChooser', () =>
    import('@/editor/components/modals/formManager/FormChooser.vue'),
  );
  vueInstance.component('FormChooserNew', () =>
    import('@/editor/components/modals/formManager/FormChooserNew.vue'),
  );
  vueInstance.component('FormChooserEdit', () =>
    import('@/editor/components/modals/formManager/FormChooserEdit.vue'),
  );
  vueInstance.component('OmColorInput', OmColorInput);
  vueInstance.component('OmRangeInput', OmRangeInput);
  vueInstance.component('OmSimpleInput', OmSimpleInput);
  vueInstance.component('OmDropdownInput', OmDropdownInput);
  vueInstance.component('OmBackgroundBox', OmBackgroundBox);
  vueInstance.component('OmBorderBox', OmBorderBox);
  vueInstance.component('OmTextShadowBox', OmTextShadow);
  vueInstance.component('OmStyle', OmStyle);
  vueInstance.component('OmFont', OmFont);
  vueInstance.component('OmSize', OmSize);
  vueInstance.component('OmEditorColorPicker', OmColorPicker);
  vueInstance.component('OmShadow', OmShadowBox);
  vueInstance.component('GlobalDisplayBox', GlobalDisplay);
  vueInstance.component('GlobalDisplayBoxBeta', GlobalDisplayBeta);
  vueInstance.component('OmSwitches', OmSwitches);
  vueInstance.component('OmFontAwesomeButton', OmFontAwesomeButton);
  vueInstance.component('OmPopupClose', OmCloseButton);
  vueInstance.component('OmPadding', OmPadding);
  vueInstance.component('OmMargin', OmMargin);
  vueInstance.component('OmSimpleImage', SimpleImage);
  vueInstance.component('OmImagePicker', ImagePicker);
  vueInstance.component('QuillEditor', Quill);
  vueInstance.component('SidebarTitle', SidebarTitle);
  vueInstance.component('SidebarOption', SidebarOption);
  vueInstance.component('Sidebar', Sidebar);
  vueInstance.component('SidebarColorPalette', SidebarColorPalette);
  vueInstance.component('ThemeFontManager', ThemeFontManager);
  vueInstance.component('RoundingManager', RoundingManager);
  vueInstance.component('CopyElementId', CopyElementId);
  vueInstance.component('SetElementDefaults', SetElementDefaults);
  vueInstance.component('InputElementChooser', InputElementChooser);
  vueInstance.component('OmProgressiveImage', OmProgressiveImage);
  vueInstance.component('FontDropdown', FontDropdown);
  vueInstance.component('Expandable', Expandable);
  vueInstance.component('SidebarAccordion', SidebarAccordion);
  vueInstance.component('OmChooser', OmChooser);
  vueInstance.component('OmMobileSettings', MobileSettings);
  vueInstance.component('OmDeviceChooser', OmDeviceChooser);
  vueInstance.component('CustomHtmlSettings', () =>
    import('@/editor/components/modals/CustomHTMLSettings.vue'),
  );
  vueInstance.component('MissingCustomFields', () =>
    import('@/editor/components/modals/MissingCustomFields.vue'),
  );
  vueInstance.component('PickAPresentSettings', () =>
    import('@/editor/components/modals/PickAPresentSettings.vue'),
  );
  vueInstance.component('PickAPresentTest', () =>
    import('@/editor/components/modals/PickAPresentTest.vue'),
  );

  vueInstance.component('OmAdvancedSettings', AdvancedSettings);
  vueInstance.component('OmInputGroup', InputGroup);
  vueInstance.component('OmResponsiveSettings', ResponsiveSettings);
  vueInstance.component('DesktopSvg', DesktopSvg);
  vueInstance.component('MobileSvg', MobileSvg);
  vueInstance.component('ElementDefaultValues', ElementDefaultValues);

  vueInstance.component('SidebarTabWrapper', TabWrapper);
  vueInstance.component('SidebarTabItem', TabItem);
  vueInstance.component('SidebarTabContent', TabContent);
  vueInstance.component('SidebarTabPredefinedAdvanced', CommonAdvancedTabContent);
  vueInstance.component('SidebarTabPredefinedStyle', CommonStyleTabContent);

  vueInstance.component('StepInfoBox', StepInfo);
};

export { registerEditorComponents };
