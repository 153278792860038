import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['installedFonts']),
    customCount() {
      return this.installedFonts.filter((f) => f.custom).length;
    },
    systemCount() {
      return this.installedFonts.filter((f) => f.preInstalled).length;
    },
    installedCount() {
      return this.installedFonts.filter((f) => !f.preInstalled).length;
    },
    availableCount() {
      const installed = this.installedFonts.map((f) => f.key);
      let count = 0;
      if (this.fonts) {
        const fKeys = Object.keys(this.fonts);
        count = fKeys.filter((k) => !installed.includes(k)).length;
      }
      return count;
    },
  },
};
