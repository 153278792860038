export default {
  props: {
    embedded: { type: Boolean, default: false },
  },
  methods: {
    toggleShow() {
      if (!this.embedded) {
        this.show = !this.show;
      }
    },
  },
};
