import themeColorsMixin from '@/editor/mixins/themeColors';
import { findAncestor, isSvgUrl } from '@/editor/util';
import { ELEMENTS } from '@om/template-properties/src/propertyHelper/index';
import { get as _get } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';

const frame = () => {
  const workspace = document.getElementById('workspaceFrame');
  return workspace;
};

const _getFrameElement = (id) => {
  return frame().contentDocument.getElementById(id);
};

const frameStore = () => {
  const _frame = frame();
  return _frame && _frame.contentWindow && _frame.contentWindow.om
    ? _frame.contentWindow.om.store
    : null;
};
export default {
  data() {
    return {
      elements: [],
      interval: null,
      hasRibbon: false,
      isPopup: true,
      floatingImages: [],
    };
  },
  mixins: [themeColorsMixin],
  props: {
    element: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.fetchElements();
    this.interval = setInterval(this.fetchElements, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState(['mobilePreview', 'selectedPage', 'globalStyle', 'paneL2']),
    ...mapGetters(['isEmbedded', 'hasAccountFeature']),

    boxes() {
      if (!this.selectedPage?.uid) return [];
      return this.elements.filter(
        (e) => e.type === ELEMENTS.OmRow && e.pageId === this.selectedPage.uid,
      );
    },

    getRows() {
      return this.elements.filter((e) => e.type === ELEMENTS.OmRow);
    },

    pageTitle() {
      return this.selectedPage?.data.title;
    },

    getText() {
      const rawText = this.removeHTMLTags(this.element.data.text);
      if (rawText) {
        return rawText;
      }

      return this.type(this.element);
    },

    getPlaceHolder() {
      return _get(this.element, 'data.epicPlaceholder') || this.type(this.element);
    },

    getFirstOption() {
      return (
        _get(this.element, 'data.form.customSettings.options[0].key') || this.type(this.element)
      );
    },

    getImage() {
      const imageId = _get(this.element, 'desktop.background.imageId');
      let result = '';
      if (imageId) {
        const image = frameStore().getters.findImage(imageId);
        result = image?.url;
      }
      return result || this.defaultImage;
    },
  },
  watch: {
    selectedPage: {
      handler(n, o) {
        if (!n?.uid) return;
        if (n.uid !== o.uid) {
          this.fetchElements();
        }
      },
      deep: true,
    },
  },
  methods: {
    getColumnsOfRow(row) {
      return this.elements.filter((e) => e.type === ELEMENTS.OmCol && e.rowId === row.uid);
    },

    getPages() {
      return window.om.template.elements.filter(
        (el) => el.type === ELEMENTS.OmPage && !el.isTeaser,
      );
    },
    getPagesAndTeaser() {
      return window.om.template.elements.filter((el) => el.type === ELEMENTS.OmPage);
    },
    getFirstColumnIdOfPages() {
      const pages = this.getPages();
      return pages.map((page) => {
        const { uid } = page;
        const rows = this.getFilteredElement(ELEMENTS.OmRow, uid);
        const [firstRow] = rows;
        const columns = this.getFilteredElementWithRowId(ELEMENTS.OmCol, uid, firstRow.uid);
        const [first] = columns;
        return first.uid;
      });
    },

    getFilteredElement(type, pageId) {
      return window.om.template.elements.filter(
        (element) => element.type === type && element.pageId === pageId,
      );
    },

    getFilteredElementWithRowId(type, pageId, rowId) {
      return window.om.template.elements.filter(
        (element) => element.type === type && element.pageId === pageId && element.rowId === rowId,
      );
    },

    getLastColumnIdOfPages() {
      const pages = this.getPages();
      return pages.map((page) => {
        const { uid } = page;
        const rows = this.getFilteredElement(ELEMENTS.OmRow, uid);
        const row = rows[rows.length - 1];
        const columns = this.getFilteredElementWithRowId(ELEMENTS.OmCol, uid, row.uid);
        const last = columns[columns.length - 1];
        return last.uid;
      });
    },

    getElementByRowId(rowId) {
      return this.elements.filter((e) => e.rowId === rowId);
    },

    getTwoOrThreeBlock() {
      const rows = window.om.template.elements.filter(
        (row) =>
          row.type === ELEMENTS.OmRow &&
          Array.isArray(row.data.columnSplit) &&
          row.data.columnSplit.length > 1,
      );
      return rows;
    },

    getWidth() {
      return Math.max(
        window.parent.document.documentElement.clientWidth || 0,
        window.parent.innerWidth || 0,
      );
    },

    navigateTo(pane) {
      const editorBetaRouteName = 'variant_edit';

      if (pane === 'OverlayPane' && this.$route.name === editorBetaRouteName) {
        this.$bus.$emit('openWebSiteOverlay');
        return;
      }
      this.$bus.$emit('activatePane', { pane, level: 2 });

      if (this.getWidth() <= 1440) {
        this.$bus.$emit('closeSideBar');
      }
      this.$bus.$emit('openRightSidebar', true);
    },
    navigateToPageOrTeaser(element) {
      this.selectElement(element);
      this.$bus.$emit('openRightSidebar', true);
    },
    enterRow(row) {
      const el = findAncestor(_getFrameElement(row.uid), 'hover-row');
      if (el) {
        el.classList.add('hover');
      }
    },
    selectRow(uid) {
      this.$bus.$emit('selectElement', uid);
    },
    leaveRow(row) {
      const el = findAncestor(_getFrameElement(row.uid), 'hover-row');
      if (el) {
        el.classList.remove('hover');
      }
    },
    getElementsOfColumn(column) {
      return this.elements.filter((e) => e.colId === column.uid);
    },
    enterElement(element) {
      if (element.type === 'OmPage') {
        this.$bus.$emit('enterPageElementOnLayers');
        return;
      }
      const uid = element.type === 'OmLuckyWheel' ? 'omLuckyWheel' : element.uid;
      const el = findAncestor(_getFrameElement(uid), 'om-element');
      if (el) {
        el.classList.add('hover');
      }
    },
    leaveElement(element) {
      if (element.type === 'OmPage') {
        this.$bus.$emit('leavePageElementOnLayers');
        return;
      }
      const uid = element.type === 'OmLuckyWheel' ? 'omLuckyWheel' : element.uid;
      const el = findAncestor(_getFrameElement(uid), 'om-element');
      if (el) {
        el.classList.remove('hover');
      }
    },
    type(element) {
      return element.type.toLowerCase().replace('om', '');
    },
    selectElement(element) {
      this.$bus.$emit('selectElement', element.uid);
    },
    fetchElements() {
      const _store = frameStore();
      this.elements = [];

      if (_store) {
        this.elements = frameStore().getters.elements || [];
        this.floatingImages =
          frameStore().getters.floatingImages.filter((f) => f.pageId === this.selectedPage.uid) ||
          [];
        this.hasRibbon = _store.getters.hasRibbon || false;
        this.isPopup = _store.getters.isPopup;
      }
    },
    removeHTMLTags(str) {
      return str.replace(/<[^>]*>?/gm, '');
    },
    editedDeviceType() {
      return this.mobilePreview ? 'mobile' : 'desktop';
    },
    isElementHidden(element) {
      const isHiddenDesktop = _get(element, 'desktop.hidden', false);
      const isHiddenMobile = _get(element, 'mobile.hidden', false);

      return isHiddenMobile && isHiddenDesktop;
    },
    getBackground(element) {
      const properties = {
        globalStyle: 'overlay.background',
        elements: `${this.editedDeviceType()}.background`,
      };

      const property = element === 'globalStyle' ? properties.globalStyle : properties.elements;
      element = element === 'globalStyle' ? this.globalStyle : element;
      const background = _get(element, `${property}.type`);
      if (background === 'image' || background === 'transparent') {
        const imageId = _get(element, `${property}.imageId`);
        const image = frameStore().getters.findImage(imageId);
        if (!imageId && !image) return { background, src: this.defaultImage };

        if (image?.url && isSvgUrl(image.url)) {
          return { svg: true, background, src: image?.url };
        }

        return { background, src: image?.url };
      }

      if (background === 'gradient') {
        const color1 = _get(element, `${property}.color`);
        const color2 = _get(element, `${property}.color2`);
        const gradientType = _get(element, `${property}.gradientType`);
        const gradientCenter = _get(element, `${property}.gradientCenter`);
        const linearDirection = _get(element, `${property}.linearDirection`);

        const getGradientType =
          gradientType === 'linear' ? `${linearDirection || 0}deg` : gradientCenter;
        return {
          background,
          'background-image': `linear-gradient(${getGradientType},${color1},${color2})`,
        };
      }

      if (background === 'solid-fill') {
        const color = _get(element, `${property}.color`);

        if (color > -1) {
          return {
            background,
            color: this.getColors[color],
          };
        }

        return { background, color };
      }

      return this.defaultImage;
    },
    remove(uid) {
      frameStore().dispatch('removeElement', { uid });
    },
    copy(uid) {
      frameStore().dispatch('copyElement', uid);
    },
  },
};
