<template lang="pug">
.expandable.brand-expandable
  .expandable-wrapper
    .expandable-toggle(@click="open = !open" :class="toggleClass")
      span {{ title }}
    .expandable-before-content(:style="contentStyle")
      slot(name="before-content")
    .expandable-content(:style="contentStyle")
      slot(name="content")
      infinite-loading(
        v-if="infiniteLoad"
        @infinite="infiniteHandler"
        force-use-infinite-wrapper=".brand-integrations-holder"
      )
        div(slot="no-results")
        div(slot="no-more")
        div(slot="spinner")
    .expandable-before-content(:style="contentStyle")
      slot(name="after-content")
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    components: { InfiniteLoading },
    props: ['title', 'showContent', 'infiniteLoad'],
    data: () => ({ open: false }),
    computed: {
      toggleClass() {
        return { 'expandable-opened': this.open, 'expandable-closed': !this.open };
      },
      contentStyle() {
        return { display: this.open ? 'flex' : 'none' };
      },
    },
    mounted() {
      this.open = this.showContent;
    },
    methods: {
      infiniteHandler($state) {
        this.$emit('loadMore', $state);
      },
    },
  };
</script>
