<template lang="pug">
.sidebar-input-wrapper
  label {{ $t(label) }}&nbsp;
    DeviceSelector(v-if="editMobile" :hasViewText="false")
  vue-range.slider(:min="min" :max="computedMax" :step="step" v-model="value")
  span.value-indicator(:class="{ focus: focus, invalid: invalid }")
    input.value-style(
      v-model="value"
      @focus="focus = true"
      @blur="isValidValue"
      @keyup="modifyValue"
    )
    span.unit(v-if="value !== null") {{ unit }}
</template>
<script>
  import VueRange from 'vue-range-slider';
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import { findAncestor } from '@/editor/util';
  import { debounce } from 'lodash-es';
  import itemMixin from '../../../mixins/item';
  import DeviceSelector from '../components/DeviceSelector.vue';

  export default {
    components: { VueRange, DeviceSelector },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      min: { type: Number, default: 0 },
      max: { type: Number, default: 200 },
      step: { type: Number, default: 5 },
      unit: { type: String, default: 'px' },
      typeOfComponent: { type: String, default: 'global' },
      useGivenMax: { type: Boolean, default: false },
    },
    data() {
      return {
        focus: false,
        invalid: false,
        maxOverride: null,
      };
    },
    computed: {
      ...mapGetters(['elementMaxWidth', 'imageMaxSize', 'nestedAccess', 'selectedImage']),
      ...mapState(['selectedElement', 'mobilePreview']),
      defaultValue() {
        return this.min < 0 ? 0 : this.min;
      },
      value: {
        get() {
          if (!this.getValueOf(this.property, this.defaultValue)) {
            const desktopProp = this.property
              .replace('$device', 'desktop')
              .replace('mobile', 'desktop');

            return this.getValueOf(desktopProp, this.defaultValue);
          }
          return this.getValueOf(this.property, this.defaultValue);
        },
        set(value) {
          this.setValueOf(this.property, value);
          window.om.bus.$emit('userInputChange', { property: this.property, value });
          this.$bus.$emit('updateValueInColorInstance', { property: this.property, value });
          this.$emit('change');
        },
      },
      localValue() {
        return this.value !== null ? this.value.toString().replace(/[^0-9-]/g, '') : null;
      },
      computedMax() {
        if (this.useGivenMax) return this.max;

        return this.maxOverride || this.max;
      },
      isImageSize() {
        return (
          this.selectedElement &&
          ['OmImage', 'OmFloatingImage'].includes(this.selectedElement.type) &&
          this.property.indexOf('width') > -1
        );
      },
      isVideoSize() {
        return (
          this.selectedElement &&
          this.selectedElement.type === 'OmVideo' &&
          this.property.indexOf('height') > -1
        );
      },
      isFloatingImage() {
        return this.selectedElement && this.selectedElement.type === 'OmFloatingImage';
      },
    },

    watch: {
      value() {
        const invalid =
          this.localValue !== null &&
          (this.localValue !== this.value.toString() ||
            parseInt(this.localValue, 10) < this.min ||
            parseInt(this.localValue, 10) > this.computedMax ||
            isNaN(parseInt(this.localValue, 10)) === true);

        if (invalid) {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      },
      selectedImage() {
        if (this.isImageSize) this.updateValueByImage();
      },
    },

    mounted() {
      // no resize for page property
      if (this.property.startsWith('page')) return;
      const isButton = this.selectedElement && this.selectedElement.type === 'OmButton';
      const isInput = this.selectedElement && this.selectedElement.type === 'OmInputs';
      const isWidth = this.property.indexOf('minwidth') > -1 || this.property.indexOf('Width') > -1;
      if (this.isImageSize || this.isVideoSize) this.updateValueByImage();

      if ((isInput || isButton) && isWidth && !this.isFloatingImage) {
        const maxSize = this.elementMaxWidth;
        this.maxOverride = maxSize;
        const currentSize = this.getValueOf(this.property, this.min);

        if (!currentSize || currentSize > maxSize) {
          this.value = maxSize;
        }
      }
    },
    methods: {
      ...mapMutations(['updateStyle', 'updateData']),
      modifyValue: debounce(function (event) {
        if (event.code === 'ArrowDown') {
          if (this.min < this.value) {
            this.value--;
          }
        } else if (event.code === 'ArrowUp') {
          if (this.value < this.max) {
            this.value++;
          }
        }
      }, 100),
      updateValueByImage() {
        if (!this.isFloatingImage) {
          const maxSize = this.imageMaxSize;
          this.maxOverride = maxSize;
        }
      },
      click(e) {
        const target = findAncestor(e.target, 'range-slider-inner');
        if (target) {
          const { left, width } = target.getBoundingClientRect();
          const clickX = parseInt(e.clientX - left, 10);
          let value = Math.ceil((this.computedMax - this.min) * (clickX / width)) + this.min;
          if (this.computedMax < value) {
            value = this.computedMax;
          } else if (this.min > value) {
            value = this.min;
          }
          this.value = value;
          this.$bus.$emit('historySave');
        }
      },
      isValidValue() {
        const noLocalValue = this.localValue === '' || this.localValue === null;
        if (noLocalValue && this.mobilePreview) {
          // null value only on mobile!
          this.value = null;
          return;
        }
        if (this.computedMax < parseInt(this.localValue, 10)) {
          this.value = this.computedMax;
        } else if (this.min > parseInt(this.localValue, 10)) {
          this.value = this.min;
        } else {
          this.value = this.localValue || this.min;
        }
        this.focus = false;
      },
    },
  };
</script>
