const moment = require('moment');

const calculatePaidUntil = (dateExpires) => {
  return moment.utc(dateExpires).startOf('day').subtract(14, 'days');
};

const calculatePaidFrom = (dateExpires, period, isShopify) => {
  const paidUntil = calculatePaidUntil(dateExpires);

  if (isShopify && period === 1) {
    return moment(paidUntil).subtract(30, 'days');
  }
  return moment(paidUntil).subtract(period, 'months');
};

module.exports = { calculatePaidUntil, calculatePaidFrom };
