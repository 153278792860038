<template lang="pug">
.pane
  sidebar-title
    span {{ $te(sidebarTitle) ? $t(sidebarTitle) : $t('editInput') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          input-element-chooser
        sidebar-tab-predefined-style(property="selectedElement" component="inputs")
          template(#before)
            custom-theme-style
            om-size(
              propsProperty="selectedElement"
              inputType="input"
              typeOfComponent="inputs"
              :defaultOption="false"
            )
            sidebar-accordion(:title="$t('fontEdit')")
              om-font(propsProperty="selectedElement" typeOfComponent="inputs")
                template(#fontSize)
                  om-range-input(
                    :property="getPath('fontSize')"
                    :editMobile="true"
                    label="fontSize"
                    typeOfComponent="inputs"
                    :min="8"
                    :max="90"
                    :step="1"
                  )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import inputsPaneMixin from '@/editor/mixins/inputsPane';
  import { inputTypes } from '@om/editor-ssr-shared/src/utils';
  import { capitalizeFirstLetter } from '@/util';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [inputsPaneMixin, itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      sidebarTitle() {
        if (inputTypes.includes(this.type)) return 'editInputField';
        return `edit${capitalizeFirstLetter(this.type)}`;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'input',
        };
      },
    },
  };
</script>
