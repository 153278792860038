var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-accordion',{attrs:{"title":_vm.$t('display'),"opened":_vm.show,"embedded":_vm.embedded,"addDeviceSelector":_vm.typeOfComponent === 'input-picker' ? false : true}},[_vm._t("before"),(_vm.typeOfComponent === 'input-picker')?[(_vm.displayType !== 'button')?_c('om-color-input',{attrs:{"label":"color","property":"desktop.checkedColor","typeOfComponent":_vm.typeOfComponent}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sidebar-input-wrapper sidebar-input-wrapper-label pb-0 pt-0 pr-0"},[_vm._v(_vm._s(_vm.$t('style')))])]),_c('radio-style-chooser',{attrs:{"type":_vm.inputType}})]:_vm._e(),(_vm.typeOfComponent !== 'text' && _vm.subPath !== 'text')?[_c('om-dropdown-input',{attrs:{"label":_vm.getFitLabel,"property":_vm.getPath('smartSize.type'),"items":_vm.size,"typeOfComponent":_vm.typeOfComponent,"defaultOption":_vm.defaultOption}}),(_vm.isPicker)?_c('om-switches',{attrs:{"label":"breakLines","property":_vm.getPath('orientation'),"typeOfComponent":_vm.selectedInput}}):_vm._e(),(_vm.sizeValue === 'manual')?_c('om-range-input',{attrs:{"label":_vm.getWidthLabel,"property":_vm.getPath('smartSize.width'),"min":0,"max":600,"step":1,"typeOfComponent":_vm.typeOfComponent}}):_vm._e(),(_vm.showHeight)?_c('om-range-input',{staticClass:"mt-3",attrs:{"label":_vm.getHeightLabel,"property":_vm.getPath('height'),"min":0,"max":_vm.maxHeight,"step":1,"typeOfComponent":_vm.typeOfComponent}}):_vm._e()]:_vm._e(),(_vm.typeOfComponent === 'input-picker' && _vm.displayType !== 'button')?[_c('align-select',{attrs:{"label":_vm.elementType === 'checkbox' ? 'checkboxAlign' : 'vertical',"options":_vm.alignRadio.map(function (ref) {
	var key = ref.key;

	return key;
}),"property":_vm.getPath('alignPicker'),"vertical":true}}),(_vm.sizeValue !== '100w')?_c('align-select',{attrs:{"label":"horizontal","options":_vm.justifyContentItems.map(function (ref) {
	var key = ref.key;

	return key;
}),"property":_vm.getPath('justifyContent'),"vertical":false}}):_vm._e()]:_vm._e(),(_vm.sizeValue !== '100w' && _vm.elementType !== 'radio' && _vm.elementType !== 'checkbox')?[_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('align'))),(_vm.typeOfComponent === 'coupon')?_c('align-select',{attrs:{"label":"vertical","property":_vm.getPath('alignItems'),"options":_vm.alignItems.map(function (ref) {
	var key = ref.key;

	return key;
}),"editMobile":true,"inline":true,"vertical":true}}):_vm._e(),_c('align-select',{attrs:{"label":_vm.alignLabel,"options":_vm.alignmentOptions,"property":_vm.propertyPath,"editMobile":true,"inline":true}})],1)]:_vm._e(),(_vm.subPath === 'input-picker')?[_c('om-switches',{attrs:{"label":"breakLines","property":"orientation"}})]:_vm._e(),_vm._t("after")],2)}
var staticRenderFns = []

export { render, staticRenderFns }