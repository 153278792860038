<template lang="pug">
.sidebar-outer-wrapper
  .sidebar.auto-overflow.left(
    :class="{ 'template-editor-mode': isTemplateEditorMode && databaseId === 44 }"
  )
    .full
      .bordered-container
        .transition-container.level-2(:class="position")
          .h-100(v-if="hasFirstSet" v-show="layersInSidebar")
            .right-pane-layout.sidebar-tab-content-scrollable-div.layers
              PageStructure-V2
          .h-100(v-show="!layersInSidebar")
            component(:is="paneL2" :key="paneL2Key")

        .transition-container.level-3(:class="position")
          component(:is="paneL3")
</template>

<script>
  import { createNamespacedHelpers, mapGetters, mapMutations, mapState } from 'vuex';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import SidebarColorPalette from '@/editor/components/sidebar/SidebarColorPalette';
  import * as Level2Panes from '@/editor/components/sidebar/panes/level2';
  import * as Level3Panes from '@/editor/components/sidebar/panes/level3';
  import TeaserV2Settings from '@/editor/components/TeaserV2Settings';
  import { dragAndDropTracker } from '@/services/userInteractionTracker/tracker';
  import PageStructureV2 from './PageStructureV2.vue';

  const { mapState: mapProductTourState, mapActions: mapProductTourActions } =
    createNamespacedHelpers('productTour');

  export default {
    components: {
      ...Level2Panes,
      ...Level3Panes,
      TeaserV2Settings,
      PageStructureV2,
      SidebarColorPalette,
    },
    props: ['saveType'],

    data() {
      return {
        stylesTabActive: false,
        layoutTypes: ['Single', 'Double', 'Triple'],
        level: 2,
        resetTimeout: null,
        TEMPLATE_FEATURES,
        popoverVisible: false,
        popoverComponentName: null,
        top: 0,
        showLayers: true,
        hasFirstSet: false,
      };
    },

    computed: {
      ...mapGetters([
        'isNano',
        'isTemplateEditorMode',
        'isEmbedded',
        'databaseId',
        'hasTemplateFeature',
        'teaserPage',
        'hasAccountFeature',
      ]),
      ...mapState([
        'selectedElement',
        'globalStyle',
        'paneLevel',
        'pages',
        'paneL2',
        'paneL3',
        'userInfoMessage',
        'hasFeedbackOnPage',
        'selectedPage',
      ]),
      ...mapProductTourState(['driver']),
      isOldEditor() {
        return this.$route.name === 'variant_edit';
      },
      layersInSidebar() {
        if (!this.hasSelectedPage()) return false;

        // Teaser (old version)
        if (!this.selectedPage.isTeaser && this.paneL2) {
          const type = this.paneL2.replace('Pane', '');
          if (type === 'Teaser') return false;
        }

        return this.showLayers;
      },
      isV2Teaser() {
        return (
          this.hasTemplateFeature(this.TEMPLATE_FEATURES.NEW_TEASER) &&
          ((this.selectedPage && this.selectedPage.isTeaser) || this.selectedElement.isTeaser)
        );
      },
      paneL2Key() {
        if (this.paneL2 === 'RowPane' && this.selectedRow) {
          return this.selectedRow.uid;
        }

        if (this.selectedElement) {
          return this.selectedElement.uid;
        }

        return this.paneL2;
      },
      position() {
        return `position-${this.paneLevel}`;
      },
      elementTypes() {
        const result = {
          basic: ['Text', 'Image', 'FloatingImage', 'Button'],
          form: ['Input', 'Radio', 'Checkbox', 'Dropdown', 'Feedback', 'Textarea', 'Survey'],
          structure: ['Row', 'Spacer', 'Divider'],
          product: ['Countdown', 'Coupon', 'Product'],
          other: ['Social', 'Video', 'CustomHTML'],
        };

        if (this.isV2Teaser) {
          return { basic: ['Text', 'Image', 'FloatingImage'] };
        }

        if (this.saveType === 'base' && this.databaseId === 44) {
          result.other.push('LuckyWheel');
          result.other.push('ScratchCard');
          result.other.push('PickAPresent');
        }

        if (this.isNano) {
          result.other.splice(result.other.indexOf('Video'), 1);
        }

        return result;
      },
      options() {
        const result = [];

        if (!this.isEmbedded) {
          result.push(
            { label: 'generalSettings', pane: 'OverlayPane' },
            { label: 'tab', pane: 'TeaserPane' },
          );
        }

        result.push({ label: 'fontManager.title', pane: 'FontManagerPane' });

        if (this.isTemplateEditorMode && this.databaseId === 44) {
          result.push({ label: 'ribbon', pane: 'RibbonPane' });
        }
        return result;
      },
      elementKinds() {
        if (this.isV2Teaser) {
          return ['basic'];
        }
        const kindOf = ['basic', 'form', 'product', 'other'];

        if (!this.isNano) {
          kindOf.splice(2, 0, 'structure');
        }

        return kindOf;
      },
    },

    watch: {
      paneLevel(newLevel, oldLevel) {
        if (newLevel < oldLevel) {
          clearTimeout(this.resetTimeout);

          this.resetTimeout = setTimeout(
            function () {
              // make sure closed panes are not kept alive in the background
              if (newLevel < 3) {
                this.$store.state.paneL3 = null;
              }
              if (newLevel < 2) {
                this.$store.state.paneL2 = null;
              }
            }.bind(this),
            400,
          ); // transition duration
        }
      },
      // Init sidebar first state - step 1
      selectedPage(page) {
        if (this.hasFirstSet) return;
        if (!page?.uid) return;
        this.$bus.$emit('selectElement', page.uid);
      },
      // Init sidebar first state - step 2
      selectedElement() {
        if (this.hasFirstSet) return;
        this.$nextTick(() => {
          this.$bus.$emit('deselectAll');
          this.setShowLayers();
          this.hasFirstSet = true;
        }, 100);
      },
    },

    async created() {
      window.om.bus.$on('show-sidebar-content', (id) => {
        const sidebarWrapper = this.$refs.sidebarWrapper;
        if (sidebarWrapper) sidebarWrapper.$emit('show-content', id);
      });

      this.initRightSidebarLayersEventListeners();
    },

    methods: {
      ...mapMutations(['activatePaneL2', 'updateDataProperty']),
      ...mapProductTourActions(['next']),
      hasSelectedPage() {
        return !!this.selectedPage?.uid;
      },
      setShowLayers() {
        this.showLayers = true;
      },
      setShowNormalPane() {
        this.showLayers = false;
      },
      initRightSidebarLayersEventListeners() {
        this.$bus.$on('activatePane', ({ pane }) => {
          if (['CloseButtonPane', 'TeaserPane'].includes(pane)) {
            this.setShowNormalPane();
          }
        });
        this.$bus.$on('outerCanvasClick', this.setShowNormalPane);
        this.$bus.$on('setSelectedElement', this.setShowNormalPane);
        this.$bus.$on('pageNavigationClick', this.setShowLayers);
        this.$bus.$on('overlayClick', this.setShowLayers);
        this.$bus.$on('showLayers', this.setShowLayers);
      },
      getElementDataTrackProperty(type) {
        return `component:Om${type}|change:{group}.elements.Om${type}.{event}`;
      },
      dragstart(e, data) {
        const type = data === 'Row' ? 'row' : 'element';

        e.dataTransfer.setData('Text', data);

        const tracker = dragAndDropTracker.create();
        tracker.start(`Om${data}`, e.target);
        tracker.setDrag();
        this.dragEndDropTracker = tracker;
        window.om.bus.$emit('setDragInfo', {
          type,
          data: data === 'Row' ? 2 : data,
          tracker,
          drag: true,
        });
      },
      dragend() {
        this.dragEndDropTracker.end();
      },
      getTypeImage(type) {
        return type === 'Row' ? 'Double' : type;
      },
      getTypeName(type) {
        return type === 'Row' ? 'block' : type.toLowerCase();
      },
      elementMouseDown() {
        if (
          this.driver &&
          this.driver.hasHighlightedElement() &&
          this.driver.getHighlightedElement().node === document.querySelector('.fake-sidebar')
        ) {
          this.next();
          window.om.bus.$emit('showProductTourSuccess');
        }
      },
      showPopover({ selector, name }) {
        this.popoverTop =
          document.querySelector(selector).getBoundingClientRect().top + window.scrollY;
        this.popoverComponentName = name;
        this.popoverVisible = true;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.toggleOutsideClickHandler(true);
        }, 50);
      },
      closePopover() {
        this.popoverVisible = false;
        this.$bus.$emit('sidebarPopoverClosed');
        this.toggleOutsideClickHandler(false);
      },
      toggleOutsideClickHandler(value) {
        const elements = [
          document.body,
          document.querySelector('#workspaceFrame').contentWindow.document.body,
        ];
        elements.forEach((element) => {
          if (value) {
            element.addEventListener('click', this.outsideClickHandler);
          } else {
            element.removeEventListener('click', this.outsideClickHandler);
          }
        });
      },
      outsideClickHandler(e) {
        if (!this.hasSomeParentTheClass(e.target, 'popover-content')) {
          this.closePopover();
        }
      },
      hasSomeParentTheClass(element, className) {
        if (element.classList.contains(className)) return true;
        return (
          element.parentElement && this.hasSomeParentTheClass(element.parentElement, className)
        );
      },
    },
  };
</script>

<style lang="sass" scoped>
  .sidebar-outer-wrapper
    height: 100%

  .full
    height: calc(100% - 52px - 68px)
  .popper ::v-deep .popper__arrow
    top: 6px !important
  .editor-right-column
    overflow: visible !important
</style>
