<template lang="pug">
.h-100
  .pane
    sidebar-title
    .sidebar-inner-box.pt-0
      sidebar-tab-wrapper(defaultTab="general")
        template(#content)
          sidebar-tab-content#general
            om-dropdown-input(
              label="countdownType"
              property="data.countdown.type"
              :items="countdownType"
            )
            om-switches(label="timeUnitLabels" property="data.countdown.labelIsActive")
            transition(name="fade")
              .mb-3.countdown-labels(v-if="selectedElement.data.countdown.labelIsActive")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('countdownLabels.days') }}
                om-simple-input(label="" property="data.countdown.labels.days")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('countdownLabels.hours') }}
                om-simple-input(label="" property="data.countdown.labels.hours")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('countdownLabels.minutes') }}
                om-simple-input(label="" property="data.countdown.labels.minutes")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('countdownLabels.seconds') }}
                om-simple-input(label="" property="data.countdown.labels.seconds")
            transition(name="fade")
              .countdown-time-wrapper(v-if="selectedElement.data.countdown.type === 'time'")
                .d-flex.flex-wrap
                  .col-6.pl-0(v-for="(dd, index) in timeDropdowns")
                    dropdown(
                      :label="dd.label"
                      :class="{ 'mt-3': index > 1 }"
                      :property="dd.prop"
                      :options="dd.options"
                    )
              .countdown-date-wrapper(v-else)
                date-time(property="data.countdown.endDate.date")
          sidebar-tab-content#style
            custom-theme-style
            sidebar-accordion(:title="$t('digitStyle')")
              om-range-input(
                editMobile
                label="width"
                property="selectedElement.subElements.number.$device.width"
                :min="0"
                :max="120"
                :step="1"
              )
              om-range-input(
                editMobile
                label="height"
                property="selectedElement.subElements.number.$device.height"
                :min="0"
                :max="120"
                :step="1"
              )
              font-dropdown(
                editMobile
                label="fontFamily"
                property="selectedElement.subElements.shared.$device.fontFamily"
                :i18n="false"
                typeOfComponent="countdown"
              )
              om-range-input(
                editMobile
                label="fontSize"
                property="selectedElement.subElements.shared.$device.fontSize"
                :min="0"
                :max="90"
                :step="1"
              )
              om-color-input(
                label="backgroundColor"
                property="selectedElement.subElements.number.desktop.background.color"
                typeOfComponent="countdown"
              )
              om-color-input(
                label="unitColor"
                property="selectedElement.subElements.label.desktop.color"
                typeOfComponent="countdown"
              )
              om-color-input(
                label="numberColor"
                property="selectedElement.subElements.number.desktop.color"
                typeOfComponent="countdown"
              )
              om-color-input(
                label="separatorColor"
                property="selectedElement.subElements.separator.desktop.color"
                typeOfComponent="countdown"
              )
            om-border-box(
              propsProperty="selectedElement"
              subPath="subElements.number.desktop"
              typeOfComponent="countdown"
            )
          advanced-tab(:spacingProps="spacingProps")
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import flatpickr from 'flatpickr';
  import moment from 'moment';
  import { mapGetters, mapMutations, mapState } from 'vuex';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      FontDropdown: () => import('@/editor/components/sidebar/inputs/FontDropdown.vue'),
      Dropdown: () => import('@/editor/components/sidebar/components/Dropdown.vue'),
      DateTime: () => import('@/editor/components/sidebar/components/DateTime/DateTime.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        show: false,
        initialState: {
          width: 10,
          height: 10,
          background: '#000',
          labelColor: '#000',
          color: '#fff',
          fontSize: '32',
          fontFamily: 'Open Sans',
          separatorColor: '#000',
        },
        countdownType: [
          { key: 'fixedDate', value: 'date' },
          { key: 'fixedTime', value: 'time' },
        ],
        flatpickrInstance: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'calendar', 'mobilePreview']),
      ...mapGetters(['nestedAccess']),
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'countdown',
        };
      },
      savedDate() {
        const format = 'Y-MM-DD HH:mm';
        const dateValue = this.selectedElement.data.countdown.endDate.date;
        return dateValue && dateValue !== ''
          ? moment(dateValue).format(format)
          : moment().format(format);
      },
      fontSize() {
        const fontSize = parseInt(`${this.selectedElement.desktop.countdown.fontSize}`, 10);
        return fontSize;
      },
      width() {
        const width = this.selectedElement.desktop.countdown.width;
        return parseInt(`${width}`, 10);
      },
      height() {
        const height = this.selectedElement.desktop.countdown.height;
        return parseInt(`${height}`, 10);
      },
      isHu() {
        return this.$i18n.locale === 'hu';
      },
      daysOptions() {
        return this.generateOptions('', 100);
      },
      hoursOptions() {
        return this.generateOptions(this.isHu ? 'ó' : 'h', 24);
      },
      minutesOptions() {
        return this.generateOptions(this.isHu ? 'p' : 'min', 60);
      },
      secondsOptions() {
        return this.generateOptions(this.isHu ? 'mp' : 'sec', 60);
      },
      timeDropdowns() {
        const { daysOptions, hoursOptions, minutesOptions, secondsOptions } = this;
        return [
          { label: 'days', prop: 'data.countdown.day', options: daysOptions },
          { label: 'hours', prop: 'data.countdown.hour', options: hoursOptions },
          { label: 'minutes', prop: 'data.countdown.min', options: minutesOptions },
          { label: 'seconds', prop: 'data.countdown.sec', options: secondsOptions },
        ];
      },
    },
    watch: {
      'calendar.visible': function (v) {
        if (!v && this.flatpickrInstance) {
          this.flatpickrInstance.close();
        }
      },
      'selectedElement.data.countdown.type': function (v) {
        if (v === 'date') {
          this.calculateDate();
        }
      },
      'selectedElement.data.countdown.endDate.date': function () {
        this.calculateDate();
      },
    },

    beforeDestroy() {
      this.$store.commit('changeCalendarVisibility', 'hidden');
    },
    methods: {
      ...mapMutations(['updateData', 'updateStyle']),
      setShowInitValue() {
        const options = ['type', 'labelIsActive', 'endDate.date', 'day', 'hour', 'min', 'sec'];
        let counter = 0;
        for (const item in options) {
          if (this.nestedAccess(`selectedElement.data.countdown.${options[item]}`) !== null)
            counter++;
        }
        if (counter > 1) {
          this.show = true;
        } else {
          this.show = false;
        }
      },
      initFlatpickr() {
        const updateFn = function (dates) {
          const currentDate = new Date();
          const date = dates[0].getTime() < currentDate.getTime() ? currentDate : dates[0];
          this.updateData({
            property: 'selectedElement.data.countdown.endDate.date',
            value: date.toUTCString(),
          });
        }.bind(this);
        this.flatpickrInstance = flatpickr('.countdownFlatpickr-endDate', {
          noCalendar: false,
          enableTime: true,
          time_24hr: true,
          // minDate: 'today',
          maxDate: new Date().fp_incr(99),
          dateFormat: 'Y-m-d H:i',
          onValueUpdate: updateFn,
          onChange: updateFn,
          onOpen: this.$store.commit('changeCalendarVisibility', 'visible'),
        });
      },
      calculateDate() {
        const endDate = this.selectedElement.data.countdown.endDate.date;
        const calculatedDate = moment.duration(moment(endDate) - moment(new Date()))._data;
        const calculatedDays = moment(endDate).diff(moment(new Date()), 'days');

        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.day',
          value: calculatedDays,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.hour',
          value: calculatedDate.hours,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.min',
          value: calculatedDate.minutes,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.sec',
          value: calculatedDate.seconds,
        });
      },
      generateOptions(postfixKey, count) {
        const hours = this.$te(postfixKey) ? this.$t(postfixKey).toLowerCase() : postfixKey;
        const options = new Array(count)
          .fill('')
          .map((_, index) => ({ key: `${index} ${hours}`, value: index }));
        return options;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .countdown-time-wrapper
    ::v-deep .epic-simple-input
      .select-arrow
        top: 50% !important
      select
        width: 124.5px !important
</style>
