<template lang="pug">
svg.brand-mobile-position(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.45 367.2")
  g#mobile-position
    rect.brand-mobile-position-row(
      @click="select('bottom')"
      :class="selectedClass('bottom')"
      x="11.76"
      y="239.71"
      width="162.2"
      height="116.24"
    )
    rect.brand-mobile-position-row(
      :style="{ fill: isNano ? '#e5e5e5' : '' }"
      @click="select('center')"
      :class="selectedClass('center')"
      x="11.99"
      y="124.46"
      width="162.2"
      height="116.24"
    )
    rect.brand-mobile-position-row(
      @click="select('top')"
      :class="selectedClass('top')"
      x="11.61"
      y="9.2"
      width="162.2"
      height="116.24"
    )
  path(
    fill="#58595b"
    d="M182.49,26.65A26.65,26.65,0,0,0,155.84,0H28.61A26.65,26.65,0,0,0,2,26.65v313.9A26.65,26.65,0,0,0,28.61,367.2H155.84a26.65,26.65,0,0,0,26.65-26.65ZM178.4,340.29a22.82,22.82,0,0,1-22.82,22.82H28.36A22.82,22.82,0,0,1,5.54,340.29V26.4A22.82,22.82,0,0,1,28.36,3.58H155.58A22.82,22.82,0,0,1,178.4,26.4Z"
  )
  path(d="M2,48.47H1.72A1.72,1.72,0,0,0,0,50.19V60.65a1.71,1.71,0,0,0,1.72,1.71H2")
  path(d="M182.49,126.27h0a2,2,0,0,0,2-2V85.48a2,2,0,0,0-2-2h0")
  path(d="M2,75.21H2a2,2,0,0,0-2,2V99.25a2,2,0,0,0,2,2H2")
  path(d="M2,108.58H2a2,2,0,0,0-2,2v22.08a2,2,0,0,0,2,2H2")
  path(
    fill="#231f20"
    d="M178.4,26.4A22.82,22.82,0,0,0,155.58,3.58H28.36A22.82,22.82,0,0,0,5.54,26.4V340.29a22.82,22.82,0,0,0,22.82,22.82H155.58a22.82,22.82,0,0,0,22.82-22.82ZM113.31,12.54a2.24,2.24,0,1,1-2.24-2.23A2.24,2.24,0,0,1,113.31,12.54ZM82.88,11.25h19.94a1.4,1.4,0,0,1,1.54,1.28,1.4,1.4,0,0,1-1.54,1.28H82.88a1.4,1.4,0,0,1-1.54-1.28A1.4,1.4,0,0,1,82.88,11.25Zm89.89,328.42c0,8.93-7.48,15.77-16.41,15.77H29a15.53,15.53,0,0,1-15.81-15.77V26A16,16,0,0,1,29,9.72H43.74c3.11,0,4.26,0,4.45,4,.2,4.1,3,6.33,6.82,7.53a14,14,0,0,0,4.1.27H126.4a14.07,14.07,0,0,0,4.11-.17c3.81-1.2,6.62-3.63,6.82-7.72s1.33-3.87,4.45-3.87h14.58A16.5,16.5,0,0,1,172.77,26Z"
  )
  circle(cx="111.07" cy="12.54" r="2.24")
  path(
    d="M82.88,13.81h19.94a1.4,1.4,0,0,0,1.54-1.28,1.4,1.4,0,0,0-1.54-1.28H82.88a1.4,1.4,0,0,0-1.54,1.28A1.4,1.4,0,0,0,82.88,13.81Z"
  )
</template>
<script>
  import { mapMutations, mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['nestedAccess', 'isNano']),
      position: {
        get() {
          return this.nestedAccess('globalStyle.overlay.mobilePosition');
        },
        set(v) {
          this.updateStyle({ property: 'globalStyle.overlay.mobilePosition', value: v });
        },
      },
    },

    methods: {
      ...mapMutations(['updateStyle']),
      select(position) {
        if (this.isNano && position === 'center') return;

        this.position = position;
      },
      selectedClass(position) {
        return this.position === position ? 'brand-mobile-position-row-selected' : '';
      },
    },
  };
</script>

<style lang="sass">
  .brand-mobile-position
    height: 10rem
    &-row
      fill: #fff
      stroke: #231f20
      stroke-miterlimit: 10
      cursor: pointer
      &-selected
        fill: var(--brand-primary-color)
</style>
