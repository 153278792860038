<template lang="pug">
.sidebar-input-wrapper
  .d-flex.flex-wrap
    .radio-style-icon(v-for="t in types" :class="{'radio-style-icon-selected': selected === t, 'radio-style-icon-disabled': radioButtonTypeDisabled(t)}")
      img(:src="require(`@/assets/editor/svg/${type}-styles/${t}.svg`)"
          @click="update(t)"
          v-tooltip="{content: radioButtonTypeDisabled(t) ? $t('oneFeedbackPerPage') : false}")
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import itemMixin from '../mixins/item';

  export default {
    mixins: [itemMixin],

    props: {
      type: { type: String },
    },

    computed: {
      ...mapState(['selectedElement', 'hasFeedbackOnPage']),
      selected() {
        return this.selectedElement.data.displayType || 'normal';
      },
      types() {
        let types = [];
        if (this.type === 'radio') {
          types = ['normal', 'full', 'colorBorder', 'thickBorder', 'check'];
          if (
            this.selectedElement.data.feedback &&
            this.selectedElement.data.feedback.question.length
          ) {
            types.push('button');
          }
        } else {
          types = ['normal', 'colorBorder', 'round', 'background', 'full'];
        }
        return types;
      },
    },

    methods: {
      ...mapMutations(['updateData', 'setStateAttr']),

      update(t) {
        if (t === 'button' && this.hasFeedbackOnPage) {
          return;
        }

        if (this.selected === 'button' && t !== 'button') {
          this.setStateAttr({ attr: 'hasFeedbackOnPage', value: false });
          this.$bus.$emit('setWorkspaceStateAttr', { attr: 'hasFeedbackOnPage', value: false });
        }

        if (this.selected !== 'button' && t === 'button') {
          this.setStateAttr({ attr: 'hasFeedbackOnPage', value: true });
          this.$bus.$emit('setWorkspaceStateAttr', { attr: 'hasFeedbackOnPage', value: true });
        }

        let checkedRadioElement;
        if (this.type === 'radio') {
          const workspaceFrame = document.getElementById('workspaceFrame').contentWindow.document;
          checkedRadioElement = workspaceFrame.querySelector(
            `#${this.selectedElement.uid} input:checked`,
          );
        }

        this.setValueOf('data.displayType', t, true);

        if (checkedRadioElement) {
          this.$nextTick(() => {
            checkedRadioElement.checked = true;
          });
        }
      },

      radioButtonTypeDisabled(t) {
        return this.selected !== t && t === 'button' && this.hasFeedbackOnPage;
      },
    },
  };
</script>

<style lang="sass">
  .radio-style-icon
    cursor: pointer
    width: 33%
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    img
      border: 1px solid #C8CDD7
      border-radius: 0.1875rem
      padding: 0.375rem

    &-selected
      img
        background: #F4F8FB
        border-color: var(--brand-primary-color)
    &-disabled
      cursor: initial
      opacity: 0.5
</style>
