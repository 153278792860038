<template lang="pug">
div(:class="['vc-sketch', disableAlpha ? 'vc-sketch__disable-alpha' : '']")
  tab-wrapper.vc-tab-wrapper(
    ref="tabWrapper"
    :class="{ 'vc-tab-wrapper-empty': !showTabs }"
    customClass="vc-tab-tabs"
    v-if="!isPalette"
    @show-content="setBackgroundType"
  )
    template(v-if="showTabs === true" #tabs)
      tab-item#transparent(
        v-if="shouldDisplayNone"
        :name="$t('colorPickerSettings.tabs.name.none')"
      )
      tab-item#solid-fill(:name="$t('colorPickerSettings.tabs.name.solid')")
      tab-item#gradient(:name="$t('colorPickerSettings.tabs.name.gradient')")
      tab-item#image(
        :name="$t('colorPickerSettings.tabs.name.image')"
        v-if="(!selectedElement || selectedElement.type !== 'OmDropdown') && !activeProperty.includes('hover')"
      )
    template(v-else-if="showTabs === false" #tabs)
      span
    template(#content)
      tab-content#transparent
        .vc-tab-content
          .vc-sketch-saturation-wrap.transparent-bg
      tab-content#solid-fill
        theme-colors(
          :disableAlpha="disableAlpha"
          :activeMainColorIndex="getSelectedMainColor"
          @handlePreset="handlePreset"
          @handleRecent="handleRecent"
        )
        .vc-tab-content
          .vc-sketch-saturation-wrap
            saturation(v-model="colors" @change="childChange" :hue="currentHue")

          template(v-if="!isPalette")
            .vc-tab-content
              solid(
                :colors="colors"
                :disableAlpha="disableAlpha"
                @inputChange="inputChange"
                @childChange="childChange"
              )

      tab-content#gradient(v-if="showTabs === true")
        .vc-sketch-top
          gradient(
            @linearDirectionChange="linearDirectionChange"
            @inputChange="inputChange"
            :directionProperty="getLinearDirectionPath"
            @setActiveProp="setGradientProperty"
            :colorInstance="colorInstance"
            :defaultValue="1"
            :property="activeProperty"
            :min="0"
            :max="360"
            :step="1"
          )
        theme-colors(
          :disableAlpha="disableAlpha"
          @handlePreset="handlePreset"
          @handleRecent="handleRecent"
        )
        .vc-tab-content
          .vc-sketch-saturation-wrap
            saturation(v-model="colors" @change="childChange" :hue="currentHue")

          solid(
            :colors="colors"
            :disableAlpha="disableAlpha"
            @inputChange="inputChange"
            @childChange="childChange"
          )

      tab-content#image(v-if="showTabs === true")
        .vc-tab-content(v-if="activeProperty.startsWith('globalStyle')")
          image-picker(
            :propsProperty="activeProperty"
            :property="activeProperty"
            :typeOfComponent="typeOfComponent"
            :colorInstance="colorInstance"
          )
        .vc-tab-content(v-else)
          image-picker(
            propsProperty="selectedElement"
            :property="activeProperty"
            :typeOfComponent="typeOfComponent"
            :colorInstance="colorInstance"
          )

  .vc-sketch-saturation-wrap(v-if="isPalette")
    saturation(v-model="colors" @change="childChange" :hue="currentHue")

  template(v-if="isPalette")
    .vc-sketch-bottom
      .vc-sketch-sliders
        .vc-sketch-hue-wrap
          hue(v-model="colors" @change="childChange")
      .vc-sketch-field
        .vc-sketch-field--double.mt-2
          ed-in(label="hex" :value="hex" @change="inputChange")

  .color-reset-box(v-if="isQuillPicker")
    a.color-reset(href="#" @click.prevent="resetColor") {{ $t('reset') }}
</template>

<script>
  import { mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import tinycolor from 'tinycolor2';
  import TabWrapper from '@/editor/components/sidebar/tab/Wrapper.vue';
  import TabItem from '@/editor/components/sidebar/tab/Item.vue';
  import TabContent from '@/editor/components/sidebar/tab/Content.vue';
  import { lsRetrieve, lsStore } from '@/helpers/localStorage';
  import { colorChangeTracker } from '@/services/userInteractionTracker/tracker';
  import { MAX_ACCEPTED_RECENTLY_COLORS } from '@/config/constants';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
  import { COLORING_TYPES } from '@/utils/color-components/helper';
  import colorMixin from '../mixin/color';

  export default {
    name: 'Sketch',
    components: {
      // Because of the dynamic import this.$refs.tabWrapper and children are undefined!
      TabWrapper,
      TabItem,
      TabContent,
      saturation: () => import('./common/Saturation.vue'),
      hue: () => import('./common/Hue.vue'),
      alpha: () => import('./common/Alpha.vue'),
      ThemeColors: () => import('./common/ThemeColors.vue'),
      'ed-in': () => import('./common/EditableInputOm.vue'),
      CheckBoard: () => import('./common/Checkboard.vue'),
      Solid: () => import('./tabs/Solid.vue'),
      Gradient: () => import('./tabs/Gradient.vue'),
      ImagePicker: () => import('./tabs/ImageV2.vue'),
    },
    mixins: [colorMixin, themeColorsMixin],
    props: {
      disableAlpha: {
        type: Boolean,
        default: false,
      },
      typeOfComponent: {
        type: String,
        default: 'global',
      },
      inEditor: {
        type: Boolean,
        default: true,
      },
      colorInstance: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    data: () => ({
      currentHue: 0,
      activeProperty: '',
      sketchTabs: [],
    }),
    computed: {
      ...mapState([
        'isEditor',
        'mobilePreview',
        'globalStyle',
        'images',
        'colorPicker',
        'selectedElement',
      ]),
      shouldDisplayNone() {
        // TODO: create a getter into the colorInstance
        const blockedTypes = [ELEMENTS.OmScratchCard];
        return !blockedTypes.includes(this.selectedElement.type);
      },
      getLinearDirectionPath() {
        return this.colorInstance.getLinearDirectionProperty();
      },
      getRecentlyUsedColors() {
        return _get(this.colorPicker, 'recentlyUsedColor');
      },
      hex() {
        return this.colors.hex;
      },
      getActiveColor() {
        return tinycolor(this.colors.rgba).toRgbString();
      },
      getSelectedMainColor() {
        return Number(this.colorInstance.getColor()) ?? null;
      },
      isQuillPicker() {
        const property = _get(this.colorPicker, 'property');
        return property && property.startsWith('omcolor');
      },
      isPalette() {
        return _get(this.colorPicker, 'palette');
      },
      getTemplatePalette() {
        return _get(window, 'om.template.palette');
      },
      showTabs() {
        return this.colorInstance.showTabs;
      },
      isBrandColorPicked() {
        return this.colorPicker.index !== null && typeof this.colorPicker.index !== 'undefined';
      },
    },
    watch: {
      hex(newValue) {
        this.$emit('change', { hex: newValue });
      },
    },
    mounted() {
      this.currentHue = this.colors.hsl.h;
      if (this.inEditor) {
        this.$nextTick(() => {
          this.setActiveTabItem();
        });
      }
    },
    beforeDestroy() {
      this.addRecentlyUsedColor(this.getActiveColor);
    },
    methods: {
      setGradientProperty(value) {
        this.colorInstance.setActiveGradientProperty(value);
        const colorHex =
          value === 'color2' ? this.colorInstance.getColor2() : this.colorInstance.getColor();
        this.changeColor(colorHex);
      },
      setActiveTabItem(value) {
        const wrapper = this.$refs.tabWrapper;
        if (!wrapper) return;

        if (!value) value = this.colorInstance.getColoringType();

        wrapper.$children.forEach((child) => {
          if (child.deactivate) child.deactivate();
          if (child.hide) child.hide();
          if (child && child.id && child.id === value) {
            this.$nextTick(() => {
              if (child.activate) child.activate();
              if (child.show) child.show();
            });
          }
        });
        this.$bus.$emit('rebuild-color-instance');
      },
      trackColorChange(type, value = null) {
        colorChangeTracker.track(this.colorPicker.property, {
          type,
          value,
        });
      },
      resetColor() {
        const firstTemplateColor = this.getTemplatePalette[0] ?? '#FFFFFF';
        const colorHex = firstTemplateColor.match(/^rgb\(.*\)$/)
          ? tinycolor(firstTemplateColor).toHexString()
          : firstTemplateColor;
        this.handlePreset({ colorHex, index: 0 });
      },
      addRecentlyUsedColor(newColor) {
        const recentlyUsedColors = this.getRecentlyUsedColors;

        if (!recentlyUsedColors) {
          this.$store.commit('setRecentlyUsedColor', [newColor]);
          return;
        }
        const recentlyUsedColorsWithoutNewColor = recentlyUsedColors.filter(
          (color) => color !== newColor,
        );
        recentlyUsedColorsWithoutNewColor.unshift(newColor);
        this.$store.commit(
          'setRecentlyUsedColor',
          recentlyUsedColorsWithoutNewColor.splice(0, MAX_ACCEPTED_RECENTLY_COLORS),
        );
      },
      generateHexOrRGBA(color, source = 'hex') {
        let data = { [source]: color };
        if (/^rgb/.test(color)) {
          const rgba = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.exec(color);
          const keys = ['r', 'g', 'b', 'a'];
          data = {};
          rgba.forEach((found, i) => {
            if (!i) return;
            data[keys[i - 1]] = i < 4 ? parseInt(found, 10) : parseFloat(found);
          });
        }
        return data;
      },
      handleRecent({ colorHex }) {
        this.trackColorChange('recent');
        let data = this.generateHexOrRGBA(colorHex);
        data = tinycolor(data).toRgbString();
        this.temporarySaveRecent(data);
        this.colorInstance.handleColorChange(colorHex);
        this.handleColorInstanceToStore();
        this.changeColor(data);
        this.$bus.$emit('rebuild-color-instance');
      },
      handlePreset({ colorHex, index }) {
        this.trackColorChange('template', index);
        this.colorInstance.handleColorChange(index);
        this.handleColorInstanceToStore();
        this.changeColor(colorHex);
        this.$bus.$emit('rebuild-color-instance');
      },
      changeColor(colorHex, source = 'hepax') {
        const colorChangeData = this.generateHexOrRGBA(colorHex);
        this.colorChange({ ...colorChangeData, source });
      },
      isWhite(data) {
        return data.source === 'hsl' && data.s === 0 && data.l > 0.5;
      },

      isBlack(data) {
        return data.source === 'hsl' && data.s === 0 && data.l < 0.5;
      },

      childChange(data) {
        this.trackColorChange('custom');

        if (data.source && ['hsl', 'hsv', 'hsla', 'hsva'].includes(data.source))
          this.currentHue = data.h || this.colors.hsl.h || 0;

        if (this.isWhite(data)) data = { h: data.h, s: 0.1, l: 0.9, source: 'hsl', a: 1 };

        if (this.isBlack(data)) data = { h: data.h, s: 0.1, l: 0.1, source: 'hsl', a: 1 };

        const hex =
          data.length === 3
            ? tinycolor(data).toString('hex3').replace('#', '').toUpperCase()
            : tinycolor(data).toHexString().toUpperCase();
        const rgba = tinycolor(data).toRgbString();

        // IMPROVEMENT: A main color állítás még régi sketch-el megy
        if (this.isEditor) {
          if (this.isPalette && !this.isBrandColorPicked) {
            this.$store.commit('updateMainColor', rgba);
            this.$bus.$emit('historySave');
          } else if (this.isPalette && this.isBrandColorPicked) {
            this.handleThemeColorClick(rgba);
            return;
          } else {
            this.colorInstance.handleColorChange(rgba);
            this.handleColorInstanceToStore();
          }
        }

        this.temporarySaveRecent(data.source ? rgba : hex);
        this.colorChange(data.source ? rgba : hex);
        this.$bus.$emit('rebuild-color-instance');
      },

      handleThemeColorClick(rgba) {
        const fallbackColor =
          this.globalStyle.palette.themeColors[this.colorPicker.index]?.themeColor;

        let color = tinycolor(rgba).toHexString();
        const name = this.globalStyle.palette.themeColors[this.colorPicker.index]?.name;

        if (color === '#000000') color = fallbackColor;

        this.$store.commit('updateThemeColor', {
          themeColor: color,
          name,
          index: this.colorPicker.index,
        });

        this.colorChange(color);
        this.$bus.$emit('historySave');
        this.$bus.$emit('rebuild-color-instance');
      },
      temporarySaveRecent(data) {
        if (data.hex) {
          data = tinycolor(data).toRgbString();
        }
        const stored = lsRetrieve('tempRecentColors') || [];
        stored.push(data);
        lsStore('tempRecentColors', stored);
      },
      setBackgroundType(value) {
        this.colorInstance.setColoringType(value);
        this.setActiveTabItem(value);
        this.setDefaultColorsAtBackgroundTypeSwithing(value);
        this.handleColorInstanceToStore();
      },
      setDefaultColorsAtBackgroundTypeSwithing(type) {
        if (type === COLORING_TYPES.SOLID_FILL || type === COLORING_TYPES.GRADIENT) {
          const color = this.colorInstance.getColor();
          if (color === null) {
            this.resetColor();
            this.colorInstance.handleColorChange(0);
          }
        }
      },
      linearDirectionChange(value) {
        this.colorInstance.setLinearDirection(value);
        this.handleColorInstanceToStore();
      },

      handleColorInstanceToStore() {
        if (this.colorInstance.isQuillOmColor()) {
          this.$bus.$emit('QuillColorUpdate');
          return;
        }
        this.$store.commit('handleColorInstanceToStore', this.colorInstance);
      },

      inputChange(data) {
        if (!data) {
          return;
        }
        if (data.hex && this.isValidHex(data.hex)) {
          this.childChange(data.hex);
        } else if (data.r || data.g || data.b || data.a) {
          const rgba = {
            r: data.r || this.colors.rgba.r,
            g: data.g || this.colors.rgba.g,
            b: data.b || this.colors.rgba.b,
            a: data.a || this.colors.rgba.a,
            source: 'rgba',
          };
          this.childChange(rgba);
        }
      },
    },
  };
</script>

<style lang="sass">
  .vc-tab-wrapper-empty
    .sidebar-tab-wrapper-tabs
      margin: 0 !important
  .vc-sketch
    .sidebar-tab-wrapper-tabs
      border-bottom: 1px solid #E3E5E8
</style>
