<template lang="pug">
sidebar-accordion(:title="$t('display')")
  om-dropdown-input(
    label="textShadow"
    property="textShadow.type"
    :items="shadowType"
    :typeOfComponent="typeOfComponent"
  )
  om-color-input(
    v-if="getValueOf('textShadow.type') !== 'none'"
    label="shadowColor"
    property="textShadow.color"
    :typeOfComponent="typeOfComponent"
  )
  om-range-input(
    label="textRotation"
    property="textRotation"
    :min="-180"
    :max="180"
    :step="1"
    unit="°"
    :typeOfComponent="typeOfComponent"
  )
</template>

<script>
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      // item: { type: Object, required: true },
      item: { type: Object },
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        shadowType: [
          { key: 'none', value: 'none' },
          { key: 'light', value: 'light' },
          // {key: 'normal', value: 'normal'},
          { key: 'medium', value: 'medium' },
          { key: 'strong', value: 'large' },
        ],
      };
    },
  };
</script>
