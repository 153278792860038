import cookie from '@/mixins/cookie';
import { mapState, mapGetters } from 'vuex';

export default {
  mixins: [cookie],
  data() {
    return {
      cookieKeyAppModalSeen: 'x-om-seen-embed-modal',
    };
  },
  computed: {
    ...mapState(['showAdminLoader']),
    ...mapGetters([
      'hasShopify',
      'domains',
      'isActiveShopifyDomain',
      'isSubUser',
      'isNormalUser',
      'isLoggedIn',
    ]),
    onOnboardingRoute() {
      if (!this.$route.name) return false;
      return (
        this.$route.name.includes('onboarding') ||
        this.$route.name.includes('agency_benefits') ||
        this.$route.name.includes('non_wizard') ||
        this.$route.name.includes('enable_app_embed')
      );
    },
    onOAuthLoginRoute() {
      return this.$route.name === 'oauth_login';
    },
    onCodeInsertRoute() {
      return (
        (this.$route.name || '').includes('settings-code-insert') ||
        (this.$route.name || '').includes('code-insert')
      );
    },
    onRegisterRoute() {
      return this.$route.name === 'registration';
    },
    onSwitchAccount() {
      return this.$route.name === 'switch_account';
    },
    onConnectShopify() {
      return this.$route.name === 'connect_shopify';
    },
    onConnectShop() {
      return this.$route.name === 'connect_shop';
    },
  },
  methods: {
    setEmbedNotificationModalSeen() {
      this.setCookie(this.cookieKeyAppModalSeen, 1, 100);
    },
    hasSingleShopifyShop() {
      return (
        this.domains.length === 1 &&
        this.domains[0].platform === 'shopify' &&
        this.isActiveShopifyDomain(this.domains[0].shopId)
      );
    },
    hasOnlyNonShopifyDomains() {
      return !this.hasShopify && this.domains.length;
    },
    getShopsType() {
      if (this.hasSingleShopifyShop()) return 'singleShopify';
      if (this.hasOnlyNonShopifyDomains()) return 'nonShopify';
      return 'mixedDomains';
    },
    hasActiveDomain() {
      return this.domains.some((domain) => domain?.inactive !== true);
    },
  },
};
