<template lang="pug">
mixin z-index-form
  .d-flex.align-items-center.w-100
    .font-size-0--8125 Z-index
    input.simpleInput.w-25.ml-auto(type="number" v-model="value")

div
  sidebar-accordion(v-if="showAccordion" :title="$t('others')" :embedded="embedded")
    .sidebar-input-wrapper
      +z-index-form
  .sidebar-input-wrapper(v-else)
    +z-index-form
</template>

<script>
  import embeddedAccordion from '@/editor/mixins/embeddedAccordion';
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [embeddedAccordion, itemMixin],
    props: {
      property: { type: String },
      showAccordion: { type: Boolean, default: true },
    },
    computed: {
      value: {
        get() {
          return this.getValueOf(this.property, 0) || 0;
        },
        set(value) {
          this.setValueOf(this.property, value);
          window.om.bus.$emit('userInputChange', { property: this.property, value });
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  .simpleInput
    min-width: 4.25rem !important
</style>
