<template lang="pug">
.h-100
  sidebar-title
    span {{ $t('luckywheel') }}
  .sidebar-inner-box.pt-0.w-100
    sidebar-tab-wrapper(defaultTab="general")
      template(#tabs)
        sidebar-tab-item#general(:name="$t('sidebarSettings.tabs.name.general')")
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
      template(#content)
        sidebar-tab-content#general
          .sidebar-input-wrapper.flex-row-baseline.pt-3
            .button.button-primary.button-primary-large.mt-4(
              @click="openLuckyWheelSettings"
              data-track-property="component:OmLuckyWheel|location:sidebar|change:settings.open|setting:settings"
            ) {{ $t('settings') }}
        sidebar-tab-predefined-style(property="selectedElement" component="luckyWheel")
          sidebar-accordion(:title="$t('design')" :opened="true")
            transition(name="expand")
              .expand-transition.mb-0(v-if="showSource")
                om-color-input(
                  label="background"
                  property="luckyWheel.backgroundFillColor"
                  typeOfComponent="luckyWheel"
                  :onlySolid="true"
                )
                om-color-input(
                  label="sliceColor1"
                  property="luckyWheel.sliceColor1"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="sliceColor2"
                  property="luckyWheel.sliceColor2"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="sliceColor3"
                  property="luckyWheel.sliceColor3"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="sliceColor4"
                  property="luckyWheel.sliceColor4"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="dots"
                  property="luckyWheel.dots"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="sticks"
                  property="luckyWheel.sticks"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="radius"
                  property="luckyWheel.radius"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="ticker"
                  property="luckyWheel.ticker"
                  typeOfComponent="luckyWheel"
                )
          sidebar-accordion(:title="$t('fontEdit')")
            transition(name="expand")
              .expand-transition(v-if="showSource")
                font-dropdown(
                  label="fontFamily"
                  :property="`fontFamily`"
                  :i18n="false"
                  typeOfComponent="luckyWheel"
                )
                om-color-input(
                  label="color"
                  property="luckyWheel.labelTextColor"
                  typeOfComponent="luckyWheel"
                )
                om-range-input(
                  label="fontSize"
                  :property="`fontSize`"
                  :min="0"
                  :max="90"
                  :step="1"
                )
                om-font-awesome-button(
                  label="style"
                  property=""
                  subPath=""
                  typeOfComponent="luckyWheel"
                )
          sidebar-accordion(v-if="mobilePreview" :title="$t('responsive')")
            om-dropdown-input(
              label="position"
              property="selectedElement.mobile.position"
              :items="mobilePositions"
              typeOfComponent="luckyWheel"
            )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [itemMixin],
    data() {
      return {
        showSource: true,
        mobilePositions: [
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
      };
    },
    computed: {
      ...mapState(['selectedElement', 'mobilePreview']),
    },
    methods: {
      openLuckyWheelSettings() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'lumiraSettings' });
      },
    },
  };
</script>
