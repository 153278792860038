<template lang="pug">
div
  //-sidebar-accordion(v-if="isSteveMode && properties.length" :title="$t('setElementDefaults.title')")
    om-range-input(v-if="properties.includes('fontSize')" label="fontSize" property="defaultFontSize" :min="1" :max="100" :step="1" typeOfComponent="text")
    om-font-awesome-button(label="style" property="" subPath="desktop" :showItems="fontStyles" :templateEdit="true")
  set-element-defaults
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'ElementDefaultValues',
    props: {
      properties: { type: Array, default: () => [] },
    },
    computed: {
      ...mapGetters(['isSteveMode']),
      fontStyles() {
        const styles = ['textWeight', 'fontItalic', 'textDecoration', 'textStrikeThrough'];
        return this.properties.filter((p) => styles.includes(p));
      },
    },
  };
</script>
