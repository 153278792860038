<template lang="pug">
  nav(class="navbar sticky-top navbar-expand-lg brand-custom-navbar")
    .container.py-0.h-100
      .mx-auto
        router-link(class="navbar-brand" :to="logoLink()" tag="a")
          template
            img(class="logo" v-if="whiteLabel === null || !WLcustomLogo" :src="require('@/assets/om-logo.svg')")
            img(class="logo" v-if="whiteLabel !== undefined && WLcustomLogo" :src="WLcustomLogo")

</template>

<script>
  import guessLang from '@/mixins/guessLang';
  import whiteLabelling from '@/mixins/whiteLabelling';

  export default {
    mixins: [guessLang, whiteLabelling],
    mounted() {
      this.getWhiteLabelSettings();
    },
    methods: {
      logoLink() {
        return this.getLangQueryParam() ? `/login/${this.getLangQueryParam()}` : '/';
      },
    },
  };
</script>
