<template lang="pug">
  .om-alert(:style="{ visibility: hidden ? 'hidden' : 'visible' }")
    svg(width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="close-alert" @click="close")
      path(d="M8.97033 0C4.55793 0 0.98291 3.58065 0.98291 8C0.98291 12.4194 4.55793 16 8.97033 16C13.3827 16 16.9578 12.4194 16.9578 8C16.9578 3.58065 13.3827 0 8.97033 0ZM12.8867 10.1C13.0381 10.2516 13.0381 10.4968 12.8867 10.6484L11.6113 11.9226C11.46 12.0742 11.2152 12.0742 11.0638 11.9226L8.97033 9.80645L6.87363 11.9226C6.72226 12.0742 6.47748 12.0742 6.32611 11.9226L5.05392 10.6452C4.90254 10.4935 4.90254 10.2484 5.05392 10.0968L7.16672 8L5.05392 5.9C4.90254 5.74839 4.90254 5.50323 5.05392 5.35161L6.32933 4.07419C6.4807 3.92258 6.72548 3.92258 6.87685 4.07419L8.97033 6.19355L11.067 4.07742C11.2184 3.92581 11.4632 3.92581 11.6146 4.07742L12.89 5.35484C13.0413 5.50645 13.0413 5.75161 12.89 5.90323L10.7739 8L12.8867 10.1Z" fill="white")
    slot
</template>

<script>
  export default {
    data: () => ({
      hidden: false,
    }),
    methods: {
      close() {
        this.hidden = true;
      },
    },
  };
</script>

<style lang="sass">
  @import '../sass/variables/variables'

  .om-alert
    padding: 8px
    border-radius: 3px
    background-color: $om-red
    font-family: Roboto
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 18px
    letter-spacing: 0px
    text-align: left
    color: #FFFFFF
    .close-alert
      cursor: pointer
      margin-right: 8px
</style>
