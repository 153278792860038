<template lang="pug">
.sidebar-color-input-wrapper
  label {{ $t(label) }}
  .color-input-wrapper
    .color-input(
      v-if="gradient"
      @click.stop="showColorPicker"
      :style="{ 'background-image': getGradientColor }"
    )
    .color-input(
      v-if="!gradient"
      @click.stop="showColorPicker"
      :style="{ 'background-color': value }"
    )
    img.color-input-image(v-if="type === 'image' && backgroundSrc" :src="backgroundSrc")
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import themeColorsMixin from '@/editor/mixins/themeColors';

  export default {
    mixins: [itemMixin, themeColorsMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      typeOfComponent: { type: String, default: 'global' },
      alpha: { type: Boolean, default: true },
      gradient: { type: Object, required: false },
      onlySolid: { type: Boolean, default: false },
    },
    computed: {
      ...mapState(['images']),
      getGradientColor() {
        return Object.values(this.gradient)[0];
      },
      palette() {
        return this.getColors;
      },
      type() {
        const typePath = this.getOverridedPath('type');
        const type = this.getValueOf(typePath);

        return type;
      },
      value() {
        return this.getColor(this.property);
      },
      backgroundSrc() {
        const path = this.getOverridedPath('imageId');

        const imageId = this.getValueOf(path);
        const image = this.images.find((i) => i._id === imageId);
        return image ? image.url : null;
      },
    },
    methods: {
      getColor(path) {
        const value = this.getValueOf(path);

        if (this.type === 'transparent' || (!this.type && /\.background\./.test(path))) {
          return 'transparent';
        }

        if (value > -1) {
          return this.getColors[value];
        }

        return value;
      },
      getPath(path) {
        if (this.isPageStyle(path)) {
          const result = this.getRealPath(path);

          return `selectedPage.${result}`;
        }

        return this.getRealPath(path, false);
      },
      getOverridedPath(override) {
        return this.property.replace(/color\d?/, override);
      },
      showColorPicker(e) {
        const COLOR_PICKER_DIMENSION = { width: 308, height: 558 };
        const OFFSET_LEFT = 258;
        const OFFSET_TOP = 100;

        const path = e.path || (e.composedPath && e.composedPath());
        const colorInputElementPosition = path[0].getBoundingClientRect();
        let left = colorInputElementPosition.left - OFFSET_LEFT;
        let top = colorInputElementPosition.top - OFFSET_TOP;
        const right = colorInputElementPosition.right;

        if (left < 0) {
          left = right;
        }

        if (
          top >= 0 &&
          left >= 0 &&
          COLOR_PICKER_DIMENSION.width <= window.innerWidth &&
          COLOR_PICKER_DIMENSION.height + colorInputElementPosition.top - OFFSET_TOP >=
            window.innerHeight
        ) {
          top = 30;
        }
        const params = {
          top,
          left,
          right,
          property: this.getPath(this.property),
          value: this.value,
          alpha: this.alpha,
          onlySolid: this.onlySolid,
          component: this.typeOfComponent,
        };
        this.$store.commit('showColorPicker', params);
      },
    },
  };
</script>
<style lang="sass">
  .color-input
    &-wrapper
      position: relative
    &-image
      pointer-events: none
      position: absolute
      object-fit: cover
      height: 100%
      width: auto
      top: 0
      left: 0
      right: 0
      margin: auto
</style>
