<template lang="pug">
.om-input-group
  .om-input-group-elements
    .om-input-group-element(
      v-for="(key, index) in keys"
      :key="key.name"
      :class="{ 'last-element': index === keys.length - 1 }"
    )
      .om-input-group-element-label {{ i18n ? $t(key.name) : key.name }}
      input.simpleInput.om-input-group-element-input(
        :type="type"
        :value="key.disabled ? '' : getValue(key.name)"
        :min="allowNegative ? null : 0"
        step="1"
        :disabled="key.disabled"
        @input="setValue(key.name, $event)"
        :placeholder="getPlaceholderValue(key)"
        :class="{ error: errorMap.includes(key.name) }"
      )
    .om-input-group-element.om-input-group-element-link
      om-button.om-input-group-element-input-link(
        icon="link-alt"
        iconOnly
        ghost
        :title="$t('allSides')"
        :selected="allKeys"
        @click="updateAllKeys(!allKeys)"
      )
</template>
<script>
  import { mapState } from 'vuex';
  import { debounce } from 'lodash-es';
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      type: {
        type: String,
        default: 'number',
      },
      property: { type: String, default: '' },
      keys: { type: Array, default: () => [] },
      i18n: {
        type: Boolean,
        default: true,
      },
      typeOfComponent: {
        type: String,
        default: 'global',
      },
    },
    data() {
      return {
        isFallback: false,
        canEditMobile: true,
        errorMap: [],
      };
    },

    computed: {
      ...mapState(['mobilePreview']),
      allKeys: {
        get() {
          return this.getValueOf(this.getProperty('allSides'), false);
        },
        set(value) {
          this.update('allSides', value);
        },
      },

      allowNegative() {
        return this.property.toLowerCase().includes('margin');
      },
    },

    methods: {
      getProperty(key) {
        return `${this.property}.${key}`;
      },

      getValue(key) {
        return this.getValueOf(this.getProperty(key), 0, true);
      },

      getPlaceholderValue(key) {
        const value = this.getValueOf(this.getProperty(key.name), 0, true, 'OmInputGroup');
        if (value && value.forced) {
          const { desktopValue } = value;
          return desktopValue;
        }
      },

      setValue: debounce(function (key, event) {
        let value = 0;
        try {
          value = parseInt(event.target.value, 10);
          if (isNaN(value) && !this.mobilePreview) value = 0;

          // to set desktop value as default
          if (isNaN(value) && this.mobilePreview) value = null;
        } catch (e) {
          console.log('@@@error', { e, value });
        }

        this.setError({ key, value: event.target.value });
        this.update(key, value);

        if (this.allKeys) {
          this.keys.forEach(({ name, disabled }) => {
            if (name !== key && !disabled) {
              this.update(name, value);
            }
          });
        }

        window.om.bus.$emit('userInputChange', { property: this.property, value });
      }, 120),

      setError({ key, value }) {
        if (!this.allowNegative && parseInt(value, 10) < 0) {
          if (this.allKeys) {
            this.errorMap = ['top', 'right', 'bottom', 'left'];
          } else {
            this.errorMap.push(key);
          }
        } else {
          if (this.allKeys) this.errorMap = [];

          const index = this.errorMap.indexOf(key);
          if (index !== -1) {
            this.errorMap.splice(index, 1);
          }
        }
      },
      update(key, value) {
        const property = this.getProperty(key);

        this.setValueOf(property, value || value === 0 ? value : null);
      },

      setAllKeys() {
        const value = this.getValue(this.keys[0].name);

        this.keys.forEach(({ name, disabled }) => {
          if (!disabled) {
            this.update(name, value);
          }
        });

        this.setError({ value });
      },

      updateAllKeys(value) {
        const property = this.getProperty('allSides');
        this.setValueOf(property, value);
        if (value) {
          this.setAllKeys();
        }
        window.om.bus.$emit('userInputChange', { property, value });
      },
    },
  };
</script>

<style lang="sass">
  .om-input-group
    &-all-sides
      display: flex
      align-items: center
      margin-bottom: 0.875rem
    &-elements
      display: flex

    &-element
      &-link
        display: flex
        align-items: flex-end
      &-input-link
        display: flex
        justify-content: center
        align-items: center
        padding: .4375rem .75rem .375rem
        background: #d7dbdf
        border: 1px solid #d7dbdf
        border-top-right-radius: 3px
        border-bottom-right-radius: 3px
        color: #696d72
        cursor: pointer
        align-self: end
        &.selected
          svg
            color: var(--brand-primary-color)
        > div
          line-height: 1.625rem
        &.active
          background: var(--brand-primary-color)
          border-color: var(--brand-primary-color)
          color: white
      &-label
        font-size: 0.8125rem
        margin-bottom: 0.1875rem

      .simpleInput
        border-radius: 0
        border-right: none
        border-color: #d7dbdf
        padding-left: 0
        text-indent: .75rem
        padding-right: .125rem

        &:disabled
          background-color: #d7dbdf

        &:focus
          box-shadow: none
          border-color: #d7dbdf
      &:first-child
        .simpleInput
          border-top-left-radius: 3px
          border-bottom-left-radius: 3px
      &:last-child
        .simpleInput
          border-top-right-radius: 3px
          border-bottom-right-radius: 3px
          border-right: 1px solid #d7dbdf
</style>
