<template lang="pug">
.product-tour-success(v-if="shown")
  .close(@click="close")
    close-icon(:width="15" :height="15" color="#C2C2C2")
  .body
    .svg
      img.mr-2(:src="require('@/assets/admin/svg/success.svg')" width="160px" height="160px")
    .text
      .title {{ $t('productTour.congrats') }}
      .description
        | {{ $t('productTour.congratsText') }}
        br
        | {{ $t('productTour.goAhead') }}
</template>

<script>
  export default {
    data: () => ({
      shown: false,
    }),
    created() {
      window.om.bus.$on('showProductTourSuccess', () => {
        this.shown = true;
        setTimeout(() => {
          this.shown = false;
        }, 5000);
      });
    },

    methods: {
      close() {
        this.shown = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .product-tour-success
    z-index: 999999999
    position: absolute
    padding: 10px 28px
    width: 456px
    height: 160px
    background: #FFFFFF
    border-radius: 5px
    bottom: 10px
    right: 10px
    .body
      display: flex
      align-items: center
      width: 100%
      height: 100%
      .svg
        margin-right: 20px
      .text
        font-family: Roboto
        color: #505763
        font-size: 14px
      .title
        line-height: 24px
        font-weight: 700
        margin-bottom: 12px
      .description
        line-height: 20px
  .close
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer
</style>
