<template lang="pug">
mixin spacing-tooltip
  om-tooltip.ml-2(large v-if="mobilePreview")
    span {{ $t('mobileSpacing') }}

mixin padding-form
  label.input-group-main-label
    span(v-html="$t('padding')")
    DeviceSelector(:float="embedded ? 'right' : null" :hasViewText="embedded ? true : false")
    +spacing-tooltip
  om-input-group.padding(
    type="number"
    :property="subPath"
    :keys="directions"
    :typeOfComponent="typeOfComponent"
  )
div
  sidebar-accordion(
    v-if="showAccordion"
    :title="$t('padding')"
    :embedded="embedded"
    :addDeviceSelector="true"
    :opened="opened"
  )
    template(slot="tooltip")
      +spacing-tooltip
    .sidebar-input-wrapper.input-group-wrapper
      +padding-form
  .sidebar-input-wrapper.input-group-wrapper(v-else)
    +padding-form
</template>

<script>
  import { mapState } from 'vuex';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import embeddedAccordion from '@/editor/mixins/embeddedAccordion';

  export default {
    components: { DeviceSelector },
    mixins: [embeddedAccordion],
    props: {
      propsProperty: { type: String },
      subPath: { type: String, default: 'padding' },
      typeOfComponent: { type: String, default: 'global' },
      opened: { type: Boolean, default: false },
      showAccordion: { type: Boolean, default: true },
    },
    computed: {
      ...mapState(['mobilePreview']),
      directions() {
        return [
          { name: 'top', disabled: false },
          { name: 'right', disabled: false },
          { name: 'bottom', disabled: false },
          { name: 'left', disabled: false },
        ];
      },
    },
  };
</script>
