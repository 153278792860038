<template lang="pug">
mixin block-layout-form
  .editor-row
    .sidebar-input-wrapper.flex-row-baseline
      span
        label(style="position: static") {{ $t('columnCount') }}
      .column-split-wrapper.mt-2
        column-split-svg(
          :layout="[[]]"
          @click.native="setColumnCount(1)"
          :class="{ selected: columnCount === 1 }"
        )
        column-split-svg(
          :layout="[6, 6]"
          @click.native="setColumnCount(2)"
          :class="{ selected: columnCount === 2 }"
        )
        column-split-svg(
          :layout="[4, 4, 4]"
          @click.native="setColumnCount(3)"
          :class="{ selected: columnCount === 3 }"
        )
  .editor-row(v-if="columnCount > 1" :key="rerender")
    .sidebar-input-wrapper.flex-row-baseline
      span
        label(style="position: static") {{ $t('columnSplit') }}
      .column-split-wrapper.mt-2(v-if="columnCount > 1")
        column-split-svg(
          v-for="(layout, index) in possibleLayouts"
          :layout="layout"
          @click.native="setColumnSplit(layout)"
          :class="{ selected: isLayoutSelected(layout) }"
          :key="index"
        )
div
  sidebar-title
    span {{ $t('editRow') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          div
            +block-layout-form
          .sidebar-input-wrapper
            .d-flex.flex-wrap
              .column-within-block {{ $t('columnsWithinRow') }}
              .column-align-wrapper
                .column-align-box(
                  v-for="(col, index) in selectedRowColumns"
                  :class="{ active: selectedElement && selectedElement.uid === col.uid }"
                  @click="selectColumn(col)"
                )
                  div {{ columnText(index) }}
        sidebar-tab-predefined-style(property="selectedElement" component="row" editMobile)
          template(#before)
            custom-theme-style
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import columnMixin from '@/editor/mixins/column';
  import { hasElWheel } from '@/editor/util';
  import { getBrandedClassString } from '@/components/Elements/Button';

  const workspaceStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace.contentWindow.om.store;
  };

  export default {
    components: {
      ColumnSplitSvg: () => import('@/editor/components/svg/ColumnSplitSvg'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin, columnMixin],
    data() {
      return {
        show: true,
        showMobile: false,
        imagePosition: [
          { key: 'original', value: 'full' },
          { key: 'contain', value: 'contain' },
          { key: 'cover', value: 'cover' },
          { key: 'stretch', value: '100% 100%' },
        ],
        selectedRowColumns: [],
        rerender: 0,
      };
    },
    computed: {
      ...mapGetters(['isNano', 'isEmbedded', 'nestedAccess']),
      ...mapState(['selectedColumn', 'selectedRow', 'selectedElement']),
      possibleLayouts() {
        if (this.columnCount === 2)
          return [
            [3, 9],
            [4, 8],
            [5, 7],
            [6, 6],
            [7, 5],
            [8, 4],
            [9, 3],
          ];
        if (this.columnCount === 3)
          return [
            [4, 4, 4],
            [6, 3, 3],
            [3, 6, 3],
            [3, 3, 6],
          ];
        return [];
      },
      columnCount: {
        get() {
          return this.selectedRow.data.columnSplit.length;
        },
        set(value) {
          const changeValue = () => {
            this.$bus.$emit('updateColumnCount', { uid: this.selectedRow.uid, count: value });
          };

          this.$nextTick(() => {
            const cols = this.columnsOfRow(this.selectedRow.uid).splice(value);
            let colHasElement = false;
            for (const col of cols) {
              colHasElement = this.elementsOfColumn(col.uid).length;
              if (colHasElement) break;
            }

            if (this.columnCount > value && colHasElement) {
              this.$modal.show('dialog', {
                text: this.$t('columnCountWarning'),
                buttons: [
                  {
                    title: this.$t('yes'),
                    class: getBrandedClassString({ primary: true }, 'mr-3'),
                    handler: () => {
                      changeValue();
                      this.$modal.hide('dialog');
                    },
                  },
                  {
                    title: this.$t('cancel'),
                    class: getBrandedClassString({ secondary: true }),
                    default: true,
                  },
                ],
              });
            } else {
              changeValue();
            }
          });
        },
      },
      spacingProps() {
        return {
          typeOfComponent: 'row',
        };
      },
    },
    watch: {
      columnCount() {
        this.selectedRowColumns = this.columnsOfRow(this.selectedRow.uid);
      },
    },
    created() {
      this.selectedRowColumns = this.columnsOfRow(this.selectedRow.uid);
    },
    mounted() {
      this.$bus.$on('column-split-changed', () => {
        this.rerender++;
      });
    },
    methods: {
      hasElWheel,
      ...mapMutations(['updateStyle']),
      columnsOfRow(id) {
        if (window.om.template) {
          return window.om.template.elements.filter((e) => e.type === 'OmCol' && e.rowId === id);
        }
        return [];
      },
      elementsOfColumn(colId) {
        if (!window.om.template) {
          return [];
        }

        return window.om.template.elements.filter(
          (e) =>
            e.type !== 'OmCol' &&
            e.type !== 'OmRow' &&
            e.type !== 'OmPage' &&
            !e.isTeaser &&
            e.colId === colId,
        );
      },
      isLayoutSelected(layout) {
        return layout.toString() === this.selectedRow.data.columnSplit.toString();
      },
      setColumnSplit(value) {
        this.selectedRow.data.columnSplit = value;
        this.resetCustomColWidths();
      },
      selectRow() {
        this.$bus.$emit('selectElement', this.selectedRow.uid);
      },
      selectColumn(column) {
        this.$bus.$emit('selectElement', column ? column.uid : null);
      },
      setColumnCount(count) {
        if (this.columnCount !== count) this.columnCount = count;
        this.resetCustomColWidths();
      },
      resetCustomColWidths() {
        this.selectedRowColumns.forEach((c) => {
          workspaceStore().dispatch('updateStyleProperty', {
            uid: c.uid,
            property: 'maxWidth',
            value: null,
          });
        });
      },
    },
  };
</script>
