export const BTN_CLASS = 'btn';
export const BTN_PRIMARY = 'btn-primary';
export const BTN_SECONDARY = 'btn-secondary';
export const BTN_SMALL = 'btn-sm';
export const BTN_LARGE = 'btn-lg';
export const BTN_LINK = 'btn-link';
export const BTN_BLOCK = 'btn-block';
export const BTN_LOADING = 'btn-loading';
export const BTN_ADD_ICON = 'btn-add-icon';
export const BTN_ICON_ONLY = 'btn-icon-only';

export const buildClassObject = ({
  primary,
  secondary,
  small,
  large,
  ghost,
  block,
  loading,
  icon,
  iconOnly,
}) => {
  return {
    [BTN_CLASS]: true,
    [BTN_PRIMARY]: primary,
    [BTN_SECONDARY]: secondary,
    [BTN_SMALL]: small,
    [BTN_LARGE]: large,
    [BTN_LINK]: ghost,
    [BTN_BLOCK]: block,
    [BTN_LOADING]: loading,
    [BTN_ADD_ICON]: icon,
    [BTN_ICON_ONLY]: iconOnly,
  };
};

export const getClassList = (properties) => {
  const allClasses = buildClassObject(properties);
  const list = [];
  Object.entries(allClasses).forEach(([key, added]) => {
    if (added) list.push(key);
  });
  return list;
};

export const getBrandedClassString = (properties, extras = []) => {
  if (!Array.isArray(extras)) extras = [extras];
  const merged = [...getClassList(properties).map((cls) => `brand-${cls}`), ...extras];
  return merged.join(' ');
};
