<template>
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 416.75 416.75"
    style="enable-background: new 0 0 416.75 416.75"
    xml:space="preserve"
    width="25"
    height="25"
  >
    <title>Code View</title>
    <g>
      <path
        style="fill: #ed5a29"
        d="M305.025,325.756c-3.7,0-7.4-1.4-10.3-4.1c-6-5.7-6.3-15.2-0.6-21.2l86.9-92.1l-86.9-92.1   c-5.7-6-5.4-15.5,0.6-21.2s15.5-5.4,21.2,0.6l96.7,102.4c5.5,5.8,5.5,14.8,0,20.6l-96.7,102.4   C312.925,324.156,308.925,325.756,305.025,325.756z"
      />
      <path
        style="fill: #ed5a29"
        d="M111.725,325.756c-4,0-8-1.6-10.9-4.7l-96.7-102.4c-5.5-5.8-5.5-14.8,0-20.6l96.7-102.4   c5.7-6,15.2-6.3,21.2-0.6s6.3,15.2,0.6,21.2l-87,92.1l86.9,92.1c5.7,6,5.4,15.5-0.6,21.2   C119.025,324.356,115.325,325.756,111.725,325.756z"
      />
      <path
        style="fill: #ed5a29"
        d="M185.325,373.856c-0.8,0-1.5-0.1-2.3-0.2c-8.2-1.3-13.8-8.9-12.6-17.1l46-300.9   c1.3-8.2,8.9-13.8,17.1-12.6c8.2,1.3,13.8,8.9,12.6,17.1l-46,300.9C199.025,368.556,192.625,373.856,185.325,373.856z"
      />
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      selected: { type: Boolean, default: false },
    },
    computed: {
      styleVal() {
        return this.selected ? 'fill:#ed5a29;' : '';
      },
    },
  };
</script>
