<template lang="pug">
.row.light-title(:class="{ 'is-col-selected': isColumnPane }")
  template(v-if="isRightSidebarLayersEnabled")
    .d-flex.align-items-center.sidebar-container.ml-1
      om-button.custom-style-button(primary ghost @click="showLayers" icon="angle-left-b")
      label {{ isColumnPane ? columnText(selectedColIndex) : paneTitle }}
  template(v-else)
    span {{ isColumnPane ? columnText(selectedColIndex) : paneTitle }}
</template>

<script>
  import { mapMutations, mapState, mapGetters } from 'vuex';
  import { track } from '@/services/xray';
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper/index';
  import { getNameFromURL } from '@om/template-properties/src/imageReplace';
  import columnMixin from '@/editor/mixins/column';
  import { getContextProperties } from '@/services/userInteractionTracker/properties';
  
  export default {
    mixins: [columnMixin],

    props: {
      selectedRowColumns: {
        type: Array,
      },
    },

    data() {
      return {
        selectedColIndex: 0,
      };
    },

    computed: {
      ...mapState([
        'mobilePreview',
        'teaserPreview',
        'selectedPage',
        'selectedColumn',
        'selectedRow',
        'selectedElement',
        'paneL2',
        'data',
        'themeKit'
      ]),
      ...mapGetters(['hasAccountFeature', 'isTeaserPage']),
      isFloatingImage() {
        return this.selectedElement && this.selectedElement.type === ELEMENTS.OmFloatingImage;
      },
      isDropdown() {
        return this.selectedElement.type === ELEMENTS.OmDropdown;
      },
      isLogoImage() {
        if (!this.themeKit?.logo?.current) return false;

        const logoName = getNameFromURL(this.themeKit.logo.current);
        const desktopUrl = getNameFromURL(this.selectedElement?.desktop?.background?.imageUrl);
        const mobileUrl = getNameFromURL(this.selectedElement?.mobile?.background?.imageUrl);

        const isDesktopSame = desktopUrl && desktopUrl === logoName
        const isMobileSame = mobileUrl && mobileUrl === logoName

        return isDesktopSame || isMobileSame;
      },
      imageTitle() {
        const logo = this.$t('onboarding.customTheme.customize.labels.logo').toLowerCase()
        const imageTitleEnding = this.isLogoImage ? ` (${logo})` : '';

        return `${this.$t('image')}${imageTitleEnding}`;
      },
      paneTitle() {
        const type = this.paneL2.replace('Pane', '');
        const key = `editorBeta.components.${type}.title`;

        if (this.isDropdown) {
          return this.$t('editorBeta.components.Dropdown.title');
        }

        if (this.paneL2 === 'ImagePane') {
          return this.isFloatingImage ? this.$t('editorBeta.components.FloatingImage.title') : this.imageTitle;
        }

        if (this.paneL2 === 'CloseButtonPane' && this.isPermanentTeaser && this.isTeaserPage) {
          return this.$t('editorBeta.components.TeaserCloseButton.title');
        }

        if (this.$te(key)) {
          return this.$t(key);
        }
        if (type === 'Page') {
          return this.selectedPage.data.title;
        }
        if (type === 'InputPicker') {
          return this.$t(`editorBeta.components.${type}.${this.selectedElement.type}.title`);
        }
        if (this.selectedPage) {
          return this.selectedPage.data.title;
        }
        return '';
      },
      isRightSidebarLayersEnabled() {
        // Teaser (old version)
        if (!this.selectedPage.isTeaser && this.paneL2) {
          const type = this.paneL2.replace('Pane', '');
          if (type === 'Teaser') return false;
        }
        return true;
      },
      isColumnPane() {
        return this.paneL2 === 'ColPane';
      },
      isPermanentTeaser() {
        return this.data.isPermanentTeaser;
      },
    },
    created() {
      if (window.om.template && this.isColumnPane) {
        const selectedRowColumns = window.om.template.elements.filter(
          (e) => e.type === 'OmCol' && e.rowId === this.selectedColumn.rowId,
        );
        this.selectedColIndex = selectedRowColumns.findIndex(
          (c) => c.uid === this.selectedColumn.uid,
        );
      }
    },

    methods: {
      ...mapMutations(['deselectAll', 'selectPage', 'setStateAttr', 'selectElementByUid']),
      showLayers() {
        track('editor_click', {
          location: 'editor-right-sidebar',
          setting: 'back-to-elements',
          ...getContextProperties(),
        });
        this.$bus.$emit('showLayers');
      },
    },
  };
</script>
<style lang="sass" scoped>
  .light-title
    .sidebar-container
      ::v-deep.custom-style-button
        min-width: unset
        width: 2rem
        height: 2rem
        padding: unset
        svg
          color: #505763 !important
      label
        padding-top: 8px
</style>
