<template lang="pug">
div(:style="`display:${display}`")
  i.fa(:class="[variant]" :style="`font-size:${size}em`")
  span(v-if="html.length" v-html="html")
</template>

<script>
  export default {
    props: {
      variant: { type: String, required: true },
      size: { type: String, default: '1' },
      html: { type: String, default: '' },
      display: { type: String, default: 'inline-block' },
    },
  };
</script>
