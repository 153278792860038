<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editText') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(:defaultTab="isSmartHeadlineFeatureEnabled ? 'general' : 'style'")
      template(#tabs)
        sidebar-tab-item#general(
          :name="$t('sidebarSettings.tabs.name.general')"
          v-if="isSmartHeadlineFeatureEnabled"
        )
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
        sidebar-tab-item#advanced(:name="$t('sidebarSettings.tabs.name.advanced')")
      template(#content)
        sidebar-tab-content#general
          smart-headline-generation
        sidebar-tab-predefined-style(property="selectedElement" component="text" editMobile)
          template(#before)
            custom-theme-style
            sidebar-accordion(
              v-if="mobilePreview"
              :addDeviceSelector="true"
              :opened="true"
              :title="$t('textSettings')"
            )
              om-range-input(
                label="fontSize"
                property="fontMultiplier"
                :min="0"
                :max="200"
                :step="1"
                unit="%"
                :editMobile="true"
                typeOfComponent="text"
              )
              om-dropdown-input(
                label="mobileAlign"
                property="selectedElement.mobile.textAlign"
                :items="textAlign"
                :editMobile="true"
                typeOfComponent="text"
              )
            om-text-shadow-box(
              :item="selectedElement"
              propsProperty="selectedElement"
              typeOfComponent="text"
            )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import { SMART_HEADLINE_GENERATOR } from '@/utils/features';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      SmartHeadlineGeneration: () =>
        import('@/editor/components/sidebar/components/SmartHeadlineGeneration.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        textAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
      };
    },

    computed: {
      ...mapState(['selectedElement', 'mobilePreview']),
      ...mapGetters(['hasAccountFeature', 'isTemplateEditorMode']),
      isSmartHeadlineFeatureEnabled() {
        return this.hasAccountFeature(SMART_HEADLINE_GENERATOR) && !this.isTemplateEditorMode;
      },
      spacingProps() {
        return {
          typeOfComponent: 'text',
        };
      },
    },
  };
</script>
