<template lang="pug">
.sidebar-color-input-wrapper
  .d-flex.align-items-center
    label {{ $t(label) }}
    DeviceSelector(v-if="editMobile" :hasViewText="false")
  .color-input-wrapper(
    :class="{ 'with-image': coloringType === imageType }"
    @click.stop="showColorPicker"
  )
    img.color-input-image(v-if="coloringType === imageType && !isSvg" :src="backgroundSrc")
    inline-svg.color-input-image(
      v-else-if="coloringType === imageType && isSvg"
      :src="backgroundSrc"
    )
    .color-input(v-else :style="coloringStyle")
</template>
<script>
  import { mapState } from 'vuex';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import { isSvgUrl } from '@/editor/util';
  import { COLORING_TYPES } from '../../../utils/color-components/helper';
  import DeviceSelector from '../sidebar/components/DeviceSelector.vue';

  export default {
    components: { DeviceSelector },
    mixins: [themeColorsMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      typeOfComponent: { type: String, default: 'global' },
      alpha: { type: Boolean, default: true },
      colorInstance: { type: Object, required: true },
      editMobile: { type: Boolean, default: false },
    },
    computed: {
      ...mapState(['images']),
      isSvg() {
        return isSvgUrl(this.colorInstance.getImageSrc());
      },
      coloringStyle() {
        return this.colorInstance.getColorStyle();
      },
      backgroundSrc() {
        return this.colorInstance.getImageSrc();
      },
      coloringType() {
        return this.colorInstance.getColoringType();
      },
      colorValue() {
        const color = this.colorInstance.getColor();
        if (Number.isInteger(color)) {
          return this.getColorsMethod()[color];
        }
        return color;
      },
      colorProperty() {
        return this.colorInstance.getColorProperty();
      },
      imageType() {
        return COLORING_TYPES.IMAGE;
      },
    },
    created() {
      if (this.coloringType !== this.imageType || this.backgroundSrc) {
        return;
      }
      // Set missing image url into colorInstance
      const imageId = this.colorInstance.getImageId();
      const image = this.images.find((i) => i._id === imageId);
      if (image?.url) {
        this.colorInstance.setImage(imageId, image.url);
      }
    },
    methods: {
      showColorPicker(e) {
        const elementRect = e.srcElement.getBoundingClientRect();

        const params = {
          top: elementRect.top,
          left: elementRect.left,
          property: this.colorProperty,
          value: this.colorValue,
          component: this.typeOfComponent,
          alpha: this.alpha,
          colorInstance: this.colorInstance,
          calculateSidebarPosition: true,
        };

        this.$store.dispatch('showColorPicker', params);
      },
    },
  };
</script>
<style lang="sass">
  .color-input
    &-wrapper
      position: relative
      cursor: pointer
    &-image
      pointer-events: none
      position: absolute
      object-fit: cover
      height: 100%
      width: auto
      top: 0
      left: 0
      right: 0
      margin: auto
</style>
