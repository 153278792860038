<template lang="pug">
.pane
  sidebar-title
    span {{ $t('pickapresent') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          sidebar-accordion(:title="$t('settings')" :opened="true")
            .user-info-message.alert.alert-danger(v-if="needButton") {{ $t('pickAPresent.noButton') }}
            .sidebar-input-wrapper.mb-2
              .d-flex.align-items-center.w-100
                .font-size-0--8125 {{ $t('pickAPresent.presentCount') }}
                input.simpleInput.ml-auto.w-25(
                  :class="{ invalid: hasValidationError }"
                  type="number"
                  min="2"
                  max="5"
                  v-model.number="presentCount"
                )
            om-range-input(
              label="pickAPresent.presentSize"
              property="icon.size"
              :min="20"
              :max="200"
              typeOfComponent="pickapresent"
              :step="1"
              :editMobile="true"
            )
            om-range-input(
              label="spacing"
              property="icon.horizontalSpacing"
              typeOfComponent="pickapresent"
              :step="1"
              :editMobile="true"
            )
            .sidebar-input-wrapper.flex-row-baseline.pt-3
              .button.button-primary.button-primary-large(@click="showSettings") {{ $t('pickAPresent.settings.button') }}
              .button.button-primary.button-primary-large.mt-4(@click="showTest") {{ $t('pickAPresent.test.button') }}
        sidebar-tab-predefined-style(property="selectedElement" component="pickapresent")
          template(#before)
            custom-theme-style
            sidebar-accordion(:title="$t('design')")
              template(v-for="element in colorInputs")
                om-color-input(
                  :label="`pickAPresent.colors.${element.label}`"
                  :property="`pickAPresent.${element.property}`"
                  typeOfComponent="pickapresent"
                )
          SwitchableSetting(
            v-for="item in items"
            :toggleProperty="`selectedElement.data.${item}.active`"
            :key="`selectedElement.data.${item}.settings`"
            :title="`pickAPresent.${item}`"
          )
            om-simple-input(label="" :property="`selectedElement.data.${item}.text`")
            om-font(
              propsProperty="selectedElement"
              type="pickapresent"
              :subPath="item"
              typeOfComponent="pickapresent"
              :editMobile="true"
            )
            om-margin(
              propsProperty="selectedElement"
              :subPath="`desktop.${item}.margin`"
              :opened="true"
              typeOfComponent="pickapresent"
              :editMobile="false"
              :showAccordion="false"
            )
            om-padding(
              propsProperty="selectedElement"
              :subPath="`desktop.${item}.padding`"
              :opened="true"
              typeOfComponent="pickapresent"
              :editMobile="false"
              :showAccordion="false"
            )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      SwitchableSetting: () =>
        import('@/editor/components/sidebar/components/SwitchableSettingWrapper.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    data: () => ({
      show: true,
      items: ['title', 'subtitle'],
      canEditMobile: true,
      colorInputs: [
        {
          label: 'box',
          property: 'boxColor',
        },
        {
          label: 'ribbon',
          property: 'ribbonColor',
        },
        {
          label: 'shadow',
          property: 'shadowColor',
        },
      ],
    }),
    computed: {
      ...mapState(['selectedElement', 'validationError', 'selectedPage']),
      ...mapGetters(['nestedAccess']),

      presentCount: {
        get() {
          return this.nestedAccess('selectedElement.data.presentCount');
        },
        set(value) {
          if (value > 5) {
            value = 5;
          }

          if (value !== '' && value < 2) {
            value = 2;
          }

          this.updateData({ property: 'selectedElement.data.presentCount', value });
        },
      },

      hasValidationError() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('presentCount')
        );
      },
      needButton() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('pickAPresentNoButton')
        );
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'pick-a-present',
        };
      },
    },
    methods: {
      ...mapMutations(['updateData', 'setValidationError']),
      showSettings() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'pickAPresentSettings' });
      },
      showTest() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'pickAPresentTest' });
      },
    },
  };
</script>
