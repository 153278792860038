<template lang="pug">
.om-structure-v2-list-item-visibility
  .om-structure-v2-list-item-visibility-text(
    :class="isElementHidden(element) ? 'hidden-text' : ''"
  ) {{ !isElementHidden(element) ? $t('visibleOnlyOn') : '' }} {{ visibility(element) }}
  template(v-if="isElementHidden(element)")
    i.fa.fa-eye-slash
  template(v-else)
    desktop-svg(v-if="isHiddenMobile(element)" color="#ED5A29" :size="12")
    mobile-svg(v-if="isHiddenDesktop(element)" color="#ED5A29" :width="8" :height="12")
</template>

<script>
  import { get as _get } from 'lodash-es';

  export default {
    props: {
      element: {
        type: Object,
      },
    },

    methods: {
      isHiddenMobile(element) {
        return _get(element, 'mobile.hidden', false);
      },
      isHiddenDesktop(element) {
        return _get(element, 'desktop.hidden', false);
      },
      isElementHidden(element) {
        return this.isHiddenMobile(element) && this.isHiddenDesktop(element);
      },
      visibility(element) {
        if (this.isElementHidden(element)) return this.$t('hidden');
        if (this.isHiddenDesktop(element)) {
          return this.$t('device.mobile');
        }
        return this.$t('device.desktop');
      },
    },
  };
</script>
