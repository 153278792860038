<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editCustomHTML') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          sidebar-accordion(:title="$t('HTMLcode')" :opened="true")
            .sidebar-input-wrapper.flex-row-baseline.flex-column.align-items-start.pt-0.pb-2
              .pt-2.w-100
                .button.button-primary.button-primary-large(@click="showSettings") {{ $t('editHTML') }}
        sidebar-tab-content#style
          custom-theme-style
          om-size(propsProperty="selectedElement" typeOfComponent="customHTML")
          om-border-box(propsProperty="selectedElement" typeOfComponent="customHTML")
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },

    computed: {
      ...mapState(['selectedElement']),
      spacingProps() {
        return {
          typeOfComponent: 'customHTML',
        };
      },
    },

    methods: {
      showSettings() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'customHTMLSettings' });
      },
    },
  };
</script>
