<template lang="pug">
.h-100
  sidebar-title
  sidebar-tab-wrapper(defaultTab="general")
    template(#tabs)
      sidebar-tab-item#general(:name="$t('sidebarSettings.tabs.name.general')")
      sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
      sidebar-tab-item#advanced(
        v-if="checkTeaser"
        :name="$t('sidebarSettings.tabs.name.advanced')"
      )
    template(#content)
      sidebar-tab-content#general
        .sidebar-inner-box.pt-0
          .no-border-top-h3
            .teaser-disabled-box.mt-4(v-if="!isTeaserEnabled")
              .icon-warning
                UilExclamationTriangle
              .teaser-disabled-box-label {{ $t('teaserDisabledWarning') }}
            .h3-elem {{ $t('showTeaser') }}
            template(v-if="isNewTeaser")
              teaser-v2-settings
            template(v-else)
              om-switches(
                label="before"
                tooltip="beforeTooltip"
                :property="`globalStyle.tab.display.before`"
              )
              om-switches(label="after" :property="`globalStyle.tab.display.after`")
      sidebar-tab-content#style.enable-overflow
        custom-theme-style
        sidebar-accordion(:title="$t('display')")
          template(v-if="isNewTeaser")
            om-range-input(
              v-if="mobilePreview && !page.isTeaser"
              label="width"
              property="selectedPage.mobile.width"
              :editMobile="!page.isTeaser"
              :useGivenMax="true"
              :min="15"
              :max="100"
              :step="1"
              typeOfComponent="canvas"
              unit="%"
            )
            om-range-input(
              v-if="mobilePreview && page.isTeaser"
              label="width"
              property="selectedPage.mobile.teaserWidth"
              :editMobile="!page.isTeaser"
              :useGivenMax="true"
              :min="15"
              :max="100"
              :step="1"
              typeOfComponent="canvas"
              unit="%"
            )
            om-range-input(
              v-else-if="!mobilePreview"
              label="width"
              property="selectedPage.desktop.width"
              :editMobile="true"
              :min="15"
              :max="980"
              :step="1"
              typeOfComponent="canvas"
            )
            om-range-input(
              label="minHeight"
              property="selectedPage.desktop.minHeight"
              :min="teaserMinHeight"
              :max="250"
              :step="1"
              typeOfComponent="canvas"
            )
            om-dropdown-input(
              label="contentAlignment"
              property="selectedPage.data.vAlign"
              :items="vAlignItems"
            )
          template(v-else)
            .flex-row-baseline.main-graphic-select
              .flex-column-3
                .graphic-item(:class="{ 'graphic-selected-item': tabType === 'corner' }")
                  .graphic-select.graphic-select-tab.graphic-select-tab-corner(
                    @click="setTabType('corner')"
                  )
                span.graphic-label {{ $t('corner') }}
              .flex-column-3
                .graphic-item(:class="{ 'graphic-selected-item': tabType === 'basic' }")
                  .graphic-select.graphic-select-tab.graphic-select-tab-rectangle(
                    @click="setTabType('basic')"
                  )
                span.graphic-label {{ $t('rectangle') }}
              .flex-column-3
                .graphic-item(:class="{ 'graphic-selected-item': tabType === 'floating' }")
                  .graphic-select.graphic-select-tab.graphic-select-tab-circle(
                    @click="setTabType('floating')"
                  )
                span.graphic-label {{ $t('circle') }}
            om-range-input(
              label="size"
              :property="`globalStyle.tab.type.size`"
              :min="60"
              :max="150"
              :step="1"
            )
        om-background-box(
          :propsProperty="isNewTeaser ? 'selectedPage' : 'globalStyle.tab'"
          :subPath="isNewTeaser ? 'selectedPage' : 'globalStyle.tab'"
        )
        om-border-box.border-top-h3(
          :propsProperty="isNewTeaser ? 'page' : 'globalStyle'"
          :subPath="isNewTeaser ? 'selectedPage' : 'globalStyle.tab'"
        )
        sidebar-accordion(:title="$t('animation')")
          template(v-if="checkTeaser")
            om-dropdown-input.om-animation-select(
              label="attentionSeeker"
              property="selectedPage.data.attentionSeeker.type"
              :items="attentionSeekers"
            )
            om-range-input.mt-2(
              label="attentionSeekerFreq"
              property="selectedPage.data.attentionSeeker.freq"
              :step="1"
              :min="3"
              :max="10"
              unit="sec"
              typeOfComponent="global"
            )
          template(v-else)
            om-dropdown-input.om-animation-select.flex-nowrap(
              label="switchFromCampaign"
              property="globalStyle.tab.animation.switch.type"
              :items="switchFromCampaigns"
            )
            om-dropdown-input.om-animation-select(
              label="attentionSeeker"
              property="globalStyle.tab.animation.attentionSeeker.type"
              :items="attentionSeekers"
            )
            om-range-input.mt-2(
              label="attentionSeekerFreq"
              property="globalStyle.tab.animation.attentionSeeker.freq"
              :step="1"
              :min="3"
              :max="10"
              unit="sec"
              typeOfComponent="global"
            )
        .set-elements-defaults(v-if="isSteveMode || isSuperAdmin")
          .set-element-defaults-item
            button.button.add-new-button(
              v-if="!hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER)"
              @click="migrateTeaser"
            ) {{ $t('convertTeaser.oldToPage') }}
            button.button.add-new-button(v-else @click="revertTeaser") {{ $t('convertTeaser.pageToOld') }}
          .set-element-defaults-item(
            v-if="hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER) && isSteveMode"
          )
            button.button.add-new-button(@click="toggleTeaserVersion") {{ $t('convertTeaser.toggleVersion') }}
      advanced-tab(v-if="isNewTeaser" :spacingProps="spacingProps")
      sidebar-tab-content#advanced(v-else)
        om-padding(:showAccordion="false" propsProperty="page" subPath="selectedPage.padding")
        .mt-3(v-if="!mobilePreview")
          om-margin(
            :showAccordion="false"
            propsProperty=""
            subPath="selectedPage.desktop.margin"
            typeOfComponent="canvas"
            :addDeviceSelector="false"
          )
</template>
<script>
  import { mapMutations, mapGetters, mapState } from 'vuex';
  import { attentionSeekers as _attentionSeekers } from '@/editor/config/animation';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import itemMixin from '@/editor/mixins/item';
  import teaserMixin from '@/editor/mixins/teaser';
  import { get as _get } from 'lodash-es';

  export default {
    components: {
      UilExclamationTriangle,
      TeaserV2Settings: () => import('@/editor/components/TeaserV2Settings'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin, teaserMixin],
    data() {
      return {
        TEMPLATE_FEATURES,
        vAlignItems: [
          { key: 'top', value: 'om-flex-top' },
          { key: 'center', value: 'om-flex-center' },
          { key: 'bottom', value: 'om-flex-bottom' },
        ],
        teaserMinHeight: 40,
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'selectedPage', 'teaserPreview', 'account']),
      ...mapGetters(['isSteveMode', 'hasTemplateFeature', 'teaserPage', 'hasAccountFeature']),
      isSuperAdmin() {
        return _get(this.account, 'isSuperAdmin', false);
      },
      checkTeaser() {
        return this.hasTemplateFeature(this.TEMPLATE_FEATURES.NEW_TEASER) && !this.teaserPreview;
      },
      tabType() {
        return this.getValueOf('globalStyle.tab.type.style');
      },
      tabPosition() {
        const tabPosition = this.getValueOf('globalStyle.tab.position');
        return tabPosition;
      },
      switchFromCampaigns() {
        const items = [{ key: 'none', value: null }];

        if (this.tabPosition && this.tabPosition.includes('top')) {
          items.push({ key: 'animated', value: 'fadeInDown' });
        } else {
          items.push({ key: 'animated', value: 'fadeInUp' });
        }

        return items;
      },
      attentionSeekers() {
        const items = [{ key: 'none', value: null }];

        _attentionSeekers.forEach((a) => {
          if (a === 'heartBeat' && this.tabType !== 'floating') {
            return;
          }

          items.push({ key: a, value: a });
        });

        return items;
      },
      spacingProps() {
        return {
          showMargin: false,
          typeOfComponent: 'page',
        };
      },
    },
    watch: {
      tabPosition() {
        const switchFromCampaign = this.getValueOf('globalStyle.tab.animation.switch.type');
        const animationValues = this.switchFromCampaigns.map((a) => a.value);

        if (switchFromCampaign !== '' && !animationValues.includes(switchFromCampaign)) {
          this.setValueOf(
            'globalStyle.tab.animation.switch.type',
            this.switchFromCampaigns[1].value,
          );
        }
      },
      tabType() {
        const attentionSeekerType = this.getValueOf(
          'globalStyle.tab.animation.attentionSeeker.type',
        );
        const attentionSeekerTypes = this.attentionSeekers.map((a) => a.key);

        if (!attentionSeekerTypes.includes(attentionSeekerType)) {
          this.setValueOf('globalStyle.tab.animation.attentionSeeker.type', null);
        }
      },
    },
    methods: {
      ...mapMutations([
        'updateStyle',
        'setStateAttr',
        'addTemplateFeature',
        'removeTemplateFeature',
        'selectPage',
        'updateDataProperty',
      ]),
      setTabType(value) {
        this.setValueOf('globalStyle.tab.type.style', value);
      },
      migrateTeaser() {
        if (!this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER)) {
          this.$bus.$emit('initV2Teaser');
          this.$bus.$on('teaserPageAdded', (uid) => {
            this.addTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
            this.$bus.$emit('deselectAll');
            this.setStateAttr({ attr: 'paneLevel', value: 1 });
            setTimeout(() => {
              this.selectPage(uid);
              this.setStateAttr({ attr: 'teaserPreview', value: false });
              this.$bus.$emit('setTeaserPreview', false);
            }, 200);
          });
        } else {
          this.addTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
          this.$bus.$emit('deselectAll');
          this.setStateAttr({ attr: 'paneLevel', value: 1 });
          setTimeout(() => {
            this.selectPage(this.teaserPage.uid);
            this.setStateAttr({ attr: 'teaserPreview', value: false });
            this.$bus.$emit('setTeaserPreview', false);
          }, 200);
        }
      },
      revertTeaser() {
        if (this.teaserPage) {
          this.$bus.$emit('deselectAll');
          this.setStateAttr({ attr: 'paneLevel', value: 1 });
          this.removeTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
          this.setStateAttr({ attr: 'teaserPreview', value: true });
          this.$bus.$emit('setTeaserPreview', true);
        }
      },
      toggleTeaserVersion() {
        const newTeaser = this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
        if (newTeaser) {
          this.$bus.$emit('toggleTeaser');
        } else {
          this.$bus.$emit('deselectAll');
          setTimeout(() => {
            this.selectPage(this.teaserPage.uid);
          }, 150);
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .enable-overflow
    height: calc(100% - 5.375rem) !important
    overflow-y: auto
  .h3-elem
    padding: 1.0625rem 0
  .graphic-select-tab
    height: 2.5rem !important
  .teaser-disabled-box
    display: flex
    align-items: center
    padding: 14px
    border: 1px solid #D5D8DD
    border-radius: 4px
    .icon-warning
      align-self: center
      margin-right: 9px
      font-size: 1.5rem
      color: #F0C920
      height: 24px
      width: 24px
      margin-top: -10px
    &-label
      font-size: 10px
</style>
