<template lang="pug">
sidebar-tab-content#advanced(ref="content")
  slot(name="before")
  slot
    custom-theme-style
    om-advanced-settings(:propsProperty="property" :typeOfComponent="component")
    om-responsive-settings
    set-element-defaults(:extras="elementDefaultsExtras")
  slot(name="after")
</template>

<script>
  import predefinedTabMixin from '@/editor/mixins/sidebar/predefinedTab';

  export default {
    components: {
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [predefinedTabMixin],
    props: {
      elementDefaultsExtras: { type: Array, default: null },
    },
  };
</script>
