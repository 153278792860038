export default {
  methods: {
    isActive() {
      return true;
    },

    close() {
      this.show = false;
    },

    open() {
      if (this.active) {
        this.show = true;
      }
    },
  },

  beforeDestroy() {
    this.$bus.$off('closeAccordion', this.close);
  },

  mounted() {
    this.$bus.$emit('registerAccordion', this);
    this.$bus.$on('closeAccordion', this.close);
  },
};
