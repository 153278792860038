import Vue from 'vue';
import OmTable from '@/components/OmTable';
import OmModal from '@/components/Modals/OmModal';
import OmTabs from '@/components/OmTabs';
import OmTab from '@/components/OmTab';
import OmSlideDown from '@/components/OmSlideDown.vue';
import OmBreadcrumb from '@/components/OmBreadcrumb.vue';
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';
import Popper from 'vue-popperjs';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import SlideUpDown from 'vue-slide-up-down';
import OmProgressiveImage from '@/components/OmProgressiveImage.vue';
import TagsInput from '@voerro/vue-tagsinput';
import '@voerro/vue-tagsinput/dist/style.css';
import Close from '@/assets/admin/svg/Close.vue';
import FAIcon from '@/components/FAIcon.vue';
import Lottie from '@/components/Lottie.vue';
import InlineSVG from 'vue-inline-svg';
import ProductTourSuccess from '@/components/Notifications/ProductTourSuccess.vue';
import * as Elements from '@/components/Elements';

for (const elementName of Object.keys(Elements)) {
  Vue.component(elementName, Elements[elementName]);
}

Vue.component('ProductTourSuccess', ProductTourSuccess);
Vue.component('OmTable', OmTable);
Vue.component('OmModal', OmModal);
Vue.component('BrandTabs', OmTabs);
Vue.component('BrandTab', OmTab);
Vue.component('OmSlideDown', OmSlideDown);
Vue.component('OmBreadcrumb', OmBreadcrumb);
Vue.component('VSelect', vSelect);
Vue.component('Multiselect', Multiselect);
Vue.component('Popper', Popper);
Vue.component('SlideUpDown', SlideUpDown);
Vue.component('OmProgressiveImage', OmProgressiveImage);
Vue.component('TagsInput', TagsInput);
Vue.component('CloseIcon', Close);
Vue.component('FaIcon', FAIcon);
Vue.component('Lottie', Lottie);
Vue.component('InlineSvg', InlineSVG);
