<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editSpacer') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="style")
      template(#tabs)
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
        sidebar-tab-item#advanced(:name="$t('sidebarSettings.tabs.name.advanced')")
      template(#content)
        sidebar-tab-content#style
          custom-theme-style
          sidebar-accordion(:title="$t('display')")
            .mb-2
              om-range-input(
                label="size"
                :property="getPath('height')"
                :min="10"
                :max="400"
                :step="1"
                :editMobile="true"
              )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>
<script>
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        show: true,
        canEditMobile: true,
      };
    },
    computed: {
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'spacer',
        };
      },
    },
  };
</script>
