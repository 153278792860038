export default {
  position: ['top', 'right'],
  cssAnimation: 'vn-fade',
  velocityAnimation: {
    enter: (el) => {
      return {
        height: [el.clientHeight, 0],
        opacity: [1, 0],
      };
    },
    leave: {
      height: 0,
      opacity: [0, 1],
    },
  },
};
