const HANDLED_BRAINTREE_ERROR_CODES = [2000, 2001, 2004, 2047, 2099];

export default {
  methods: {
    getBraintreePaymentProblemType(errorCode) {
      if (HANDLED_BRAINTREE_ERROR_CODES.includes(errorCode)) {
        return String(errorCode);
      }
      return 'default';
    },
    navigateToCheckout(plan, userId) {
      const { name: planName, period: selectedPeriod } = plan;
      this.$router.push({
        name: 'checkout',
        params: {
          userId,
          step: 'payment',
        },
        query: { package: `${planName}-${selectedPeriod}` },
      });
    },
  },
};
