<template lang="pug">
.pane
  sidebar-title
  sidebar-tab-wrapper(defaultTab="general")
    template(#tabs)
      sidebar-tab-item#general(:name="$t('sidebarSettings.tabs.name.general')")
      sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
    template(#content)
      sidebar-tab-content#general
        .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t('message') }}
        om-simple-input(label="" property="selectedElement.data.message")
        .sidebar-input-wrapper.flex-row-baseline.pt-3
          .button.button-primary.button-primary-large(@click="showScratchCardSettings") {{ $t('settings') }}
        .sidebar-input-wrapper.flex-row-baseline.pt-3
          .button.button-primary.button-primary-large(@click="showScratchCardTest") {{ $t('scratchCard.test.showButton') }}
      sidebar-tab-content#style
        om-simple-image(
          label="scratchCard.bg.win"
          propsProperty="selectedElement.data.background.win"
        )
        om-simple-image(
          label="scratchCard.bg.lose"
          propsProperty="selectedElement.data.background.lose"
        )
        om-background-box(
          label="scratchCard.bg.scratch"
          propsProperty="selectedElement"
          typeOfComponent="scratchCard"
          :transparentOption="false"
          :imageProperties="false"
          :saveImageUrl="true"
          :blacklist="['svg', 'gif']"
          :item="selectedElement"
          :canRemainOpen="true"
        )
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import { mapState } from 'vuex';

  export default {
    mixins: [itemMixin],
    computed: {
      ...mapState(['selectedElement', 'images']),
    },
    methods: {
      showScratchCardSettings() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'scratchCardSettings' });
      },
      showScratchCardTest() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'scratchCardTest' });
      },
    },
  };
</script>
