import { mapState, mapGetters } from 'vuex';

const dimensions = {
  min: {
    width: 580,
    height: 300,
  },
  max: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
};

export default {
  data() {
    return {
      buttons: [
        { text: 'cssEditor', mode: 'css' },
        { text: 'jsEditor', mode: 'js' },
      ],
      mode: 'css',
      orientation: 'bottom',
      editor: {
        css: null,
        js: null,
      },
      loading: false,
      previewLoading: false,
      css: null,
      js: {
        pageLoad: '',
        popupShow: '',
        popupFill: '',
        popupReject: '',
      },
      showOverlay: false,
      downEvt: null,
      wrapperStyle: null,
      customJsEvent: 'pageLoad',
      svgDimensions: {
        width: '1.25rem',
        height: '1.0625rem',
      },
    };
  },
  computed: {
    ...mapState(['customCss', 'showCustomEditorBox', 'customJsByEvents']),
    ...mapGetters(['isEmbedded']),
    jsEvents() {
      const baseSet = [
        { key: 'jsEvents.pageLoad', value: 'pageLoad' },
        { key: 'jsEvents.popupShow', value: 'popupShow' },
        { key: 'jsEvents.popupFill', value: 'popupFill' },
      ];
      const rejectSet = [{ key: 'jsEvents.popupReject', value: 'popupReject' }];

      if (this.isEmbedded) return baseSet;

      return [...baseSet, ...rejectSet];
    },
    getCustomJs() {
      return this.js[this.customJsEvent];
    },
    isCssEditor() {
      return this.mode === 'css';
    },
    isJsEditor() {
      return this.mode === 'js';
    },
  },
  watch: {
    showCustomEditorBox() {
      this.setFocus();
    },
    mode() {
      this.setFocus();
    },
    customCss(v) {
      if (v) {
        this.editor.css.setValue(v || '', -1);
        this.css = v;
      }
    },
    customJsByEvents(v) {
      if (v) {
        this.editor.js.setValue(v[this.customJsEvent] || '', -1);
        this.js = v;
      }
    },
    showOverlay(v) {
      this.$emit('overlay', v);
    },
    downEvt(v) {
      const computed = getComputedStyle(this.$refs.editorWrapper);
      this.wrapperStyle = {
        height: parseInt(computed.height, 10),
        width: parseInt(computed.width, 10),
      };
      if (v) {
        document.documentElement.addEventListener('mousemove', this.dragHandler);
      } else {
        document.documentElement.removeEventListener('mousemove', this.dragHandler);
      }
    },
    customJsEvent() {
      this.editor.js.setValue(this.js[this.customJsEvent] || '', -1);
      this.setFocus();
    },
  },
  mounted() {
    this.editor.css = this.initEditor('customCssEditor', 'css');
    this.editor.js = this.initEditor('customJsEditor', 'javascript');
    this.editor.css.on('change', () => {
      this.css = this.editor.css.getValue();
    });
    this.editor.js.on('change', () => {
      this.js[this.customJsEvent] = this.editor.js.getValue();
    });

    document.documentElement.addEventListener('mouseup', this.stopDrag);
    document.documentElement.addEventListener('mouseleave', this.stopDrag);
  },

  beforeDestroy() {
    document.documentElement.removeEventListener('mouseup', this.stopDrag);
    document.documentElement.removeEventListener('mouseleave', this.stopDrag);
  },
  methods: {
    initEditor(id, mode) {
      const editor = window.ace.edit(id);
      editor.getSession().setMode(`ace/mode/${mode}`);
      editor.$blockScrolling = Infinity;
      return editor;
    },
    dragHandler(e) {
      const { target, clientY: startY, clientX: startX } = this.downEvt;
      const { height: startHeight, width: startWidth } = this.wrapperStyle;
      const calculatedWidth = startWidth + startX - e.clientX;
      const calculatedHeight = startHeight + startY - e.clientY;
      if (target.classList.contains('horizontal')) {
        let width = `${calculatedWidth}px`;
        if (calculatedWidth < dimensions.min.width) {
          width = `${dimensions.min.width}px`;
        } else if (calculatedWidth > dimensions.max.width) {
          width = `${dimensions.max.width}px`;
        }
        this.$refs.editorWrapper.style.width = width;
      } else {
        let height = `${calculatedHeight}px`;
        if (calculatedHeight < dimensions.min.height) {
          height = `${dimensions.min.height}px`;
        } else if (calculatedHeight > dimensions.max.height) {
          height = `${dimensions.max.height}px`;
        }
        this.$refs.editorWrapper.style.height = height;
      }
      this.editor.css.resize();
      this.editor.js.resize();
    },
    setOrientation(value) {
      this.orientation = value;
      if (value === 'bottom') {
        this.removeEditorStyleProp('height');
        this.$refs.editorWrapper.style.removeProperty('width');
        this.$refs.editorWrapper.style.removeProperty('height');
        this.$refs.cssEditor.style.width = '100%';
        this.$refs.jsEditor.style.width = '100%';
      } else {
        this.removeEditorStyleProp('width');
        this.$refs.editorWrapper.style.removeProperty('height');
        this.$refs.editorWrapper.style.removeProperty('width');
      }
      setTimeout(() => {
        this.editor.css.resize();
        this.editor.js.resize();
      }, 350);
      this.setFocus();
    },
    initDrag(e) {
      this.downEvt = e;
      this.showOverlay = true;
      this.$refs.editorWrapper.style.transition = 'none';
    },
    stopDrag() {
      this.downEvt = null;
      this.showOverlay = false;
      if (this.$refs.editorWrapper) this.$refs.editorWrapper.style.removeProperty('transition');
    },
    removeEditorStyleProp(prop) {
      this.$refs.cssEditor.style.removeProperty(prop);
      this.$refs.jsEditor.style.removeProperty(prop);
    },
    showLoading(preview) {
      let prop = 'loading';
      if (preview) {
        prop = 'previewLoading';
      }

      this[prop] = true;
      setTimeout(() => {
        this[prop] = false;
      }, 300);
    },
    async save() {
      if (this.isCssEditor) {
        this.setStateAttr({ attr: 'customCss', value: this.css });
      } else {
        this.setStateAttr({ attr: 'customJsByEvents', value: this.js });
      }
      this.showLoading();
    },
    setFocus() {
      const ed = this.editor[this.mode];
      ed.focus();
      ed.navigateFileEnd();
    },
  },
};
