import runtimeConfig from '@/config/runtime';
import GET_HUBSPOT_CHAT_TOKEN from '@/graphql/GetHubSpotChatVisitorToken.gql';

const { VUE_APP_HUBSPOT_SCRIPT_URL } = runtimeConfig;

const insertHubSpotScript = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  if (!VUE_APP_HUBSPOT_SCRIPT_URL) return;

  const hubspotScript = document.createElement('script');
  hubspotScript.src = VUE_APP_HUBSPOT_SCRIPT_URL;
  hubspotScript.async = true;
  hubspotScript.defer = true;
  const documentBody = document.querySelector('body');
  if (!documentBody) return;

  documentBody.appendChild(hubspotScript);
};

const initHubSpotChat = async (apollo) => {
  if (!window.hsConversationsSettings) return;

  const {
    data: {
      getHubSpotChatVisitorToken: { email, token },
    },
  } = await apollo.query({
    query: GET_HUBSPOT_CHAT_TOKEN,
  });

  if (email && token) {
    window.hsConversationsSettings.identificationEmail = email;
    window.hsConversationsSettings.identificationToken = token;
  }

  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();
      },
    ];
  }
};

export { insertHubSpotScript, initHubSpotChat };
