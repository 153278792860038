<template lang="pug">
.theme-fonts-container.mt-4
  .each-theme-font(v-for="(font, index) in getThemeKitFonts")
    .alt-title {{ index === 0 ? $t('themeKit.primaryFont') : $t('themeKit.secondaryFont') }}
    .d-flex
      multiselect.pointer(
        v-model="valueObj[index]"
        :options="getFontsWithoutThemeFont"
        label="key"
        track-by="value"
        :closeOnSelect="true"
        :searchable="true"
        :allow-empty="true"
        :placeholder="$t('brandKitGroup.fontPlaceholder')"
        selectLabel=""
        deselectLabel=""
        selectedLabel=""
        open-direction="bottom"
        data-track-property="setting:select-open|change:themekit.theme-font.select.open"
        :ref="`themeKitInst${index}`"
        @input="$bus.$emit('themeKitFontChanged', { index })"
      )
        template(slot="singleLabel" slot-scope="props")
          span.option__desc
            span.option__title(:style="`font-family: '${props.option.key}'`") {{ props.option.key }}
        template(slot="option" slot-scope="props")
          span.option__desc
          span.option__title(:style="`font-family: ${props.option.key}`") {{ props.option.key }}
      .font-upload-icon.pointer(
        @click="$modal.show('custom-font-upload-v2', { themeKitIndex: index })"
        data-track-property="setting:upload-open|change:themekit.theme-font.upload.open"
      )
        UilUpload(slot="icon" size="1.5em" fill="#505763")
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import { getMostUsedFonts } from '@/editor/util/getMostUsedFonts';
  import { UilUpload } from '@iconscout/vue-unicons';

  export default {
    components: { UilUpload },
    data() {
      return {
        valueObj: [],
      };
    },
    computed: {
      ...mapState(['themeKit', 'sourceThemeKit']),
      ...mapGetters(['installedFonts']),
      ...mapGetters({
        userId: 'databaseId',
      }),
      getThemeKitFonts() {
        return this.themeKit.fonts;
      },
      getSourceThemeFonts() {
        return this.sourceThemeKit.fonts;
      },
      getFontsWithoutThemeFont() {
        return getMostUsedFonts(this.userId, this.installedFonts)
          .filter((font) => !font.value.startsWith('om-font'))
          .map(({ value, label }) => ({
            value,
            key: label,
          }));
      },
    },
    watch: {
      valueObj(newVal) {
        const newThemeKitFonts = [];
        newVal.forEach((font) => {
          newThemeKitFonts.push(font.value);
        });
        this.$store.commit('updateThemeKitFont', newThemeKitFonts);
      },
    },
    mounted() {
      const fontNames = this.getThemeKitFonts;
      const allFonts = this.getFontsWithoutThemeFont;
      fontNames.forEach((fontName) => {
        const foundFont = allFonts.find((e) => e.value === fontName);
        if (foundFont) {
          this.setFontValue(foundFont.key, foundFont.value, true);
        } else if (this.getSourceThemeFonts?.length) {
          const sourceFont = allFonts.find((e) => e.value === this.getSourceThemeFonts[0]);
          if (sourceFont) {
            this.setFontValue(sourceFont.key, sourceFont.value, true);
          }
        }
      });
      this.$bus.$on('customFontAdded', this.onFontUpload);
    },
    beforeDestroy() {
      this.$bus.$off('customFontAdded');
    },
    methods: {
      setFontValue(key, value, def = false) {
        this.valueObj.push({
          key: key || null,
          value: value || null,
          default: def,
        });
      },
      onFontUpload(eventData) {
        if (eventData.themeKitIndex === null) {
          return;
        }

        // need to wait for getFonts request...
        setTimeout(() => {
          this.$refs[`themeKitInst${eventData.themeKitIndex}`][0].$emit('input', {
            key: eventData.name,
            value: eventData.value,
          });
        }, 500);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .theme-fonts-container
    .each-theme-font
      min-height: 100px
  .multiselect
    width: unset
    ::v-deep.multiselect__tags
      min-width: 12.5rem
      min-height: 2.5rem !important
    ::v-deep.multiselect__select
      &:before
        color: #505763
    ::v-deep.multiselect__content-wrapper
      max-width: 12.75rem !important
    ::v-deep.multiselect__input,
    ::v-deep.multiselect__tags
      font-style: unset
  .font-upload-icon
    margin-left: 0.75rem
    flex: 1
    align-self: center
    &:hover
      svg
        fill: #bdc0c7
</style>
