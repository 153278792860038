<template lang="pug">
om-modal(:name="name" color="light" :width="500" :clickToClose="closable")
  template(v-slot:modal-header)
    .w-100.text-center.font-weight-bold {{ $t(`paymentProblem.${type}.modalTitle`) }}
      .brand-modal-action-icon
        span.cursor-pointer(@click="cancel")
          close-icon(:width="14" :height="14" :color="'#C2C2C2'")

  template(v-slot:modal-body)
    .text-center
      template(v-if="type === 'braintree'")
        span(v-html="$t(`paymentProblem.braintree.${braintreeProblemType}`)")
      template(v-else-if="type === 'bankTransfer'") {{ $t('paymentProblem.bankTransfer.default') }}
      template(v-else-if="type === 'shopify'") {{ $t('paymentProblem.shopify.default') }}

  template(v-slot:modal-footer)
    .d-flex.justify-content-center.mt-2
      om-button(secondary @click="cancel") {{ $t('cancel') }}
      template(v-if="type === 'braintree'")
        om-button.ml-3(primary @click="checkoutAction()") {{ $t('paymentProblem.button.updatePaymentMethod') }}
      template(v-else-if="type === 'bankTransfer'")
        om-button.ml-3(primary @click="proformaAction()" :loading="!isProformaDownloadable") {{ $t('paymentProblem.button.downloadProforma') }}
</template>
<script>
  import { createNamespacedHelpers } from 'vuex';
  import { getAccountIdFromCookie } from '@/util';
  import paymentProblem from '@/mixins/paymentProblem';

  const { mapActions: paymentActions, mapGetters: paymentGetters } =
    createNamespacedHelpers('payment');

  export default {
    components: {},
    mixins: [paymentProblem],
    props: {
      name: { type: String, default: '' },
      type: { type: String, default: '' },
      closable: { type: Boolean, default: true },
    },
    data() {
      return {
        isProformaDownloadable: true,
      };
    },
    computed: {
      ...paymentGetters(['getBraintreePaymentErrorCode', 'getSelectedPlan', 'getLastOrderId']),
      braintreeProblemType() {
        return this.getBraintreePaymentProblemType(this.getBraintreePaymentErrorCode);
      },
    },
    watch: {},
    methods: {
      ...paymentActions(['loadBilling', 'loadPlans', 'downloadProforma']),
      cancel() {
        this.$modal.hide(this.name);
      },
      async checkoutAction() {
        await this.loadBilling();
        await this.loadPlans();
        this.navigateToCheckout(this.getSelectedPlan, getAccountIdFromCookie());
      },
      async proformaAction() {
        if (this.isProformaDownloadable === false) {
          return;
        }
        this.isProformaDownloadable = false;
        await this.downloadProforma(this.getLastOrderId);
        this.cancel();
      },
    },
  };
</script>
<style lang="sass"></style>
