<template lang="pug">
mixin spacing-tooltip
  om-tooltip.ml-2(large v-if="mobilePreview")
    span {{ $t('mobileSpacing') }}

mixin margin-form
  label.input-group-main-label
    span(v-html="$t(label)")
    DeviceSelector(:float="embedded ? 'right' : null" :hasViewText="embedded ? true : false")
    +spacing-tooltip
  om-input-group.margin(
    type="number"
    :property="subPath"
    :keys="directions"
    :typeOfComponent="typeOfComponent"
  )
div
  sidebar-accordion(
    v-if="showAccordion"
    :title="$t(label)"
    :embedded="embedded"
    :addDeviceSelector="addDeviceSelector"
    :opened="opened"
  )
    template(slot="tooltip")
      +spacing-tooltip
    .sidebar-input-wrapper.input-group-wrapper
      +margin-form
  .sidebar-input-wrapper.input-group-wrapper(v-else)
    +margin-form
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import embeddedAccordion from '@/editor/mixins/embeddedAccordion';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import { TEMPLATE_FEATURES } from '@/editor/util';

  export default {
    components: { DeviceSelector },
    mixins: [embeddedAccordion],
    props: {
      propsProperty: { type: String, default: '' },
      subPath: { type: String, default: 'margin' },
      typeOfComponent: { type: String, default: 'global' },
      opened: { type: Boolean, default: false },
      addDeviceSelector: { type: Boolean, default: true },
      showAccordion: { type: Boolean, default: true },
      label: { type: String, default: 'margin' },
      limitedSides: { type: Boolean, default: false },
    },
    data() {
      return {
        TEMPLATE_FEATURES,
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'selectedPage']),
      ...mapGetters(['isNano', 'hasAccountFeature', 'teaserPage', 'hasTemplateFeature']),
      isV2Teaser() {
        return (
          this.hasTemplateFeature(this.TEMPLATE_FEATURES.NEW_TEASER) &&
          this.selectedPage &&
          this.selectedPage.isTeaser
        );
      },
      directions() {
        const dirs = [
          { name: 'top', disabled: false },
          { name: 'right', disabled: false },
          { name: 'bottom', disabled: false },
          { name: 'left', disabled: false },
        ];
        const disableSides = (sides) => {
          for (const side of sides) {
            dirs[side].disabled = true;
          }
        };

        if (this.limitedSides) {
          if (!this.mobilePreview) {
            const position = this.teaserPage.data.position;
            // Left positioned teaser
            if (['left-center', 'top-left', 'bottom-left'].includes(position)) {
              disableSides([1]);
            }
            // Right positioned teaser
            if (['right-center', 'top-right', 'bottom-right'].includes(position)) {
              disableSides([3]);
            }

            // Bottom positioned teaser
            if (['bottom-center', 'bottom-right', 'bottom-left'].includes(position)) {
              disableSides([0]);
            }

            // Top positioned teaser
            if (['top-center', 'top-right', 'top-left'].includes(position)) {
              disableSides([2]);
            }

            // Horizontally centered teaser
            if (position === 'top-center' || position === 'bottom-center') {
              disableSides([1, 3]);
            }

            // Vertically centered teaser
            if (position === 'left-center' || position === 'right-center') {
              disableSides([0, 2]);
            }
          } else {
            // Mobile preview
            const mobilePosition = this.teaserPage.data.mobilePosition;
            if (mobilePosition === 'top') {
              disableSides([2]);
            }
            if (mobilePosition === 'bottom') {
              disableSides([0]);
            }
            if (mobilePosition === 'left-center') {
              disableSides([0, 1, 2]);
            }
            if (mobilePosition === 'right-center') {
              disableSides([0, 2, 3]);
            }
          }
        }

        return dirs;
      },
    },
  };
</script>
