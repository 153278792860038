<template lang="pug">
.set-elements-defaults(v-if="show")
  //- .set-element-defaults-item
    button.button.add-new-button(@click="onSet(true)") {{ $t('setElementDefaults.all', { type: $t(elementTypeTransKey) }) }}
  .set-element-defaults-item
    button.button.add-new-button(@click="onSet(false)") {{ $t('setElementDefaults.onlyDefault', { type: $t(elementTypeTransKey) }) }}
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    props: {
      extras: { type: Array, default: null },
    },
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters(['isSteveMode']),
      show() {
        return this.selectedElement && this.isSteveMode;
      },
      elementTypeTransKey() {
        return this.selectedElement.type.substring(2).toLowerCase();
      },
    },
    methods: {
      onSet(update) {
        const payload = {
          uid: this.selectedElement.uid,
          update,
          extras: this.getExtras(),
        };
        if (update) {
          // eslint-disable-next-line
          const result = confirm(this.$t('areYouSure'));
          if (result) this.$bus.$emit('setElementDefaults', payload);
        } else {
          this.$bus.$emit('setElementDefaults', payload);
        }
      },
      getExtras() {
        return this.extras;
      },
    },
  };
</script>

<style lang="sass">
  .set-element-defaults
    &-item
      margin-top: 1rem
      padding: 0 1rem
      display: flex
      justify-content: center
</style>
