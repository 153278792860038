<template lang="pug">
.popup-custom-editor-bottom(
  ref="editorWrapper"
  :class="{ 'custom-css-disable': !showCustomEditorBox, resizable: orientation === 'bottom', 'to-right': orientation === 'right', 'resizable-right': orientation === 'right' }"
)
  .custom-editor-resizable-area
    .resizer.vertical.hide-text-selection(@mousedown="initDrag")
    .custom-editor-tabs
      .custom-editor-tab.hide-text-selection(
        @click="mode = 'css'"
        :class="{ active: isCssEditor }"
      )
        span {{ $t('cssEditor') }}
      .custom-editor-tab.hide-text-selection(@click="mode = 'js'" :class="{ active: isJsEditor }")
        span {{ $t('jsEditor') }}
      .d-flex-1.align-items-center.custom-editor-js-events(v-if="isJsEditor")
        .sidebar-input-wrapper.sidebar-input-wrapper-label.font-weight-bold.mr-2 {{ $t('jsEventsLabel') }}
        .sidebar-input-wrapper.flex-row-baseline
          span.epic-simple-input
            .select-arrow
              i.fa.fa-angle-down(aria-hidden="true")
            select(v-model="customJsEvent")
              option(v-for="item in jsEvents" :value="item.value") {{ $t(item.key) }}

    .dock-side-wrapper
      .dock-side.dock-side-bottom(
        @click="setOrientation('bottom')"
        :class="{ selected: orientation === 'bottom' }"
      )
        svg(:style="svgDimensions")
          rect(
            width="100%"
            height="100%"
            style="fill: transparent; stroke-width: 4; stroke: #999ea1"
          )
          rect(y="60%" width="100%" height="40%" style="fill: #999ea1; stroke-width: 2")
      .dock-side.dock-side-right(
        @click="setOrientation('right')"
        :class="{ selected: orientation === 'right' }"
      )
        svg(:style="svgDimensions")
          rect(
            width="100%"
            height="100%"
            style="fill: transparent; stroke-width: 4; stroke: #999ea1"
          )
          rect(x="60%" width="40%" height="100%" style="fill: #999ea1; stroke-width: 2")
      .dock-side.dock-side-close(@click="setCustomEditorBoxTrigger(false)")
        svg(width="17" height="17")
          path(d="M 3.4 3.4 L 13.6 13.6 Z" fill="#ababab" stroke="#999ea1" stroke-width="1")
          path(d="M 3.4 13.6 L 13.6 3.4 Z" fill="#ababab" stroke="#999ea1" stroke-width="1")
  .resizer.horizontal.hide-text-selection(@mousedown="initDrag" style="z-index: 5; left: 6px")
  #customCssEditor(ref="cssEditor" v-show="isCssEditor") {{ css }}
  #customJsEditor(ref="jsEditor" v-show="isJsEditor") {{ getCustomJs }}
  .custom-editor-buttons.buttons
    .om-btn.green(v-if="isCssEditor" @click="preview" :class="{ loading: previewLoading }") {{ $t('preview') }}
    .om-btn.orange(@click="save" :class="{ loading }") {{ $t('save') }}
    .om-btn.red(@click="setCustomEditorBoxTrigger(false)") {{ $t('cancel') }}
</template>
<script>
  import { mapMutations } from 'vuex';
  import customJsCss from '@/mixins/customJsCss';

  export default {
    mixins: [customJsCss],
    methods: {
      ...mapMutations(['setStateAttr', 'resetCustomCss', 'previewCss']),
      preview() {
        this.previewCss(this.css);
        this.showLoading(true);
      },
      setCustomEditorBoxTrigger(value) {
        if (!value) {
          this.resetCustomCss();
          this.editor.css.setValue(this.customCss || '', -1);
          this.editor.js.setValue(this.js[this.customJsEvent] || '', -1);
        }
        this.setStateAttr({ attr: 'showCustomEditorBox', value });
      },
    },
  };
</script>

<style lang="sass">
  .brand-tabs-content
    display: flex !important
    align-items: flex-end !important
  .brand-tab
    padding-top: 0px !important
  .brand-tab-list-element
    font-size: 14px
    flex: 0 0 auto !important
  .new-design-editor
    background: #FFFFFF !important
  .new-resizer
    display: flex
    align-items: center
    cursor: ns-resize
  .rotate
    z-index: 5
    position: absolute
    top: 0
    bottom: 0
    transform: rotate(90deg)
    cursor: ew-resize
  .set-img-size
    width: 20px
    height: 20px
  .transform
    transform: rotate(90deg)
</style>
