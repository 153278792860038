<template lang="pug">
sidebar-accordion(:title="$t('responsive')")
  om-switches.pt-0.mb-2(v-if="isRowSelected" label="reverseOrderMobile" property="selectedElement.mobile.reverseOrder")
  om-switches.pt-0.mb-2(label="desktopDisplay" :property="`${propsProperty}.desktop.hidden`")
  om-switches.pt-0(label="mobileDisplay" :property="`${propsProperty}.mobile.hidden`")
  slot
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      propsProperty: {
        type: String,
        default: 'selectedElement',
      },
    },
    computed: {
      ...mapState(['selectedElement']),
      isRowSelected() {
        return this.selectedElement && this.selectedElement.type === 'OmRow';
      },
    },
  };
</script>
