<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4H8M12 19H2C1.4 19 1 18.6 1 18V2C1 1.4 1.4 1 2 1H12C12.6 1 13 1.4 13 2V18C13 18.6 12.6 19 12 19ZM7 14.4C6.8 14.4 6.6 14.6 6.6 14.8C6.6 15 6.8 15.2 7 15.2C7.2 15.2 7.4 15 7.4 14.8C7.4 14.5 7.2 14.4 7 14.4Z"
      :stroke="active ? activeColor : color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      active: { type: Boolean, default: false },
      activeColor: { type: String, default: '#ED5A29' },
      color: { type: String, default: 'rgba(23, 24, 26, 0.15)' },
      width: { type: Number, default: 14 },
      height: { type: Number, default: 20 },
    },
  };
</script>
