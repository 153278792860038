<template lang="pug">
.title.pointer(@click="navigate")
  .pdl-1.left.text-ellipsis(:title="translatedLabel") {{ translatedLabel }}
  span.option-edit.right {{ $t('edit') }}
    i.fa.fa-chevron-right
</template>
<script>
  import { mapMutations } from 'vuex';

  export default {
    props: {
      pane: { type: String, required: true },
      label: { type: String, required: true },
      pageIndex: { type: String },
      level: { type: Number, default: 2 },
    },
    computed: {
      translatedLabel() {
        if (this.pageIndex) {
          return this.label;
        }
        return this.$t(this.label);
      },
    },
    methods: {
      ...mapMutations(['activatePaneL2', 'activatePaneL3', 'selectPage']),
      navigate() {
        if (this.pageIndex) {
          this.selectPage(this.pageIndex);
        }
        if (this.level === 2) {
          this.activatePaneL2(this.pane);
        } else {
          this.activatePaneL3(this.pane);
        }
      },
    },
  };
</script>
