import noneType from './none.vue';
import smallType from './small.vue';
import mediumType from './medium.vue';
import largeType from './large.vue';

export default {
  noneType,
  smallType,
  mediumType,
  largeType,
};
