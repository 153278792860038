<template lang="pug">
.border-top-h3(:class="{ 'angle-open': !show }")
  transition(name="expand")
    .expand-transition(v-if="show")
      .sidebar-input-wrapper.flex-row-baseline(
        v-if="!(isFullscreen || isInterstitial || hasWheel || mobilePreview || isEmbedded) || (isFitToScreenOnMobile && isSidebar) || (isWithoutOverlayOnMobile && isSidebar)"
      )
        span
          label {{ $t('position') }}
            DeviceSelector(:hasViewText="false")
        span(style="width: 55%; height: 100%")
          position-svg(
            property="globalStyle.overlay.position"
            :bgFill="isNano || isSidebarOnMobile || isFitToScreen ? '#dedede' : 'white'"
          )
      .sidebar-input-wrapper.flex-row-baseline(
        v-if="!hasWheel && !isFullscreen && ((mobilePreview && !isEmbedded && !isFitToScreenOnMobile && !isWithoutOverlayOnMobile) || (!isSidebar && mobilePreview))"
      )
        span
          label {{ $t('position') }}
            DeviceSelector(:hasViewText="false")
        mobile-position(property="globalStyle.overlay.mobilePosition")
      slot
      .sidebar-input-wrapper(v-if="usingDeprecatedAnimation")
        .user-info-message.alert.alert-danger.mb-0 {{ $t('deprecatedAnimation') }}
      om-dropdown-input(
        v-if="isSuperAdmin && !hasWheel"
        label="type"
        property="globalStyle.mode"
        :items="popupTypes"
        :i18n="true"
      )
      om-dropdown-input(
        v-if="!isEmbedded"
        label="backgroundAnimation"
        property="globalStyle.animation.backgroundAnimation"
        :items="backgroundAnimations"
      )
      om-dropdown-input(
        v-if="hasBackgroundAnimation && isPopupType"
        label="backgroundAnimationPosition"
        property="globalStyle.animation.backgroundAnimationPosition"
        :items="backgroundAnimationPositions"
        :i18n="true"
        typeOfComponent="global"
        :setMaxWidth="true"
      )
      .sidebar-input-wrapper(v-if="isSidebar && !isNano && !mobilePreview && !isFitToScreen")
        om-margin(
          propsProperty=""
          subPath="globalStyle.overlay.margin"
          typeOfComponent="global"
          :showAccordion="false"
          label="distanceFromTheEdge"
        )
      .sidebar-input-wrapper(
        v-if="isSidebar && mobilePreview && !isFitToScreenOnMobile && isWithoutOverlayOnMobile"
      )
        om-margin(
          propsProperty=""
          subPath="selectedPage.mobile.margin"
          typeOfComponent="global"
          :showAccordion="false"
          label="distanceFromTheEdge"
        )
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import PositionSvg from '@/editor/components/svg/PositionSvg';
  import MobilePosition from '@/editor/components/svg/MobilePosition.vue';
  import itemMixin from '@/editor/mixins/item';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';

  export default {
    components: { PositionSvg, MobilePosition, DeviceSelector },

    mixins: [itemMixin],
    data() {
      return {
        show: true,
        backgroundAnimations: [
          { key: 'none', value: 'none' },
          { key: 'snowing', value: 'snowing' },
        ],
        backgroundAnimationPositions: [
          { key: 'backgroundAnimationPopup', value: 'popup' },
          { key: 'backgroundAnimationOverlay', value: 'overlay' },
        ],
        oldTypes: [
          { key: 'none', value: 'no-animation' },
          { key: 'bounce', value: 'bounce' },
          { key: 'fadeIn', value: 'fadeIn' },
          { key: 'slideInLeft', value: 'slideInLeft' },
          { key: 'slideInTop', value: 'slideInUp' },
          { key: 'fall', value: 'shake' },
          { key: 'stickyUp', value: 'slideInDown' },
          { key: 'flipHorizontal', value: 'flipInY' },
          { key: 'flipVertical', value: 'flipInX' },
          { key: 'sign', value: 'wobble' },
          { key: 'slit', value: 'tada' },
          { key: 'rotateLeft', value: 'rotateInDownLeft' },
          { key: 'rotateTop', value: 'rotateInUpLeft' },
          { key: 'superScaled', value: 'zoomIn' },
        ],
      };
    },
    computed: {
      ...mapGetters([
        'isInterstitial',
        'isFullscreen',
        'isNano',
        'isSidebar',
        'isTemplateEditorMode',
        'nestedAccess',
        'isEmbedded',
        'databaseId',
        'isSidebarOnMobile',
        'isSuperAdmin',
      ]),
      ...mapState([
        'hasWheel',
        'wheelPageNumber',
        'openCloseButton',
        'mobilePreview',
        'selectedPage',
      ]),
      popupTypes() {
        if (this.isFullscreen) {
          return [
            { key: 'popup', value: 'popup' },
            { key: 'fullscreen_new', value: 'fullscreen' },
          ];
        }

        const types = [
          { key: 'nanobar', value: 'nano' },
          { key: 'fullscreen_old', value: 'interstitial' },
          { key: 'popup', value: 'popup' },
          { key: 'embedded', value: 'embedded' },
        ];

        if (
          !this.hasWheel &&
          this.getValueOf('globalStyle.mode') === 'popup' &&
          this.globalPosition === 5
        ) {
          types.push({ key: 'fullscreen_new', value: 'fullscreen' });
        }

        return types;
      },
      hasCurrentPageWheel() {
        const wheel = window.om.template.elements.find(({ type }) => type === 'OmLuckyWheel');

        if (wheel) {
          return wheel.pageId === this.selectedPage.uid;
        }

        return false;
      },
      globalPosition() {
        return this.$store.state.globalStyle.overlay.position;
      },
      isLeftPosition() {
        return [1, 4, 7].includes(this.globalPosition);
      },
      isRightPosition() {
        return [3, 6, 9].includes(this.globalPosition);
      },
      isTopPosition() {
        return [1, 2, 3].includes(this.globalPosition);
      },
      isBottomPosition() {
        return [7, 8, 9].includes(this.globalPosition);
      },
      isHorizontalMiddlePosition() {
        return this.globalPosition === 5;
      },
      isVerticalMiddlePosition() {
        return [4, 6].includes(this.globalPosition);
      },
      animationType() {
        return this.$store.state.globalStyle.animation.type;
      },
      isPopupType() {
        return (
          !this.isFullscreen &&
          !this.isInterstitial &&
          !this.isNano &&
          !this.isSidebar &&
          !this.hasWheel
        );
      },
      isFitToScreen() {
        return this.getValueOf('globalStyle.overlay.fitToScreen');
      },
      isFitToScreenOnMobile() {
        return this.getValueOf('globalStyle.overlay.fitToScreenOnMobile');
      },
      isWithoutOverlayOnMobile() {
        return this.getValueOf('globalStyle.overlay.withoutOverlayOnMobile');
      },
      animationTypes() {
        let types = [];

        const shared = [
          { key: 'none', value: 'no-animation' },
          { key: 'zoom', value: 'zoomIn' },
          { key: 'fade', value: 'fadeIn' },
        ];

        const sidebar = [];

        if (!this.isHorizontalMiddlePosition) {
          sidebar.push({
            key: 'fadeHorizontal',
            value: this.isLeftPosition ? 'fadeInLeft' : 'fadeInRight',
          });
        }

        if (!this.isVerticalMiddlePosition) {
          sidebar.push({
            key: 'fadeVertical',
            value: this.isTopPosition ? 'fadeInDown' : 'fadeInUp',
          });
        }

        const nanobar = [
          { key: 'none', value: 'no-animation' },
          { key: 'slide', value: this.isTopPosition ? 'slideInDown' : 'slideInUp' },
          { key: 'fade', value: 'fadeIn' },
        ];

        if (!this.isNano) {
          types = [].concat(types, shared);
        } else {
          types = [].concat(types, nanobar);
        }

        if (this.isSidebar && !this.isNano) {
          types = [].concat(types, sidebar);
        }

        return types;
      },
      animations() {
        if (this.usingDeprecatedAnimation) {
          const oldType = this.oldTypes.find((o) => o.value === this.animationType);
          return [...this.animationTypes, { key: oldType.key, value: oldType.value }];
        }

        return this.animationTypes;
      },
      usingDeprecatedAnimation() {
        const animationTypes = this.animationTypes.map((a) => a.value);
        return !animationTypes.includes(this.animationType);
      },
      hasBackgroundAnimation() {
        return this.getValueOf('globalStyle.animation.backgroundAnimation', 'none') !== 'none';
      },
    },
    watch: {
      openCloseButton(v) {
        if (v === true) {
          this.show = false;
        }
      },
      globalPosition(v) {
        if (typeof v === 'undefined') return;

        const isFitToScreen = this.getValueOf('globalStyle.overlay.fitToScreen');
        if (v === 5 && isFitToScreen) {
          this.setValueOf('globalStyle.overlay.fitToScreen');
        }

        this.setValueOf('selectedPage.data.position', v);
        if (!Number.isInteger(v)) {
          const switchType = v.includes('top') ? 'fadeInDown' : 'fadeInUp';
          this.setValueOf('globalStyle.tab.animation.switch.type', switchType);
        }
      },
      isFullscreen(v) {
        this.$bus.$emit('initFullscreen', v);
      },
    },
    created() {
      this.show = !this.openCloseButton;
    },
    methods: {
      ...mapMutations(['updateStyle']),
      setNano() {
        const value = this.isNano ? 'popup' : 'nano';
        this.setValueOf('globalStyle.mode', value);
      },
    },
  };
</script>
