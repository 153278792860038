<template lang="pug">
.brand-slide-down
  .brand-slide-down-header(@click="activeSlide" :class="{ 'cursor-pointer': slideActive }")
    .brand-slide-down-title {{ title }}
    .brand-slide-down-icon(v-if="slideActive")
      i.fas(:class="{ 'fa-chevron-up': showContent, 'fa-chevron-down': !showContent }")
  slide-up-down.brand-slide-down-body(:active="showContent" :duration="400")
    slot
</template>

<script>
  export default {
    props: {
      title: { type: String, required: true },
      show: { type: Boolean, default: true },
      slideActive: { type: Boolean, default: true },
    },

    data() {
      return {
        showContent: true,
      };
    },
    watch: {
      show(v) {
        this.showContent = v;
      },
    },
    created() {
      this.showContent = this.show;
    },
    methods: {
      activeSlide() {
        if (this.slideActive) {
          this.showContent = !this.showContent;
        }
      },
    },
  };
</script>
