export default {
  methods: {
    columnText(index) {
      if (this.selectedRowColumns.length === 1) return this.$t('column');
      if (index === 0) return this.$t('columnLeft');
      if (index === 1 && this.selectedRowColumns.length === 3) return this.$t('columnMiddle');
      return this.$t('columnRight');
    },
  },
};
