<template lang="pug">
.d-flex
  .radius-elements.pointer(v-for="element in types")
    .radius-wrapper.d-flex(
      @click="selectRadiusType(element)"
      :class="{ selected: value === element.type }"
    )
      .icon.d-flex
        component(:is="element.type" :element="element.type")
    .type {{ $t(element.name) }}
</template>
<script>
  import Elements from './components/brandKit/elements/index';

  export default {
    components: {
      ...Elements,
    },
    props: {
      value: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        types: [
          { type: 'none-type', name: 'brandKitGroup.radius.none' },
          { type: 'small-type', name: 'brandKitGroup.radius.small' },
          { type: 'medium-type', name: 'brandKitGroup.radius.medium' },
          { type: 'large-type', name: 'brandKitGroup.radius.large' },
        ],
      };
    },
    methods: {
      selectRadiusType(element) {
        this.$emit('select', element);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .radius-elements
    width: 56px
    height: 69px
    margin-right: 8px
    .radius-wrapper
      height: 46px
      border-radius: 8px
      border: 1px solid #E3E5E8
      justify-content: center
      align-items: center
      &:hover
        background: rgba(185, 190, 198, 0.2)
      &.selected
        border: 2px solid #ED5A29
        &:hover
          background: unset
      .icon
        justify-content: center
    .type
      color: #4F4F4F
      font-size: 12px
</style>
