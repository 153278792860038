import GET_RESYNC_ROW_STATUSES from '@/graphql/GetResyncRowStatuses.gql';
import failedIntegrationResyncStorage from '@/services/integrations/resync/storage';

const POLL_INTERVAL = 5000; // 5 sec

export default {
  data: () => ({
    resyncPollInterval: null,
  }),
  mounted() {
    this.resyncPollInterval = setInterval(() => {
      this.pollResync();
    }, POLL_INTERVAL);
  },

  beforeUnmount() {
    clearInterval(this.resyncPollInterval);
  },

  methods: {
    async pollResync() {
      const timestamp = failedIntegrationResyncStorage.getResyncPollTimestamp();
      if (!timestamp) return;
      const { data } = await this.$apollo.query({
        query: GET_RESYNC_ROW_STATUSES,
        variables: {
          timestamp: Number(timestamp),
        },
      });

      let resyncLogId;
      const { inProgress, finished } = data.getResyncRowStatuses;
      if (inProgress) {
        failedIntegrationResyncStorage.updateResyncPollTimestamp();
      } else {
        resyncLogId = failedIntegrationResyncStorage.getResyncPollLogId();
        failedIntegrationResyncStorage.deleteResyncPollTimestamp();
      }
      if (finished) {
        this.$notify({
          type: 'success',
          text: this.$t('failedIntegration.resyncPoll.notification'),
          onClick: () => {
            if (this.$route.name === 'subscribers') {
              this.$modal.show('failed-integration-resync');
              this.$bus.$emit('refetch-integration-resync-data');
            } else {
              this.$router.push({
                name: 'subscribers',
                params: { openIntegrationResyncModal: true, resyncLogId },
              });
            }
          },
        });
      }
    },
  },
};
