import axios from 'axios';
import { baseUrl } from '../config/url';

let shopsWithMissingScope = null;

const setHasShopsWithMissingScopes = (value) => {
  try {
    if (typeof window.OptiMonk !== 'undefined') {
      const adapter = window.OptiMonk.Visitor.createAdapter();
      adapter.attr('hasShopsWithMissingScope', `${value}`);
    }
  } catch (err) {
    console.log('Failed to set hasShopsWithMissingScope');
    console.log(err);
  }
  window.hasShopsWithMissingScope = value;
};

const checkShopsWithMissingScope = async () => {
  try {
    const response = await axios.get('/app/shopify/shopsWithMissingScope', {
      baseURL: baseUrl,
      withCredentials: true,
    });
    if (response.data.hasMissing) {
      const { myshopifyDomain, url } = response.data;
      shopsWithMissingScope = { domain: myshopifyDomain, url };
      setHasShopsWithMissingScopes(1);
    }
  } catch (err) {
    console.log('Failed to fetch shops with missing scopes');
  }
};

const extendShopifyScopes = async () => {
  if (shopsWithMissingScope) {
    localStorage.setItem('shopify-scopes-extended', 1);
    window.shopifyScopeExtended = 1;
    window.location.href = shopsWithMissingScope.url;
  }
};

window.extendShopifyScopes = extendShopifyScopes;
setHasShopsWithMissingScopes(0);
window.shopifyScopeExtended = parseInt(localStorage.getItem('shopify-scopes-extended'), 10) || 0;

const onOMReady = window.OptiMonkOnReady;
window.OptiMonkOnReady = () => {
  setHasShopsWithMissingScopes(window.hasShopsWithMissingScope || 0);

  if (typeof onOMReady === 'function') {
    onOMReady();
  }
};

export { extendShopifyScopes, checkShopsWithMissingScope };
