import WHITELABEL_SETTINGS_FOR_DOMAIN from '@/graphql/GetAgencyWhiteLabelSettingsForDomain.gql';
import GET_AGENCY_WHITE_LABEL_SETTINGS from '@/graphql/GetAgencyWhiteLabelSettings.gql';
import { mapState, mapMutations } from 'vuex';
import OmFavicon from '@/assets/om-favicon.png';
import WLFavicon from '@/assets/cog-wheel.png';
import { isWhitelabelDomain } from '../config/url';

export const getWLSettings = (apolloClient, query) => {
  return apolloClient.query({
    query,
  });
};

export default {
  data() {
    return {
      whiteLabel: undefined,
      isWhitelabelDomain: isWhitelabelDomain(),
    };
  },
  computed: {
    ...mapState(['accountType', 'whiteLabelSettings']),
    isUnbranded() {
      return this.accountType === 'agency' || this.accountType === 'sub';
    },
    WLbrandName() {
      return this.whiteLabel ? this.whiteLabel.brandName : '';
    },
    WLcustomLogo() {
      return this.whiteLabel ? this.whiteLabel.customLogo : '';
    },
    WLcustomSquared() {
      return this.whiteLabel ? this.whiteLabel.customSquared : '';
    },
    WLdomainBase() {
      return this.whiteLabel ? this.whiteLabel.domainBase : '';
    },
  },
  watch: {
    $route() {
      this.setTitle();
    },
    whiteLabelSettings(n) {
      this.whiteLabel = n;
      this.setTitle();
    },
    WLbrandName(n) {
      this.setTitle(n);
    },
    WLcustomSquared() {
      this.setWLAssets();
    },
  },
  methods: {
    ...mapMutations(['setWhiteLabelSettings']),
    setTitle(brandName = this.WLbrandName) {
      let title = 'OptiMonk Admin';
      const isLogin = this.$route.name === 'login';

      if (brandName) {
        // Whitelabel with brandName
        if (isLogin) {
          title = this.$t('WL.loginTitle', { brand: brandName });
        } else {
          title = this.$t('WL.title', { brand: brandName });
        }
      } else if (this.isWhitelabelDomain) {
        // WhiteLabel domain without brandName
        title = 'Admin';
      } else if (isLogin) {
        // Normal OptiMonk
        title = this.$t('titles.login');
      }

      document.title = title;
    },
    getWhiteLabelSettings() {
      let prom;

      if (this.isUnbranded) {
        prom = getWLSettings(this.$apollo, GET_AGENCY_WHITE_LABEL_SETTINGS);
      } else if (isWhitelabelDomain()) {
        prom = getWLSettings(this.$apollo, WHITELABEL_SETTINGS_FOR_DOMAIN);
      }

      if (prom) {
        prom
          .then(({ data }) => {
            this.whiteLabel = data.settings;
            this.setWhiteLabelSettings(data.settings);
          })
          .catch(() => {
            this.whiteLabel = null;
            this.setWhiteLabelSettings(null);
          });
      } else {
        this.setTitle();
        this.setWhiteLabelSettings(null);
      }
    },
    changeFavicon(src) {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = src;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
    setWLAssets() {
      if (this.WLcustomSquared) {
        this.changeFavicon(this.WLcustomSquared);
      } else if (isWhitelabelDomain()) {
        this.changeFavicon(WLFavicon);
      } else {
        this.changeFavicon(OmFavicon);
      }
    },
  },
};
