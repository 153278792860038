<template lang="pug">
sidebar-accordion(:title="$t('shadow')")
  om-dropdown-input(
    label="shadow"
    property="shadow.type"
    :items="shadowType"
    :typeOfComponent="typeOfComponent"
  )
  om-color-input(
    v-if="shadowTypeValue !== 'none'"
    label="shadowColor"
    :property="property"
    :typeOfComponent="typeOfComponent"
  )
</template>

<script>
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        shadowType: [
          { key: 'none', value: 'none' },
          { key: 'normal', value: 'normal' },
          { key: 'medium', value: 'medium' },
          { key: 'large', value: 'large' },
        ],
      };
    },
    computed: {
      shadowTypeValue() {
        return this.getValueOf('shadow.type', 'none') || 'none';
      },
    },
  };
</script>
