<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V11C0.5 11.7956 0.816071 12.5587 1.37868 13.1213C1.94129 13.6839 2.70435 14 3.5 14H9.5V16H5.5C5.23478 16 4.98043 16.1054 4.79289 16.2929C4.60536 16.4804 4.5 16.7348 4.5 17C4.5 17.2652 4.60536 17.5196 4.79289 17.7071C4.98043 17.8946 5.23478 18 5.5 18H15.5C15.7652 18 16.0196 17.8946 16.2071 17.7071C16.3946 17.5196 16.5 17.2652 16.5 17C16.5 16.7348 16.3946 16.4804 16.2071 16.2929C16.0196 16.1054 15.7652 16 15.5 16H11.5V14H17.5C18.2956 14 19.0587 13.6839 19.6213 13.1213C20.1839 12.5587 20.5 11.7956 20.5 11V3C20.5 2.20435 20.1839 1.44129 19.6213 0.87868C19.0587 0.316071 18.2956 0 17.5 0ZM18.5 11C18.5 11.2652 18.3946 11.5196 18.2071 11.7071C18.0196 11.8946 17.7652 12 17.5 12H3.5C3.23478 12 2.98043 11.8946 2.79289 11.7071C2.60536 11.5196 2.5 11.2652 2.5 11V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H17.5C17.7652 2 18.0196 2.10536 18.2071 2.29289C18.3946 2.48043 18.5 2.73478 18.5 3V11Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      color: { type: String, default: '#505763' },
      size: { type: Number, default: 20 },
    },
  };
</script>
