<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editOverlay') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="style")
      template(#tabs)
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
      template(#content)
        sidebar-tab-content#style
          om-background-box(
            v-if="!(isSidebar || isNano)"
            :isOpen="true"
            label="overlay"
            propsProperty="globalStyle.overlay"
            subPath="globalStyle.overlay"
            :isLeftSide="false"
          )
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [itemMixin],
    computed: {
      ...mapState(['hasWheel', 'mobilePreview']),
      ...mapGetters(['isNano', 'isSidebar', 'nestedAccess']),
    },
  };
</script>
