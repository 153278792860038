import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';
import VueInputAutowidth from 'vue-input-autowidth';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2';
import 'v-calendar/lib/v-calendar.min.css';
import 'vue-popperjs/dist/vue-popper.css';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import '@/assets/admin/css/bootstrap_social.css';

Vue.use(ToggleButton);
Vue.use(VModal, { dialog: true, dynamic: true });
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(VueInputAutowidth);
Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  datePickerShowDayPopover: false,
  popoverVisibility: 'focus',
});
Vue.use(VueClipboard);
