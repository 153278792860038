<template lang="pug">
mixin input-element-chooser
  .sidebar-input-wrapper.flex-row-baseline.pt-0.mt-2
    slot(name="beforeInput")
    .d-flex.pt-2
      .sidebar-input-wrapper-label.p-0.pb-2 {{$t('type')}}
    multiselect(:value="selectedElementObj"
                @input="changeSelectedElement"
                class="om-editor-element-multiselect"
                :options="matchingInputs"
                label="name"
                track-by="customId"
                :closeOnSelect="true"
                :searchable="false"
                :allow-empty="false"
                :placeholder="$t('select')"
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                open-direction="bottom")
      template(slot="singleLabel" slot-scope="props")
        span.option__desc(v-if="props.option.name")
          span.option__title(:title="props.option.name") {{ props.option.name.length > 40 ? props.option.name.substring(0, 40) + '...' : props.option.name }}
      template(slot="option" slot-scope="props")
        .d-flex
          .option__desc
          .option__title(v-if="props.option.name" :title="props.option.name") {{ props.option.name.length > 35 ? props.option.name.substring(0, 35) + '...' : props.option.name }}
      template(slot="afterList" slot-scope="props")
        .add-new-button-holder
          .brand-btn.brand-btn-primary.brand-btn-sm.brand-btn-primary-outline.border-r-3(@click="changeFormManagerVisibility({show: 'secondStep'})") {{ $t('createNew') }}
  .add-new-button-holder.px-1
    om-button(
      secondary
      block
      icon="setting"
      :disabled="!hasEditableInput"
      :style="!hasEditableInput ? 'pointer-events:none' : '' " @click="manageFields()") {{ $t('manageInputFields') }}
  template(v-if="inputTypes.includes(selectedElementType) || selectedElementType === 'textarea' || selectedElementType === 'dropdown' || selectedElementType === 'country'")
    .d-flex.pt-2
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{$t('placeholder')}}
    om-simple-input(label="" property="data.epicPlaceholder")
    template(v-if="selectedElementType !== 'dropdown'")
      om-switches(label="inputRequired" property="data.required")
      template(v-if="selectedElementType === 'date'")
        .d-flex.pt-2
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{$t('dateFormat')}}
        om-dropdown-input.wide-select-100(label="" property="data.format" :items="formatTypes" :i18n="false")
      template(v-if="selectedElement.data.required")
        .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{$t('errorMessage')}}
        om-simple-input.mb-2(label="" property="data.errorMessage")
        om-switch#blockExisting(
          v-if="isEmailAndHasBlockEmailFlag"
          :tooltip="$t('blockExistingTooltip')"
          :label="$t('onlyNewLead')"
          labelPosition="fill"
          v-model="selectedElement.data.form.customSettings.allowOnlyNewLead"
        )
      template(v-if="selectedElement.data.form.customSettings.allowOnlyNewLead")
        .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{$t('errorMessage')}}
        om-simple-input(label="" property="selectedElement.data.form.customSettings.onlyNewLeadCustomErrorMessage")
      om-button(
        v-if="isEmailAndHasBlockEmailFlag"
        secondary
        block
        icon="envelope-lock"
        @click="inputValidation()"
        ) {{ $t('emailInputValidations') }}
    template(v-if="selectedElementType === 'phoneNumber'")
      .d-flex.pt-2
        .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{$t('defaultCountry')}}
      om-dropdown-input.wide-select-100(label="" property="data.phoneDefaultCountry" :items="countryData" :i18n="false")
      .sidebar-help-wrapper.mt-1(v-if="selectedElement.data.phoneDefaultCountry === 'auto'")
        .sidebar-help-content {{ $t('phoneCountryAutoDetectTooltip') }}
  div(v-if="selectedElementType === 'dropdown' || radioOrCheckbox || selectedElementType === 'survey'")
    template(v-if="radioOrCheckbox && isV1Element")
      .d-flex.sidebar-input-wrapper.pb-0
        .flex-row-baseline.sidebar-input-wrapper-label {{$t('question')}}
        i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-tooltip="{content: $t('questionTooltip')}")
      om-simple-input(label="" property="data.feedback.question")
    .d-flex.sidebar-input-wrapper.pb-0
      .flex-row-baseline.sidebar-input-wrapper-label {{$t('options')}}
    template(v-if="selectedElement.type === 'OmSurvey'")
      draggable.d-flex(
        v-model="draggingOptions"
        @start="startDrag"
        @end="endDrag"
      )
        transition-group(name="fade")
          div(v-for="(item, index) in selectedElement.data.form.customSettings.options" :key="item.value")
            .sidebar-input-wrapper.option-input-holder.pb-0(style="align-items: center")
              Drag(v-if="draggingOptions.length > 1")
              input(class="simpleInput pr-4 mb-1" v-model="item.key" @input="update()")
              transition(name="fade")
                om-button.ml-2.mb-1(v-if="optionsCount > 1" icon="trash-alt" ghost iconSize="1.5em" iconOnly @click="removeAnotherOption(index)")
    template(v-else)
      transition-group(name="fade")
        div(v-for="(item, index) in selectedElement.data.form.customSettings.options" :key="item.value")
          div(class="social-setting-block-inner.pb-0")
            .sidebar-input-wrapper.option-input-holder.pb-0
              input(class="simpleInput pr-4 mb-1" v-model="item.key" @input="update()")
              transition(name="fade")
                om-button.ml-2(icon="trash-alt" ghost iconSize="1.5em" iconOnly v-if="optionsCount > 1" @click="removeAnotherOption(index)")
            template(v-if="hasFeedbackQuestion && selectedElementType === 'radio'")
              template(v-if="displayType === 'button'")
                om-dropdown-input.pb-0(label="onClick" :property="optionProperty(index, 'action')" @change="actionTypeChanged(index, $event)" :items="actionTypes")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="actionType(index) === 'redirect'") {{$t('url')}}
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="actionType(index) === 'dial'") {{$t('phoneNumber')}}
                .pb-2
                  om-simple-input(v-if="actionType(index) === 'redirect'" label="" :property="optionProperty(index, 'redirectUrl')")
                  om-simple-input(v-if="actionType(index) === 'dial'" :ref="`phoneNumberInput${index}`" @input="phoneNumberInputListener(index)" label="" :property="optionProperty(index, 'rawPhoneNumber')")
                  om-dropdown-input(v-if="actionType(index) === 'jumpToPage'" label="choosedPage" :property="optionProperty(index, 'jumpToPage')" :items="pageNames" :i18n="false")

                  om-switches(v-if="actionType(index) === 'redirect'" label="keepQueryParams" :property="optionProperty(index, 'keepQueryParams')" :enabledText="$t('yes')" :disabledText="$t('no')")
                  om-switches(v-if="actionType(index) === 'redirect'" label="openInNewTab" :property="optionProperty(index, 'newTab')" :enabledText="$t('yes')" :disabledText="$t('no')")
              om-dropdown-input(v-else label="jumpToPage" :property="optionProperty(index, 'jumpToPage')" @change.native="update()" :items="pageNames" :i18n="false")
    div.privacy-link(v-if="selectedElementType === 'checkbox' && optionsCount === 1")
      om-switches(label="privacyPolicyLink" property="selectedElement.data.isPrivacyPolicy")
    om-simple-input.mb-2(v-if="isPrivacyPolicy && optionsCount === 1" label="" placeholder="https://yoursite.com/privacy-and-policy" property="data.privacyPolicyUrl")
    .sidebar-input-wrapper
      .d-flex.align-items-center.brand-color.cursor-pointer(@click="addAnotherOption()")
        add-icon.mr-2
        .font-size-0--875.font-weight-semibold.line-height-1 {{$t('addNewOption')}}
    om-switches(v-if="selectedElementType !== 'survey'" label="inputRequired" property="selectedElement.data.required")
    template(v-if="selectedElement.data.required")
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{$t('errorMessage')}}
      om-simple-input.mb-2(label="" property="selectedElement.data.errorMessage")
div
  +input-element-chooser
  slot
</template>

<script>
  import { nanoid } from 'nanoid';
  import { get as _get } from 'lodash-es';
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
  import { inputTypes } from '@om/editor-ssr-shared/src/utils';

  import AddIcon from '@/editor/components/svg/Add.vue';
  import Drag from '@/editor/components/svg/Drag.vue';
  import DELETE_FIELD from '@/graphql/DeleteField.gql';
  import itemMixin from '@/editor/mixins/item';
  import buttonMixin from '@/editor/mixins/button';
  import phoneNumberMixin from '@/editor/mixins/phoneNumber';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import draggable from 'vuedraggable';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      AddIcon,
      Drag,
      draggable,
    },
    mixins: [itemMixin, buttonMixin, phoneNumberMixin],

    data() {
      return {
        inputNameVal: null,
        countryData: [],
        inputTypes,
        matchingInputs: [],
        flatpickr: null,
        Hungarian: null,
      };
    },

    computed: {
      ...mapState(['selectedElement', 'templateSaveData', 'inputs', 'pages']),
      ...mapGetters(['databaseId', 'selectedPageIndex', 'hasAccountFeature']),
      draggingOptions: {
        get() {
          return _clone(this.selectedElement.data.form.customSettings.options);
        },
        set(value) {
          this.selectedElement.data.form.customSettings.options = value;
        },
      },

      inputName: {
        get() {
          return this.inputNameVal === null ? this.selectedElementName : this.inputNameVal;
        },

        set(v) {
          this.inputNameVal = v;
        },
      },

      isEmailAndHasBlockEmailFlag() {
        return this.selectedElementType === 'email';
      },

      selectedElementCustomId() {
        return this.selectedElement.data.form.customSettings.customId;
      },

      selectedElementName() {
        return this.selectedElement.data.form.customSettings.name;
      },

      selectedElementObj() {
        const { customId, name } = this.selectedElement.data.form.customSettings;

        return { name, customId };
      },

      selectedElementType() {
        return this.selectedElement.data.form.customSettings.type;
      },

      selectedElementOptions() {
        return this.selectedElement.data.form.customSettings.options;
      },

      selectedElementVersion() {
        return parseInt(this.selectedElement.data.version, 10);
      },

      isPrivacyPolicy() {
        return this.selectedElement.data.isPrivacyPolicy;
      },

      formatTypes() {
        const { flatpickr, Hungarian } = this;
        if (!flatpickr) return;
        // needed so if the user changes global language, we can load back the format
        const formatByLocale = {
          hu: {
            dMY: 'Y. M. d.',
            dFY: 'Y. F d.',
            Ymd: 'Y-m-d',
            DJMY: 'Y. M. j. D.',
          },
          en: {
            dMY: 'd M Y',
            dFY: 'd F Y',
            Ymd: 'Y-m-d',
            JMY: 'J M Y',
            DJMY: 'D J M Y',
          },
        };

        const formatDate = (f) => {
          return this.$i18n.locale === 'hu'
            ? flatpickr.formatDate(new Date(), f, Hungarian).toLowerCase()
            : flatpickr.formatDate(new Date(), f);
        };

        const result = [];

        Object.keys(formatByLocale.en).forEach((f) => {
          if (formatByLocale[this.$i18n.locale].hasOwnProperty(f)) {
            result.push({
              key: formatDate(formatByLocale[this.$i18n.locale][f]),
              value: f,
            });
          }
        });

        return result;
      },

      radioOrCheckbox() {
        return ['radio', 'checkbox'].includes(this.selectedElementType);
      },

      isV1Element() {
        return this.selectedElementVersion === 1 && !this.hasFeedbackQuestion;
      },

      options() {
        return _get(this.selectedElement, 'data.form.customSettings.options');
      },

      optionsCount() {
        const options = this.getValueOf('data.form.customSettings.options', []) || [];
        return options ? options.length : 0;
      },

      hasFeedbackQuestion() {
        const question = _get(this.selectedElement, 'data.feedback.question');
        return this.radioOrCheckbox && question && question.length;
      },

      pageNames() {
        const names = [];
        this.pages.forEach((p, index) => {
          if (index > this.selectedPageIndex) {
            names.push({ key: p.data.title, value: index + 1 });
          }
        });

        return [{ key: this.$t('none'), value: null }, ...names];
      },

      displayType() {
        return _get(this.selectedElement, 'data.displayType');
      },

      hasEditableInput() {
        return this.matchingInputs.some((input) => this.isInputEditable(input));
      },
    },

    watch: {
      async selectedElementType() {
        this.setMatchingInputs();
      },

      async selectedElementOptions() {
        this.setMatchingInputs();
      },

      displayType(e) {
        if (e === 'button') {
          this.setOptionFields();
        }
      },
    },
    async created() {
      this.matchingInputs = await this.getMatchingInputs();
    },

    mounted() {
      this.loadFlatpickr();
      this.setCountryData();

      this.$bus.$on('switchInputElement', this.setMatchingInputs);
      this.$bus.$on('deleteInputField', this.deleteInput);
      this.$bus.$on('openDeleteConfirmationModal', this.deleteInputModal);
      this.$bus.$on('editInputField', this.editInput);
      this.$bus.$on('deleteField', this.delete);
    },
    beforeDestroy() {
      this.$bus.$off('switchInputElement', this.setMatchingInputs);
      this.$bus.$off('deleteInputField', this.deleteInput);
      this.$bus.$off('openDeleteConfirmationModal', this.deleteInputModal);
      this.$bus.$off('editInputField', this.editInput);
      this.$bus.$off('deleteField', this.delete);
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility', 'setStateAttr']),
      ...mapActions(['getMatchingInputs']),

      startDrag(params) {
        params.item.style.opacity = 0;
      },
      endDrag(params) {
        params.item.style.opacity = 1;
      },
      async loadFlatpickr() {
        const [{ default: flatpickr }, { Hungarian }] = await Promise.all([
          import('flatpickr'),
          import('flatpickr/dist/l10n/hu'),
        ]);
        this.flatpickr = flatpickr;
        this.Hungarian = Hungarian;
      },
      changeSelectedElement(event) {
        const customId = event.customId;
        const selectedInput = this.matchingInputs.find((i) => i.customId === customId);
        this.$bus.$emit('switchInputElement', { customField: selectedInput });
      },

      editInput(input) {
        if (!this.isInputEditable(input)) {
          return;
        }

        this.changeFormManagerVisibility({ show: 'thirdStep', selectedElementValue: input });
      },

      deleteInput(input) {
        this.$bus.$emit('openDeleteConfirmationModal', input);
        this.changeFormManagerVisibility({ hide: 'manageField' });
      },

      async delete(input) {
        if (!this.isInputEditable(input)) {
          return;
        }

        const {
          data: { deleteField },
        } = await this.$apollo.mutate({
          mutation: DELETE_FIELD,
          variables: {
            customId: input.customId,
            databaseId: parseInt(this.databaseId, 10),
          },
        });

        if (deleteField.success) {
          await this.setMatchingInputs();

          this.$notify({
            type: 'success',
            text: this.$t('notifications.removeSuccess'),
          });
        } else {
          this.$modal.show('dialog', {
            text: this.$t('fieldUsed', { count: deleteField.count }),
            buttons: [
              {
                title: this.$t('ok'),
                class: getBrandedClassString({ secondary: true }),
                default: true,
              },
            ],
          });
        }
      },

      isInputEditable(input) {
        return input._id !== null;
      },
      setEmailRequired() {
        if (this.selectedElementType === 'email') {
          this.setValueOf('data.required', true);
        }
      },
      async setCountryData() {
        if (this.selectedElementType === 'phoneNumber' || this.selectedElementType === 'country') {
          const [{ default: intlHuCountries }] = await Promise.all([
            import('@om/editor-ssr-shared/src/config/intl_countries_hu.json'),
            import('intl-tel-input'),
          ]);
          this.countryData = [
            { key: 'Auto', value: 'auto' },
            ...window.intlTelInputGlobals.getCountryData().map((c) => ({
              key: this.$i18n.locale === 'hu' ? intlHuCountries[c.iso2] : c.name,
              value: c.iso2,
            })),
          ];
        }
      },

      getExtraOptionFields() {
        let fields = {};

        if (this.hasFeedbackQuestion) {
          if (this.displayType === 'button') {
            fields = {
              jumpToPage: null,
              action: 'nextPopup',
              redirectUrl: '',
              rawPhoneNumber: '',
              keepQueryParams: false,
              newTab: false,
            };
          } else {
            fields = {
              jumpToPage: null,
            };
          }
        }

        return fields;
      },
      addAnotherOption() {
        const newIndexNumber = nanoid(9);
        const len = this.selectedElement.data.form.customSettings.options.length;
        let option = {
          key: this.$t('myOption1', { num: len + 1 }),
          value: `option_${newIndexNumber}`,
        };

        option = Object.assign(option, this.getExtraOptionFields());

        this.selectedElement.data.form.customSettings.options.push(option);
        this.update();
      },
      removeAnotherOption(index) {
        this.selectedElement.data.form.customSettings.options.splice(index, 1);
        this.update();
      },
      update() {
        // this.setValueOf('data.form.customSettings.options', this.selectedElement.data.form.customSettings.options, true)
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      optionProperty(index, key) {
        return `data.form.customSettings.options.${index}.${key}`;
      },
      actionType(index) {
        return this.options[index].action || '';
      },
      setOptionFields() {
        this.selectedElement.data.form.customSettings.options.forEach((o, index) => {
          this.selectedElement.data.form.customSettings.options[index] = {
            key: o.key,
            value: o.value,
            ...this.getExtraOptionFields(),
          };
        });
        this.update();
      },
      actionTypeChanged(index, event) {
        const actionType = event.target.value;
        if (actionType === 'dial') {
          this.initPhoneNumberInput(index);
        } else {
          this.destroyPhoneNumberInput(index);
        }
      },
      async setMatchingInputs() {
        this.matchingInputs = await this.getMatchingInputs();
        this.setCountryData();
        this.setEmailRequired();
      },
      manageFields() {
        this.changeFormManagerVisibility({ show: 'manageField', fields: this.matchingInputs });
      },
      deleteInputModal(input) {
        this.changeFormManagerVisibility({ show: 'deleteFields', selectedElementValue: input });
      },
      inputValidation() {
        this.changeFormManagerVisibility({ show: 'inputValidations' });
      },
      getCustomId(field) {
        if (['email', 'firstname', 'lastname'].includes(field.customId)) {
          return '';
        }
        return ` (${field.customId})`;
      },
    },
  };
</script>
