<template lang="pug">
  .info-box-wrapper.d-flex.justify-content-start.flex-wrap(v-if="show")
    .info-box-close.cursor-pointer(@click.stop="hideInfoBox")
      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M7.12891 6.31104L13.1836 0.244141C13.3464 0.0813802 13.5091 0 13.6719 0C13.7858 0 13.8753 0.0406901 13.9404 0.12207C14.0137 0.203451 14.0503 0.301107 14.0503 0.415039C14.0503 0.480143 14.034 0.553385 14.0015 0.634766C13.9689 0.716146 13.916 0.793457 13.8428 0.866699L7.76367 6.90918L13.9526 12.6709C14.0584 12.7686 14.1317 12.8703 14.1724 12.9761C14.2212 13.0737 14.2456 13.1632 14.2456 13.2446C14.2456 13.3423 14.2131 13.4237 14.1479 13.4888C14.091 13.5539 14.0137 13.5864 13.916 13.5864C13.737 13.5864 13.5457 13.4888 13.3423 13.2935L7.12891 7.50732L0.915527 13.3057C0.793457 13.4196 0.687663 13.4969 0.598145 13.5376C0.508626 13.5701 0.423177 13.5864 0.341797 13.5864C0.236003 13.5864 0.150553 13.5579 0.0854492 13.501C0.0284831 13.4359 0 13.3545 0 13.2568C0 13.0697 0.101725 12.8784 0.305176 12.6831L6.49414 6.90918L0.415039 0.866699C0.268555 0.720215 0.195312 0.569661 0.195312 0.415039C0.195312 0.301107 0.231934 0.203451 0.305176 0.12207C0.378418 0.0406901 0.472005 0 0.585938 0C0.748698 0 0.911458 0.0813802 1.07422 0.244141L7.12891 6.31104Z" fill="#666666"/>
      </svg>
    .h3-elem.p-0.w-100.mt-0.mb-3 {{ heading }}
    .info-box-wrapper-content
      slot(name="content")
    .info-box-wrapper-image
      slot(name="image")
</template>

<script>
  import cookie from '@/mixins/cookie';

  export default {
    mixins: [cookie],
    props: ['heading', 'name'],
    data: () => ({ show: false }),
    created() {
      this.show = !this.getCookie(this.getName());
    },
    methods: {
      getName() {
        return `show${this.name.charAt(0).toUpperCase()}${this.name.substring(1)}`;
      },
      hideInfoBox() {
        this.setCookie(this.getName(), 1, 1825); // ~5 years expiration
        this.show = false;
      },
    },
  };
</script>
