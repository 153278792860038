<template lang="pug">
div
  .d-flex.flex-column.flex-md-row.align-items-stretch.info-bar.mb-3.justify-content-center(
    v-if="downgradeInformation"
  )
    i.fas.fa-info-circle.align-self-center.px-2(style="color: #505763")
    .d-flex.justify-content-center.align-items-center.info-text(style="color: #505763") {{ infoBarText }}
</template>

<script>
  import moment from 'moment';
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    name: 'DowngradeInfoBar',
    mixins: [dateFormat],
    computed: {
      ...mapGetters(['getDowngradeInformation', 'currentPeriod']),
      ...paymentGetters([
        'isShopifyPayment',
        'getDatePaidFromExpires',
        'isFreePackageBy',
        'getPeriodLanguageKey',
      ]),
      downgradeInformation() {
        const info = this.getDowngradeInformation;
        if (info && this.isShopifyPayment) return info?.needShopifyApprove ? null : info;
        return info;
      },
      infoBarText() {
        const info = this.getDowngradeInformation;
        const downgradedPackage = this.toPackageName(info);
        const nextBilling = this.nextBillingDate;

        if (nextBilling.isBefore(moment.utc())) {
          return this.$t('downgradeModal.infoBarAfterExpires', { downgradedPackage });
        }

        if (this.hasFunctionalDowngrade(info)) {
          return this.$t('downgradeModal.infoBar', {
            nextBilling: nextBilling.format(this.dateFormat),
            downgradedPackage,
          });
        }

        return this.$t('downgradeModal.infoBarWithoutDowngrade', {
          nextBilling: nextBilling.format(this.dateFormat),
          downgradedPackage,
        });
      },
      nextBillingDate() {
        return moment.utc(this.getDatePaidFromExpires).startOf('day');
      },
    },
    methods: {
      hasFunctionalDowngrade(info) {
        return !!info.hasFunctionalDowngrade;
      },
      toPackageName(info) {
        if (this.isFreePackageBy(info.to)) {
          return info.to;
        }
        const planName = info.to;
        const periodInMonth = info.toPeriod;
        const periodLanguageKey = `billingCycle.${this.getPeriodLanguageKey(periodInMonth)}`;
        return `${planName.toUpperCase()} (${this.$t(periodLanguageKey)})`;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .info-bar
    background: #F1F2F4
    border-radius: 8px
    height: 2.5rem
    margin-top: 1em
  .info-text
    flex: none
    order: 0
    flex-grow: 0
  @media (max-width: 768px)
    .info-bar
      padding: 30px
      .fa-info-circle
        display: none
    .info-text
      font-size: 1.5em
      text-align: center
</style>
