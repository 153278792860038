<template lang="pug">
.backdrop(v-if="colorPicker.show")
  .editor-color-picker(
    @click.stop=""
    :style="{ top: colorPicker.top, left: colorPicker.left, right: colorPicker.right }"
  )
    template(v-if="colorPicker.colorInstance")
      sketch-editor-v2(
        :value="sketchColors"
        :disableAlpha="!colorPicker.alpha"
        :editMobile="true"
        :colorInstance="colorPicker.colorInstance"
      )
    template(v-else)
      sketch-editor(:value="sketchColors" :disableAlpha="!colorPicker.alpha" :editMobile="true")
</template>
<script>
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import SketchEditor from '@/editor/components/color/components/Sketch.vue';
  import SketchEditorV2 from '@/editor/components/color/components/SketchV2.vue';

  export default {
    components: {
      SketchEditor,
      SketchEditorV2,
    },
    mixins: [themeColorsMixin],
    computed: {
      sketchColors() {
        return { hex: this.paletteColor.color };
      },
      colorPicker() {
        return this.$store.state.colorPicker;
      },
      paletteColor() {
        let { value, property } = this.colorPicker;

        if (!property.startsWith('omcolor') && !value) {
          value = property.endsWith('olor') ? 0 : 1;
        }

        if (value > -1) {
          return {
            color: this.getColors[value],
            index: value,
          };
        }

        return { color: value };
      },
    },
  };
</script>
<style lang="sass" scoped>
  .backdrop
    position: absolute
    height: 100%
    width: 100%
    z-index: 1000000
    left: 0
    top: 0
  .editor-color-picker
    width: 308px
    z-index: 2000
    position: absolute
    border-radius: 8px
    box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 15px 0px
</style>
