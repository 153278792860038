<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15L12.5 19M8 15L7.5 19M5.2 19H14.8M19 11.1H1M18 15H2C1.4 15 1 14.6 1 14V2C1 1.4 1.4 1 2 1H18C18.6 1 19 1.4 19 2V14C19 14.6 18.6 15 18 15Z"
      :stroke="active ? activeColor : color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      active: { type: Boolean, default: false },
      activeColor: { type: String, default: '#ED5A29' },
      color: { type: String, default: 'rgba(23, 24, 26, 0.15)' },
      size: { type: Number, default: 20 },
    },
  };
</script>
