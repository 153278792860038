<template lang="pug">
sidebar-accordion.mobile-settings-accordion(v-if="!isEmbedded" :title="$t('mobileSetting')")
  transition(name="fade")
    .sidebar-input-wrapper.flex-row-baseline(v-if="!mobilePreview")
      .user-info-message.alert.alert-danger(style="width:100%")
        span {{ $t('mobileSettingsWarning.0') }}
        span.brand-link(@click="setEditorPreviewMode({ mode: 'mobile', reset: false } )") {{ $t('mobileSettingsWarning.1') }}
        span {{ $t('mobileSettingsWarning.2') }}
  slot
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['isEmbedded']),
      ...mapState(['mobilePreview']),
    },
    methods: {
      ...mapActions(['setEditorPreviewMode']),
    },
  };
</script>
