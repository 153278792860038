import { get as _get } from 'lodash-es';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['selectedElement']),

    type() {
      return _get(this.selectedElement, 'data.form.customSettings.type', 'input') || 'input';
    },
  },
};
