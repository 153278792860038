import {
  handleTemplateElementsExceptions,
  handleImageExceptions,
} from '@om/template-properties/src/exceptions';

const frame = () => {
  const workspace = document.getElementById('workspaceFrame');
  return workspace;
};

const frameStore = () => {
  const _frame = frame();
  return _frame && _frame.contentWindow && _frame.contentWindow.om
    ? _frame.contentWindow.om.store
    : null;
};

export default {
  computed: {
    templateElements() {
      return frameStore().state.template.elements;
    },
  },
  methods: {
    getExceptions() {
      return [
        ...handleImageExceptions(this.templateElements),
        ...handleTemplateElementsExceptions(this.templateElements),
      ];
    },
  },
};
