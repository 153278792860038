<template lang="pug">
.sidebar-input-wrapper.flex-wrap(style="align-items: center; justify-content: space-between")
  .sidebar-title-tooltip-container.pdl-0
    label.switch-label {{ $t(label) }}
      slot(name="labelTooltip")
  .om-switches-wrapper
    vue-switches.switch-position.m-0(
      :value="initial"
      @click.native="valueSet"
      theme="om-orange"
      type-bold="true"
    )
  slot(name="help" v-if="!tooltipWithIcon")
    .sidebar-help-wrapper.mt-4(v-if="helpContent")
      .sidebar-help-content.smaller-size {{ helpContent }}
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { isDefined } from '@/editor/util';
  import itemMixin from '@/editor/mixins/item';
  import DeviceSelector from '../components/DeviceSelector.vue';

  export default {
    components: { DeviceSelector },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      typeOfComponent: { type: String, default: 'global' },
      enabledText: { type: String, required: false },
      disabledText: { type: String, required: false },
      tooltip: { type: String, required: false, default: '' },
      useNested: { type: Boolean, default: false },
      value: { type: Boolean, default: undefined },
      tooltipWithIcon: { type: Boolean, default: false },
    },
    data() {
      return {
        canISetThis: false,
        tooltipBoundariesElement: document.body,
      };
    },
    computed: {
      ...mapGetters(['nestedAccess']),
      addTooltip() {
        return !!this.tooltip;
      },
      initial() {
        if (isDefined(this.value)) {
          return this.value;
        }

        if (this.useNested) {
          return this.nestedAccess(this.property);
        }
        return this.getValueOf(this.property);
      },
      helpContent() {
        if (this.addTooltip && this.$te(this.tooltip)) {
          return this.$t(this.tooltip);
        }
        if (this.label === 'autoplay') {
          return this.$t('autoplayYoutubeTooltip');
        }
        if (this.label === 'buttonCampaignStatus') {
          return this.initial
            ? this.$t('buttonCampaignStatusTooltip')
            : this.$t('buttonCampaignStatusTooltipOff');
        }
        return null;
      },
    },
    methods: {
      ...mapMutations(['updateData', 'updateStyle']),
      valueSet() {
        const value = !this.initial;
        this.$emit('change', value);

        if (!this.property.length) return;

        if (this.useNested) {
          if (this.property.includes('data')) {
            this.updateData({ property: this.property, value });
          } else {
            this.updateStyle({ property: this.property, value });
          }
        } else {
          this.setValueOf(this.property, value);
        }
        window.om.bus.$emit('userInputChange', { property: this.property, value });
      },
    },
  };
</script>

<style lang="sass">
  .smaller-size
    font-size: 12px
    line-height: 16px
    color: #8F97A4
  .sidebar-help-wrapper
    font-size: .875rem
    line-height: 1.25
    color: rgb(143, 151, 164)
</style>
