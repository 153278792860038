import moment from 'moment';
import GET_INVOICES from '@/graphql/GetInvoices.gql';
import { mapState, createNamespacedHelpers, mapGetters } from 'vuex';
import { getAccountIdFromCookie } from '@/util';
import localStorageMixin from './localStorage';

const LS_BILLING_KEY = 'billingInfo';
const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers('payment');

const FREE_PLAN_NAMES = ['FREE', 'TRIAL'];

const isFreePlan = (plan) => FREE_PLAN_NAMES.includes(plan);

export default {
  mixins: [localStorageMixin],
  data: () => ({
    invoices: [],
    midnight: moment().startOf('day'),
  }),
  computed: {
    ...mapState(['account']),
    ...mapGetters(['getDowngradeInformation']),
    ...paymentGetters(['getPlans']),
    billingInfo() {
      return this.account.billing;
    },
    planNames() {
      return this.getPlans.map((plan) => plan.name);
    },
  },
  methods: {
    ...paymentActions(['loadPlans']),
    async saveBillingInfo() {
      await this.loadInvoices();
      const billingInfo = {
        billingInfo: this.billingInfo,
        hasPreviousBill: this.invoices.some(({ types }) => types.includes('eszamla')),
      };
      this.lsStore(LS_BILLING_KEY, billingInfo);
    },
    removBillingInfo() {
      this.lsRemove(LS_BILLING_KEY);
    },
    getBillingInfo() {
      const billingInfo = this.lsRetrieve(LS_BILLING_KEY);
      this.removBillingInfo();
      return billingInfo;
    },
    getChange(saved, plan) {
      const {
        billingInfo: { package: previous },
      } = saved;
      const prevIndex = this.planNames.indexOf(previous);
      const currentIndex = this.planNames.indexOf(plan);
      if (prevIndex < currentIndex) return 'upgrade';
      if (prevIndex > currentIndex) return 'downgrade';
      return 'same';
    },
    async loadInvoices() {
      const {
        data: { getInvoices: response },
      } = await this.$apollo.query({
        query: GET_INVOICES,
      });

      this.invoices = response && response.count ? response.invoices : [];
    },
    isNewPayment(prevBillingInfo) {
      const {
        billingInfo: { package: billingPackage, dateExpires: expiresRaw },
        hasPreviousBill,
      } = prevBillingInfo;
      const expires = moment(expiresRaw);
      const isExpired = expires.isBefore(this.midnight);
      const isFree = isFreePlan(billingPackage);
      /*
        isFree: for premium users, they cant expire...
        isExpired && !hasPreviousBill: for hungarian users who get trial package...
      */
      return isFree || (isExpired && !hasPreviousBill);
    },
    isReactivationPayment(prevBillingInfo) {
      /*
        User is reactivating if the package is expired
        and has previous bill and of course not in trial
        package (hungarian users only)
      */
      const {
        billingInfo: { package: billingPackage, dateExpires: expiresRaw },
        hasPreviousBill,
      } = prevBillingInfo;
      const expires = moment(expiresRaw);
      const isExpired = expires.isBefore(this.midnight);
      const isFree = isFreePlan(billingPackage);

      return !isFree && isExpired && hasPreviousBill;
    },
    getPaymentType(prevBillingInfo) {
      if (this.isNewPayment(prevBillingInfo)) return 'new';
      return this.isReactivationPayment(prevBillingInfo) ? 'reactivation' : undefined;
    },
    getSuccessPageInformation(plan) {
      const result = {
        checkout: 'success',
      };
      const saved = this.getBillingInfo();
      let info = {};
      if (saved) {
        info = {
          from: saved.billingInfo.package,
          to: plan,
          change: this.getChange(saved, plan),
        };
      }
      return {
        ...result,
        ...info,
        type: this.getPaymentType(saved),
      };
    },
    async goToSuccessPage(plan, routerMethod = 'push') {
      const sanitizedPlanName = (plan.split('-')[0] || '').toUpperCase(); // remove period
      const result = this.getSuccessPageInformation(sanitizedPlanName);
      this.$router[routerMethod]({
        name: 'plan_settings',
        params: { userId: getAccountIdFromCookie() },
        query: result,
      });
    },
  },
};
