<template lang="pug">
.sidebar-device-chooser
  .sidebar-input-wrapper
    label.m-0(v-if="label" style="position: static" v-html="$t(label)")

    .d-flex.btn-group.btn-group-toggle.brand-settings-group(data-toggle="buttons")
      label.flex-grow-1.btn.brand-btn-switch(:class="{ active: value === 'desktop' }")
        input(type="radio" name="device" autocomplete="off" value="desktop" v-model="value")
        div
          desktop-svg(:color="value === 'desktop' ? 'white' : '#aab1c1'")
          span {{ $t('device.desktop') }}
      label.flex-grow-1.btn.brand-btn-switch(:class="{ active: value === 'mobile' }")
        input(type="radio" name="device" autocomplete="off" value="mobile" v-model="value")
        div
          mobile-svg(:color="value === 'mobile' ? 'white' : '#aab1c1'")
          span {{ $t('device.mobile') }}
      label.flex-grow-1.btn.brand-btn-switch(:class="{ active: value === 'desktop_and_mobile' }")
        input(
          type="radio"
          name="device"
          autocomplete="off"
          value="desktop_and_mobile"
          v-model="value"
        )
        div
          desktop-svg.mr-2(:color="value === 'desktop_and_mobile' ? 'white' : '#aab1c1'")
          mobile-svg(:color="value === 'desktop_and_mobile' ? 'white' : '#aab1c1'")
          span {{ $t('device.both') }}
</template>

<script>
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String },
      defaultValue: { type: String },
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        currentRule: { device: this.value },
      };
    },
    computed: {
      value: {
        get() {
          return this.getValueOf(this.property, this.defaultValue) || this.defaultValue;
        },
        set(value) {
          this.setValueOf(this.property, value);
        },
      },
    },
    watch: {
      'currentRule.device': function (n) {
        this.value = n;
      },
    },
  };
</script>
