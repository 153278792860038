<template lang="pug">
.brand-breadcrumb
  template(v-for="(crumb, i) in crumbs")
    template(v-if="i !== crumbs.length - 1")
      router-link( class="brand-link brand-breadcrumb-element" :to="crumb.to" tag="a") {{ crumb.name }}
      span.brand-breadcrumb-sep /
    span(v-else class="brand-breadcrumb-element" :title="crumb.name") {{ crumb.name }}
</template>

<script>
  export default {
    props: {
      crumbs: {
        type: Array,
        required: true,
      },
    },
  };
</script>
