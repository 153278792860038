<template lang="pug">
mixin border-input-form
  om-dropdown-input(
    label="border"
    :property="getPath('border.selectedBorder')"
    :items="isTab ? selectedBorderMin : selectedBorder"
    :typeOfComponent="typeOfComponent"
    defaultValue="no"
  )
  template(v-if="selectedBorderValue && selectedBorderValue !== 'no'")
    om-dropdown-input(
      label="borderType"
      :property="getPath('border.type')"
      :items="type"
      :typeOfComponent="typeOfComponent"
      defaultValue="solid"
    )
    om-color-input(
      label="borderColor"
      :property="getPath('border.color')"
      :typeOfComponent="typeOfComponent"
    )
    om-color-input(
      v-if="typeOfComponent === 'button' && !mobilePreview"
      label="hoverColor"
      property="hover.borderColor"
      typeOfComponent="button"
    )
    .mt-4
      om-range-input(
        label="width"
        :property="getPath('border.width')"
        :min="0"
        :max="40"
        :step="1"
        :typeOfComponent="typeOfComponent"
      )
  corner-radius(
    v-if="!subPath.includes('tab') && (!isFitToScreen || isTeaserPage || (isFitToScreen && !subPath.includes('selectedPage'))) && (!isFitToScreenOnMobile || (isFitToScreenOnMobile && !subPath.includes('selectedPage')))"
    :subPath="subPath"
    :editMobile="editMobile"
  )
  om-dropdown-input(
    label="shadow"
    :property="getPath('shadow.type')"
    :items="shadowType"
    :typeOfComponent="typeOfComponent"
    defaultValue="none"
  )
  om-color-input(
    v-if="shadowTypeValue && shadowTypeValue !== 'none'"
    label="shadowColor"
    :property="getPath('shadow.color')"
    :typeOfComponent="typeOfComponent"
  )
sidebar-accordion(
  v-if="showAccordion"
  :title="$t('borderAndShadow')"
  :addDeviceSelector="editMobile"
  :embedded="embedded"
)
  +border-input-form
div(v-else)
  +border-input-form
</template>
<script>
  import CornerRadius from '@/editor/components/sidebar/components/CornerRadius.vue';
  import embeddedAccordion from '@/editor/mixins/embeddedAccordion';
  import { mapGetters, mapState } from 'vuex';
  import itemMixin from '../../../mixins/item';

  export default {
    components: { CornerRadius },
    mixins: [embeddedAccordion, itemMixin],
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: '' },
      typeOfComponent: { type: String, default: 'global' },
      showAccordion: { type: Boolean, default: true },
    },
    data() {
      return {
        selectedBorderMin: [
          { key: 'none', value: 'no' },
          { key: 'full', value: 'full' },
        ],
        selectedBorder: [
          { key: 'none', value: 'no' },
          { key: 'full', value: 'full' },
          { key: 'top', value: 'top' },
          { key: 'bottom', value: 'bottom' },
          { key: 'left', value: 'left' },
          { key: 'right', value: 'right' },
          { key: 'topBottom', value: 'top-bottom' },
        ],
        type: [
          { key: 'solid', value: 'solid' },
          { key: 'dashed', value: 'dashed' },
          { key: 'dotted', value: 'dotted' },
        ],
        borderRadiusType: [{ key: 'dotted', value: 'dotted' }],
        shadowType: [
          { key: 'none', value: 'none' },
          { key: 'normal', value: 'normal' },
          { key: 'medium', value: 'medium' },
          { key: 'strong', value: 'large' },
        ],
      };
    },
    computed: {
      ...mapGetters(['isTeaserPage']),
      ...mapState(['mobilePreview']),
      isTab() {
        return (this.subPath || '').includes('tab');
      },
      shadowColor() {
        return this.getValueOf(this.getPath('shadow.type'));
      },
      selectedBorderValue() {
        return this.getValueOf(this.getPath('border.selectedBorder'));
      },
      shadowTypeValue() {
        return this.getValueOf(this.getPath('shadow.type'));
      },
      isFitToScreen() {
        return this.getValueOf('globalStyle.overlay.fitToScreen');
      },
      isFitToScreenOnMobile() {
        return this.getValueOf('globalStyle.overlay.fitToScreenOnMobile');
      },
    },
  };
</script>
