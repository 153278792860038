<template lang="pug">
sidebar-accordion(
  :title="$t('display')"
  :opened="show"
  :embedded="embedded"
  :addDeviceSelector="typeOfComponent === 'input-picker' ? false : true"
)
  slot(name="before")
  template(v-if="typeOfComponent === 'input-picker'")
    om-color-input(
      v-if="displayType !== 'button'"
      label="color"
      property="desktop.checkedColor"
      :typeOfComponent="typeOfComponent"
    )
    .d-flex
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t('style') }}
    radio-style-chooser(:type="inputType")
  template(v-if="typeOfComponent !== 'text' && subPath !== 'text'")
    om-dropdown-input(
      :label="getFitLabel"
      :property="getPath('smartSize.type')"
      :items="size"
      :typeOfComponent="typeOfComponent"
      :defaultOption="defaultOption"
    )
    om-switches(
      v-if="isPicker"
      label="breakLines"
      :property="getPath('orientation')"
      :typeOfComponent="selectedInput"
    )
    om-range-input(
      v-if="sizeValue === 'manual'"
      :label="getWidthLabel"
      :property="getPath('smartSize.width')"
      :min="0"
      :max="600"
      :step="1"
      :typeOfComponent="typeOfComponent"
    )
    om-range-input.mt-3(
      v-if="showHeight"
      :label="getHeightLabel"
      :property="getPath('height')"
      :min="0"
      :max="maxHeight"
      :step="1"
      :typeOfComponent="typeOfComponent"
    )
  template(v-if="typeOfComponent === 'input-picker' && displayType !== 'button'")
    align-select(
      :label="elementType === 'checkbox' ? 'checkboxAlign' : 'vertical'"
      :options="alignRadio.map(({ key }) => key)"
      :property="getPath('alignPicker')"
      :vertical="true"
    )
    align-select(
      v-if="sizeValue !== '100w'"
      label="horizontal"
      :options="justifyContentItems.map(({ key }) => key)"
      :property="getPath('justifyContent')"
      :vertical="false"
    )
  template(v-if="sizeValue !== '100w' && elementType !== 'radio' && elementType !== 'checkbox'")
    label.mt-2 {{ $t('align') }}
      align-select(
        v-if="typeOfComponent === 'coupon'"
        label="vertical"
        :property="getPath('alignItems')"
        :options="alignItems.map(({ key }) => key)"
        :editMobile="true"
        :inline="true"
        :vertical="true"
      )
      align-select(
        :label="alignLabel"
        :options="alignmentOptions"
        :property="propertyPath"
        :editMobile="true"
        :inline="true"
      )
  template(v-if="subPath === 'input-picker'")
    om-switches(label="breakLines" property="orientation")
  slot(name="after")
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import RadioStyleChooser from '@/editor/components/RadioStyleChooser.vue';
  import embeddedAccordion from '@/editor/mixins/embeddedAccordion';
  import { get as _get } from 'lodash-es';
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { RadioStyleChooser, AlignSelect },
    mixins: [embeddedAccordion, itemMixin],
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: '' },
      typeOfComponent: { type: String, default: 'global' },
      inputType: { type: String },
      defaultOption: { type: Boolean, default: true },
    },
    data() {
      return {
        show: false,
        alignRadio: [
          { key: 'top', value: 'top' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
        canEditMobile: true,
        justifyContentItems: [
          { key: 'left', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'flex-end' },
        ],
        alignItems: [
          { key: 'top', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'flex-end' },
        ],
      };
    },
    computed: {
      ...mapState(['selectedElement', 'mobilePreview']),
      ...mapGetters(['isNano']),
      breakIntoMultipleLines() {
        return this.getValueOf('orientation');
      },
      sizeValue() {
        return this.getValueOf(this.getPath('smartSize.type'));
      },
      size() {
        const array = [];

        if (this.isButtonOrCoupon) {
          array.push({ key: 'fluid', value: 'fluid' });
        }
        if (this.typeOfComponent === 'survey') {
          array.push({ key: 'fitToContent', value: 'fluid' });
        }
        if (this.mobilePreview) {
          array.push({ key: 'default', value: 'default' });
        }

        array.push({ key: 'manual', value: 'manual' });

        if (!this.isNano) {
          const keyValue = this.typeOfComponent === 'button' ? 'simple100' : 'w100';
          array.push({ key: keyValue, value: '100w' });
        }
        return array;
      },
      textJustify() {
        // use other properties
        if (this.propsProperty === 'globalStyle' && this.subPath === 'text') {
          return [
            { key: 'left', value: 'om-text-left' },
            { key: 'center', value: 'om-text-center' },
            { key: 'right', value: 'om-text-right' },
          ];
        }
        return [
          { key: 'left', value: 'om-justify-left' },
          { key: 'center', value: 'om-justify-center' },
          { key: 'right', value: 'om-justify-right' },
        ];
      },
      getFitLabel() {
        if (['button', 'inputs'].includes(this.typeOfComponent)) {
          return 'width';
        }
        if (this.typeOfComponent === 'survey') {
          return 'optionWidth';
        }

        return 'fit';
      },
      getWidthLabel() {
        if (['button', 'inputs', 'survey'].includes(this.typeOfComponent)) {
          return '';
        }

        return 'width';
      },
      getHeightLabel() {
        if (this.typeOfComponent === 'survey') {
          return 'optionHeight';
        }

        return 'height';
      },
      showHeight() {
        return (
          this.sizeValue === 'manual' ||
          (this.sizeValue === 'fluid' && this.typeOfComponent === 'coupon') ||
          this.typeOfComponent === 'inputs' ||
          this.subPath === 'inputs' ||
          this.subPath === 'input-picker' ||
          this.typeOfComponent === 'input-picker' ||
          (this.sizeValue === '100w' &&
            (this.typeOfComponent === 'button' ||
              this.typeOfComponent === 'coupon' ||
              this.subPath === 'button' ||
              this.typeOfComponent === 'customHTML')) ||
          this.typeOfComponent === 'survey'
        );
      },
      isButtonOrCoupon() {
        const { typeOfComponent, subPath } = this;

        const isButton = typeOfComponent === 'button' || subPath === 'button';
        const isCoupon = typeOfComponent === 'coupon';

        return isButton || isCoupon;
      },
      showAlignment() {
        const { typeOfComponent } = this;
        return ['inputs', 'input-picker', 'coupon', 'button', 'survey'].includes(typeOfComponent);
      },
      maxHeight() {
        return this.typeOfComponent !== 'customHTML' ? 300 : 600;
      },
      elementType() {
        return _get(this.selectedElement, 'data.form.customSettings.type');
      },
      isControl() {
        const type = this.elementType;
        return type === 'radio' || type === 'checkbox';
      },
      isPicker() {
        return (
          this.typeOfComponent === 'input-picker' && this.isControl && this.sizeValue === 'manual'
        );
      },
      selectedInput() {
        return this.isControl ? 'input-picker' : 'inputs';
      },
      verticalAlignment() {
        return this.getValueOf('orientation');
      },
      displayType() {
        return this.getValueOf('data.displayType');
      },
      justifyContentProperty() {
        return this.typeOfComponent === 'survey'
          ? `${this.mobilePreview ? 'mobile' : 'desktop'}.justifyContent`
          : this.getPath('justifyContent');
      },
      propertyPath() {
        const path = this.showAlignment ? this.justifyContentProperty : this.getPath('textJustify');
        return path;
      },
      alignmentOptions() {
        const options = this.showAlignment ? this.justifyContentItems : this.textJustify;
        return options.map(({ key }) => key);
      },
      alignLabel() {
        return this.breakIntoMultipleLines ? 'optionAlign' : 'horizontal';
      },
    },
    watch: {
      sizeValue(n) {
        if (this.isControl && n === 'manual') {
          this.setValueOf('orientation', true);
        }
      },
      'selectedElement.desktop.orientation': function (n) {
        if (this.isControl && this.sizeValue === 'manual' && !n) {
          this.$bus.$emit('getWrapperWidth', this.selectedElement.uid);
        }
      },
      'selectedElement.mobile.orientation': function (n) {
        if (this.isControl && this.sizeValue === 'manual' && !n) {
          this.$bus.$emit('getWrapperWidth', this.selectedElement.uid);
        }
      },
    },
    mounted() {
      this.setShowInitValue();
      if (this.isNano) {
        const initValue = this.getValueOf('smartSize.type');
        if ((!initValue || initValue === '100w') && !this.mobilePreview) {
          this.setValueOf('smartSize.type', 'manual');
        }
      }
      if (this.$bus) {
        this.$bus.$on('wrapperWidth', ({ id, itemWidth, currentFontSize }) => {
          if (id === this.selectedElement.uid) {
            const width = Math.floor(itemWidth / 2) - 10 - currentFontSize;
            this.setValueOf('smartSize.width', width);
          }
        });
        this.$bus.$on('userInputChange', ({ property, value }) => {
          this.$bus.$emit('sizeChanged', { property, value });
        });
      }
    },
    methods: {
      ...mapMutations(['updateStyle']),
      setShowInitValue() {
        // TODO
        const options = ['type', 'color'];
        let counter = 0;
        for (const item in options) {
          if (this.getValueOf(`shadow.${options[item]}`) !== null) counter++;
        }
        if (counter > 1) {
          this.show = true;
        } else {
          this.show = false;
        }
      },
    },
  };
</script>
