<template lang="pug">
div
  .sidebar-palette
    .sidebar-title
      .d-flex.align-items-center.alt-title {{ $t('mainColor') }}
      .d-flex.color-box.main-color-new.pointer(
        @click.stop="showColorPicker($event, 'mainColor', 'color-box')"
      )
        .base-color(
          :style="{ 'background-color': globalStyle.palette.mainColor }"
          data-track-property="setting:picker-open|change:brandkit.brand-color.main.picker.open"
        )
      .d-flex.color-box.main-color-derivate
        .secondary-color(
          v-for="color in getSecondaryColors"
          :style="{ 'background-color': color }"
          @click.stop="showColorPicker($event, 'mainColor', 'color-box')"
        )
  .sidebar-title.mt-2(v-if="getThemeColorsRaw && isUniversal")
    .d-flex.align-items-center.alt-title(v-if="getThemeColorsRaw && getThemeColorsRaw.length") {{ $t('secondaryColors') }}
    .theme-color
      .color-box.pointer(v-for="(color, index) in getThemeColorsRaw")
        .base-color.theme(
          :style="{ 'background-color': color.themeColor }"
          @click.stop="showColorPicker($event, 'themeColors', 'color-box', index)"
          data-track-property="setting:picker-open|change:brandkit.brand-color.secondary.picker.open"
        )
  .theme-colors.mt-3(v-if="getThemeColorsRaw && getThemeColorsRaw.length <= 3")
    om-button.pt-1.pb-1.theme-button(
      v-if="isTemplateEditorMode && isUniversal"
      secondary
      block
      icon="PlusCircle"
      :disabled="false"
      @click.stop="addNewThemeColor()"
    ) {{ $t('addNewColor') }}
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import { findAncestor } from '@/editor/util';
  import themeColorsMixin from '@/editor/mixins/themeColors';

  export default {
    mixins: [themeColorsMixin],
    computed: {
      ...mapGetters(['hasAccountFeature', 'isTemplateEditorMode', 'isUniversal']),
      ...mapState(['globalStyle']),
      getSecondaryColors() {
        return this.globalStyle.palette.secondaryColors;
      },
    },
    methods: {
      showColorPicker(e, property, targetClass, index = null) {
        const target = findAncestor(e.target, targetClass);
        const clientRect = target.getClientRects()[0];
        const top = clientRect.top + 55;

        const params = {
          top,
          left: clientRect.left,
          property: `globalStyle.palette.${property}`,
          palette: true,
          index,
        };
        this.$store.commit('showColorPicker', params);
      },
      addNewThemeColor() {
        this.$modal.show('add-theme-color');
      },
    },
  };
</script>
<style lang="sass" scoped>
  .color-tooltip
    font-size: 1rem
    color: #6A6D72
  .sidebar-title
    flex-direction: column
    .color-box
      width: 237px
      .base-color
        border-radius: 4px 4px 0 0 !important
        width: unset !important
        border-bottom: unset !important
        margin: unset !important
    .main-color-derivate
      flex-direction: row
      .secondary-color
        height: 32px
        width: 62px
        margin: unset
        border-radius: unset
        border: unset
        border-bottom: 1px solid #EEEFF1
        &:first-child
          border-left: 1px solid #EEEFF1
          border-radius: 0 0 0 4px
        &:last-child
          border-right: 1px solid #EEEFF1
          border-radius: 0 0 4px 0
    .theme-color
      display: inline-flex
      flex-direction: row
      .color-box
        margin-right: 4px
        width: 56px
        .base-color
          width: 56px !important
          height: 32px
          border-radius: 4px !important
          border: 1px solid #EEEFF1
          &.theme
            border-bottom: 1px solid #EEEFF1 !important
</style>
