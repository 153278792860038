<template lang="pug">
.pane.pane-no-copy-element-id
  sidebar-title
    span {{ $t('ribbon') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#tabs)
        sidebar-tab-item#general(:name="$t('sidebarSettings.tabs.name.general')")
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
      template(#content)
        sidebar-tab-content#general
          .border-top-h3.bt-0(:class="{ 'angle-close': show, 'angle-open': !show }")
            .h3-elem(@click="show = !show") {{ $t('display') }}
            transition(name="expand")
              .expand-transition(v-if="show")
                .mb-2
                  om-switches(label="show" property="globalStyle.ribbon.show")
                  .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t('text') }}
                  .p2
                    om-simple-input(label="" property="globalStyle.ribbon.text")
        sidebar-tab-predefined-style(property="selectedElement" component="ribbon")
          om-color-input(label="backgroundColor" property="globalStyle.ribbon.background.color")
          om-color-input(label="fontColor" property="globalStyle.ribbon.color")
</template>
<script>
  export default {
    data() {
      return {
        show: true,
      };
    },
  };
</script>
