<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editVideo') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          .border-top-h3.b-0(:class="{ 'angle-close': show, 'angle-open': !show }")
            .h3-elem(@click="show = !show") {{ $t('videoSettings') }}
            transition(name="expand")
              .expand-transition(v-if="show")
                template(v-if="selectedElement.data.videoType === 'youtube'")
                  .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t('youtubeUrl') }}
                  .pb-2
                    om-simple-input(label="" property="selectedElement.data.videoUrl")
                  om-switches(label="autoplay" property="selectedElement.data.autoPlay")
                  template(v-if="!selectedElement.data.autoPlay")
                    om-switches(label="controls" property="selectedElement.data.controls")
                  .mb-2
                    align-select(
                      label="align"
                      property="data.align"
                      :options="videoAlign.map(({ key }) => key)"
                    )
                  template(v-if="!mobilePreview")
                    om-range-input(
                      label="size"
                      property="selectedElement.desktop.height"
                      :min="50"
                      :max="1000"
                      :step="5"
                    )
        sidebar-tab-content#style
          custom-theme-style
          om-border-box(propsProperty="selectedElement" typeOfComponent="video")
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      AlignSelect: () => import('@/editor/components/sidebar/components/AlignSelect.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        show: true,
        showMobile: false,
        videoType: [
          { key: 'youtube', value: 'youtube' },
          { key: 'vimeo', value: 'vimeo' },
          { key: 'vistia', value: 'vistia' },
        ],
        videoAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
      };
    },
    computed: {
      ...mapState(['selectedElement', 'mobilePreview']),
      ...mapGetters(['nestedAccess']),
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'video',
        };
      },
    },
    methods: mapMutations(['updateStyle']),
  };
</script>
