<template lang="pug">
.wrapper
  om-switches(
    label="before"
    property=""
    @change="updateDataProperty({ uid: teaserPage.uid, property: 'display.before', value: $event })"
    :value="teaserPageBefore"
    tooltip="beforeTooltip"
    :tooltipWithIcon="true"
  )
  .sub-group(v-if="isBeforePopUpActive")
    .alt-text {{ $t('teaserTriggering.appearAfterPageLoad') }}
    .w-8.alt-text-input
      .alt-text-input-inner
        om-input#afterPageLoad(
          type="number"
          step="1"
          :value="getValue('afterPageLoadValue') || getDefaultAfterPageLoadValue"
          :suffix="$t('teaserSec')"
          @input="updateDataProperty({ uid: teaserPage.uid, property: 'display.afterPageLoadValue', value: $event }); isValid('afterPageLoadValue', $event)"
        )
          template(v-if="errorKey.afterPageLoadValue" #error)
            span {{ $t(errorKey.afterPageLoadValue) }}
  om-switches(
    label="after"
    property=""
    tooltip="afterTooltip"
    @change="updateDataProperty({ uid: teaserPage.uid, property: 'display.after', value: $event })"
    :value="teaserPageAfter"
    :tooltipWithIcon="true"
  )
  .sub-group(v-if="isPopUpClosedActive")
    .alt-text {{ $t('teaserTriggering.appearAfter') }}
    .w-8.alt-text-input
      .alt-text-input-inner
        om-input#after(
          type="number"
          step="1"
          :suffix="$t('teaserSec')"
          :value="getValue('afterValue') || getDefaultAfterValue"
          @input="updateDataProperty({ uid: teaserPage.uid, property: 'display.afterValue', value: $event }); isValid('afterValue', $event)"
        )
          template(v-if="errorKey.afterValue" #error)
            span {{ $t(errorKey.afterValue) }}
  om-info-box#Teaser.mt-5(
    v-if="showTeaserInfoBox"
    :title="$t('infoBox.teaserTitle')"
    :text="$t('infoBox.teaserText')"
    :learnMoreUrl="$t('infoBox.teaserLearnMoreUrl')"
  )
  .teaser-device-selector.mt-2(v-if="isBeforePopUpActive || isPopUpClosedActive")
    .text {{ $t('teaserDeviceChooser') }}
    .chooser.mt-2
      .block(
        v-for="device in devices"
        :class="{ 'is-selected': device.isSelected }"
        @click="deviceSelectorClicked(device)"
      )
        template(v-if="Array.isArray(device.icon)")
          .both
            .device-icon(v-for="eachItem in device.icon")
              component(:is="eachItem" :size="device.size")
          .alt-title.text-overflow {{ $t(device.name) }}
        template(v-else)
          .device-icon
            component(:is="device.icon" :size="device.size")
          .alt-title {{ $t(device.name) }}
</template>
<script>
  import { mapMutations, mapGetters, mapState } from 'vuex';
  import NewDesktopSvg from '@/editor/components/svg/NewDesktopSVG';
  import NewMobileSvg from '@/editor/components/svg/NewMobileSVG';
  import { get as _get } from 'lodash-es';
  import { PERMANENT_TEASER } from '@/utils/features';

  export default {
    components: {
      NewDesktopSvg,
      NewMobileSvg,
    },
    data() {
      return {
        errorKey: { afterPageLoadValue: null, afterValue: null },
        getDefaultAfterPageLoadValue: 6,
        getDefaultAfterValue: 2,
        devices: [
          {
            key: 'all',
            name: 'teaser.device.all',
            icon: [NewDesktopSvg, NewMobileSvg],
            size: 20,
            isSelected: true,
          },
          {
            key: 'desktop',
            name: 'teaser.device.desktop',
            icon: NewDesktopSvg,
            size: 20,
            isSelected: false,
          },
          {
            key: 'mobile',
            name: 'teaser.device.mobile',
            icon: NewMobileSvg,
            size: 20,
            isSelected: false,
          },
        ],
      };
    },
    computed: {
      ...mapState(['account', 'data']),
      ...mapGetters(['teaserPage', 'hasAccountFeature']),
      isBeforePopUpActive() {
        return _get(this.teaserPage, 'data.display.before');
      },
      isPopUpClosedActive() {
        return _get(this.teaserPage, 'data.display.after');
      },
      getValue() {
        return (type) => {
          return _get(this.teaserPage, `data.display.${type}`);
        };
      },
      teaserPageBefore() {
        return _get(this.teaserPage, 'data.display.before');
      },
      teaserPageAfter() {
        return _get(this.teaserPage, 'data.display.after');
      },
      showTeaserInfoBox() {
        return (
          !_get(this.account, 'profile.infoBox.hideTeaser') &&
          this.hasAccountFeature(PERMANENT_TEASER)
        );
      },
    },
    created() {
      if (this.teaserPage && this.teaserPage.data.display.deviceDisplay) {
        const { deviceDisplay } = this.teaserPage.data.display;
        this.devices.forEach((device) => {
          if (device.key === deviceDisplay) {
            device.isSelected = true;
          } else {
            device.isSelected = false;
          }
        });
      }
    },
    methods: {
      ...mapMutations(['updateDataProperty', 'updateData']),
      deviceSelectorClicked(device) {
        this.devices.forEach((device) => {
          device.isSelected = false;
        });
        device.isSelected = !device.isSelected;
        this.updateDataProperty({
          uid: this.teaserPage.uid,
          property: 'display.deviceDisplay',
          value: device.key,
        });
      },
      isValid(type, value) {
        if (value < 0) {
          this.errorKey[type] = 'teaser.errors.smallerThenZero';
          return;
        }

        if (value % 1 !== 0) {
          this.errorKey[type] = 'teaser.errors.onlyInt';
          return;
        }

        this.errorKey[type] = null;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .is-old
    .sub-group
      display: flex
      padding: 0.75rem 1.5rem
      .alt-text-input
        .unit
          top: calc(50% - .7rem)
    .teaser-device-selector
      padding: 0.75rem 1.5rem
  .wrapper
    padding-right: 5px
    .sub-group
      display: flex
    .alt-text
      font-size: 0.75rem
      line-height: 1.5
      color: #505763
      align-self: center
      max-width: 50%
    .alt-text-input
      max-width: 50%
      margin-left: auto
      &-inner
        position: relative
    .teaser-device-selector
      font-size: 0.75rem
      line-height: 1.5rem
      color: #505763
      .chooser
        display: flex
        .block
          display: flex
          flex-direction: column
          width: 77px
          height: 60px
          border-radius: 4px
          border: 1px solid #E3E5E8
          margin-right: 8px
          cursor: pointer
          &.is-selected
            border: 1px solid #ED5A29
          .both
            display: flex
            justify-content: center
          .device-icon
            align-self: center
            margin-top: 11px
          .alt-title
            max-width: 65px
            margin-top: 4px
            align-self: center
            font-size: 10px
            line-height: 16px
            color: #505763
            white-space: nowrap
</style>
